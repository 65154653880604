import { requestApi } from '@/core';
import type { ContactLookup } from '@/modules/ERP/Contacts/types';
import type { AxiosResponse } from 'axios';
import type { EntitySearch } from '@/modules/Onboarding/Shared/types';
import { PaginatedResponse } from '@/modules/ReportCentre/components/DynamicTable/types';

interface ContactsResponse {
  content: Array<ContactLookup>;
}

export const getContacts = (params?: EntitySearch, ids?: Array<string> | string): Promise<Array<ContactLookup>> => {
  return requestApi({
    service: 'contact',
    method: 'get',
    url: `/api/contacts?ids=${ids ? ids : ''}`,
    params: {
      ...params,
      size: 99999
    }
  }).then((resp: AxiosResponse<ContactsResponse> | undefined) => {
    return resp!.data.content;
  });
};

export const getContactsEnriched = async (params?: EntitySearch, ids?: Array<string> | string): Promise<PaginatedResponse<ContactLookup[]>> => {
  const { withMetadata, withMetadataFilter, ...paramsWithoutMetadata } = params ?? {};
  const resp = await requestApi({
    service: 'contact',
    method: 'post',
    url: `/api/contacts/enriched`,
    params: {
      ...paramsWithoutMetadata,
      ...(ids?.length ? { ids } : undefined)
    },
    data: { ...params, withMetadata: true },
    headers: {
      'x-search-with-body': true
    }
  });
  return resp.data;
};
