import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { HistoricalChartDateRangePeriod, getRange } from '@/modules/reporting-v2/types/HistoricalConfig';
import HistoricalChartConfig from '@/modules/reporting-v2/core/visuals/HistoricalChart/HistoricalChartConfig';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import styles from './HistoricalChartDateRange.module.css';

const options: Array<{ label: string; value: HistoricalChartDateRangePeriod }> = [
  {
    label: '7D',
    value: HistoricalChartDateRangePeriod.LAST_7_DAYS
  },
  {
    label: '1M',
    value: HistoricalChartDateRangePeriod.LAST_MONTH
  },
  {
    label: '3M',
    value: HistoricalChartDateRangePeriod.LAST_3_MONTH
  },
  {
    label: '6M',
    value: HistoricalChartDateRangePeriod.LAST_6_MONTH
  },
  {
    label: 'YTD',
    value: HistoricalChartDateRangePeriod.YEAR_TO_DATE
  },
  {
    label: '1Y',
    value: HistoricalChartDateRangePeriod.LAST_YEAR
  },
  {
    label: '3Y',
    value: HistoricalChartDateRangePeriod.LAST_3_YEARS
  },
  {
    label: 'ALL',
    value: HistoricalChartDateRangePeriod.FROM_INCEPTION
  }
];

const HistoricalChartDateRange = ({ visual, extraMargin }: { visual: HistoricalChartConfig; extraMargin?: boolean }) => {
  const [value, setValue] = useState<HistoricalChartDateRangePeriod>(visual.dateRange as any);
  const context = useContext(ReportContext);

  const updateVisual = useCallback(
    dateRange => {
      visual.dateRange = dateRange;
      visual.rawConfig.dateRange = dateRange; // this property doesn't work
      visual.rawConfig._dateRange = dateRange; // this property is still used
      visual.historicalConfig.range = getRange(dateRange);

      if (context && context.triggerVisualRefetch && context.updatePreferences) {
        context.updatePreferences(visual.id, visual.rawConfig, visual.version);
        context.triggerVisualRefetch(visual.id, dateRange);
      }
    },
    [context, visual]
  );

  const handlePeriodChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value as HistoricalChartDateRangePeriod);
      updateVisual(e.target.value as HistoricalChartDateRangePeriod);
    },
    [setValue, updateVisual]
  );

  return (
    <div className={styles.component} style={{ marginBottom: extraMargin ? '10px' : 0 }} data-component="HistoricalChartDateRange" data-testid="historical-chart-range">
      {options.map(item => {
        return (
          <label className={styles.label} key={`${visual.id}_${item.value}`}>
            <input name={visual.id} type={'radio'} value={item.value} checked={item.value === value} onChange={handlePeriodChange} />
            <div className={styles.button}>{item.label}</div>
          </label>
        );
      })}
    </div>
  );
};

export default HistoricalChartDateRange;
