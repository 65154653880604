import { HoldingSetType } from '@/common/types/entity/HoldingSetType';
import { selectorFamily } from 'recoil';
import { holdingSets, holdingSetsMap } from '@/recoil/holdingSets';
import { AssetLookup, getAssets } from '../api/getAssets';
import { Show } from '@/modules/Onboarding/Shared/types';
import { getContacts } from '@/modules/ERP/Contacts/Api/getContacts';
import { ContactType } from '@/modules/ERP/Contacts/types';
import { RecentEntityModule } from '@/modules/GlobalSearch/api/getRecentEntities';
import { SearchFilterListParam } from '@/modules/GlobalSearch/recoil/searchResults';
import { assetsV2Query } from '@/modules/ERP/PortfolioV2/recoil/assets';
import { legalEntitiesWithCustodian } from '@/modules/ERP/PortfolioV2/recoil/legalEntities';
import { CustodiedType } from '@/common/types/entity/CustodiedType';

export type SearchQueryReturnData = AssetLookup & { type: HoldingSetType };

// ASSETS, PORTFOLIOS, LEGAL_ENTITY will be deprecated and soon replaced with PMS V2
export enum SearchV2Category {
  LEGAL_ENTITY = 'Legal Entity',
  LEGAL_ENTITIES = 'Legal Entities',
  PORTFOLIOS = 'Portfolio',
  PORTFOLIOSV2 = 'Portfolios',
  GROUPING = 'Grouping',
  ASSETS = 'Asset',
  ASSETSV2 = 'Assets',
  CONTACT = 'Contact',
  COMPANY = 'Company'
}

export type SearchQueryReturn<T = SearchQueryReturnData> = {
  key: string;
  title: string;
  description?: string;
  group: string;
  content?: string;
  totalElements?: string;
  data: T;
  searchCategory: SearchV2Category;
};

export enum RecentEntityType {
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  DOCUMENT = 'DOCUMENT',
  PORTFOLIO = 'PORTFOLIO',
  GROUPING = 'GROUPING',
  ASSET = 'ASSET',
  CONTACT = 'CONTACT',
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
  MEASURE = 'MEASURE'
}

export type RecentEntity = {
  entityType: RecentEntityType;
  entityId: string;
  sesameModule?: RecentEntityModule;
  date?: Date;
};

export const assetSelector = selectorFamily({
  key: 'GlobalSearchAssetSelector',
  get: (holdingSetIds: number[]) => async () => {
    return getAssets({ holdingSetIds });
  }
});
export const SearchQuery = selectorFamily<SearchQueryReturn[], SearchFilterListParam>({
  key: 'GlobalSearchQuery',
  get:
    ({ filterBy, take }) =>
    async ({ get }) => {
      const holdingSetMap = get(holdingSetsMap);
      // assets will be deprecated and soon replaced by assetsV2
      const assets = get(assetSelector(Array.from(holdingSetMap.keys())));

      const publicAssetsV2 = get(
        assetsV2Query({
          search: filterBy ? (filterBy[0].value as string) : '',
          sort: 'name,asc',
          private: false,
          hidden: false,
          allocatedInSpace: true,
          page: 0,
          size: take
        })
      );
      const privateAssetsV2 = get(
        assetsV2Query({
          search: filterBy ? (filterBy[0].value as string) : '',
          sort: 'name,asc',
          private: true,
          hidden: false,
          page: 0,
          size: take
        })
      );
      const assetsV2 = [...publicAssetsV2.content, ...privateAssetsV2.content];

      const legalEntitiesV2 = get(
        legalEntitiesWithCustodian({
          sort: 'id, desc',
          size: take,
          holdingSetType: HoldingSetType.VEHICLE
        })
      );

      const userPortfoliosV2 = get(
        legalEntitiesWithCustodian({
          custodiedTypes: [CustodiedType.USER_MANAGED_TRANSACTIONS, CustodiedType.USER_MANAGED_POSITIONS],
          sort: 'id, desc',
          size: take,
          holdingSetType: HoldingSetType.PORTFOLIO
        })
      );
      const custodiedPortfoliosV2 = get(
        legalEntitiesWithCustodian({
          custodiedTypes: [CustodiedType.MANAGED_FEED],
          sort: 'id, desc',
          size: take,
          holdingSetType: HoldingSetType.PORTFOLIO
        })
      );
      const portfoliosV2 = [...userPortfoliosV2.content, ...custodiedPortfoliosV2.content];
      const contacts = await getContacts({ show: Show.ACTIVE, size: 999999 });

      const hsets = get(holdingSets);

      const portfolios = hsets.filter(portfolio => portfolio.type === HoldingSetType.PORTFOLIO);

      const legalEntities = hsets.filter(legalEntity => legalEntity.type === HoldingSetType.VEHICLE);

      const groupingEntities = hsets.filter(grouping => grouping.type === HoldingSetType.GROUPING);

      const companies = contacts.filter((entity: any) => entity.type === ContactType.COMPANY);
      const persons = contacts.filter((entity: any) => entity.type === ContactType.PERSON);

      // assets will be deprecated and soon replaced by assetsV2
      const mappedAssets = assets.map(asset => {
        const holdingSet = holdingSetMap.get(asset.holdingSetId)!;
        return {
          searchCategory: SearchV2Category.ASSETS,
          key: String(asset.id),
          title: asset.name,
          content: holdingSet?.name,
          group: asset.category,
          data: {
            ...asset,
            type: holdingSet?.type,
            recentType: RecentEntityType.ASSET,
            category: SearchV2Category.ASSETS,
            group: asset.category,
            withGroup: true
          }
        };
      });

      const mappedAssetsV2 = assetsV2.map(asset => {
        return {
          searchCategory: SearchV2Category.ASSETSV2,
          key: String(asset.id),
          title: asset.name,
          content: asset.name,
          group: asset.category,
          totalElements: publicAssetsV2.totalElements + privateAssetsV2.totalElements,
          data: {
            ...asset,
            type: asset.type,
            recentType: RecentEntityType.ASSET,
            category: SearchV2Category.ASSETSV2,
            group: asset.category,
            withGroup: true,
            isAssetV2: true
          }
        };
      });

      const mappedPersons = persons.map(person => {
        return {
          searchCategory: SearchV2Category.CONTACT,
          key: person.id,
          title: person.firstName + ' ' + person.lastName,
          content: person.email,
          data: {
            ...person,
            type: person.type,
            name: person.firstName + ' ' + person.lastName,
            recentType: RecentEntityType.CONTACT,
            category: SearchV2Category.CONTACT
          }
        };
      });

      const mappedCompanies = companies.map((company: any) => {
        return {
          searchCategory: SearchV2Category.COMPANY,
          key: String(company.id),
          title: company.company,
          content: company.email,
          data: {
            ...company,
            type: company.type,
            name: company.company,
            recentType: RecentEntityType.COMPANY,
            category: SearchV2Category.COMPANY
          }
        };
      });

      // portfolios will be deprecated and soon replaced by portfoliosV2
      const mappedPortfolios = portfolios.map((portfolio: any) => {
        return {
          searchCategory: SearchV2Category.PORTFOLIOS,
          key: String(portfolio.id),
          title: portfolio.name,
          content: portfolio.name,
          data: {
            ...portfolio,
            type: portfolio.type,
            recentType: RecentEntityType.PORTFOLIO,
            category: SearchV2Category.PORTFOLIOS
          }
        };
      });

      const mappedPortfoliosV2 = portfoliosV2.map(portfolio => {
        return {
          searchCategory: SearchV2Category.PORTFOLIOSV2,
          key: String(portfolio.holdingSetId),
          title: portfolio.name,
          content: portfolio.name,
          data: {
            ...portfolio,
            type: portfolio.custodiedType,
            recentType: RecentEntityType.PORTFOLIO,
            category: SearchV2Category.PORTFOLIOSV2,
            isPortfolioV2: true,
            isCustodiedPortolio: portfolio.custodiedType === CustodiedType.MANAGED_FEED,
            isUserPortfolio: portfolio.custodiedType !== CustodiedType.MANAGED_FEED,
            id: portfolio.holdingSetId
          }
        };
      });

      // legalEntities will be deprecated and soon replaced by legalEntitiesV2
      const mappedLegalEntities = legalEntities.map((legalEntity: any) => {
        return {
          searchCategory: SearchV2Category.LEGAL_ENTITY,
          key: String(legalEntity.id),
          title: legalEntity.name,
          content: legalEntity.name,
          data: {
            ...legalEntity,
            type: legalEntity.type,
            recentType: RecentEntityType.LEGAL_ENTITY,
            category: SearchV2Category.LEGAL_ENTITY
          }
        };
      });

      const mappedLegalEntitiesV2 = legalEntitiesV2.content.map(legalEntity => {
        return {
          searchCategory: SearchV2Category.LEGAL_ENTITIES,
          key: String(legalEntity.holdingSetId),
          title: legalEntity.name,
          content: legalEntity.name,
          data: {
            ...legalEntity,
            type: legalEntity.custodiedType,
            recentType: RecentEntityType.LEGAL_ENTITY,
            category: SearchV2Category.LEGAL_ENTITIES,
            isLegalEntityV2: true,
            id: legalEntity.holdingSetId
          }
        };
      });

      const mappedGroupings = groupingEntities.map((groupingEntity: any) => {
        return {
          searchCategory: SearchV2Category.GROUPING,
          key: String(groupingEntity.id),
          title: groupingEntity.name,
          content: groupingEntity.name,
          data: {
            ...groupingEntity,
            type: groupingEntity.type,
            recentType: RecentEntityType.GROUPING,
            category: SearchV2Category.GROUPING
          }
        };
      });
      return [
        ...mappedLegalEntities,
        ...mappedPortfolios,
        ...mappedAssets,
        ...mappedPersons,
        ...mappedCompanies,
        ...mappedGroupings,
        ...mappedAssetsV2,
        ...mappedPortfoliosV2,
        ...mappedLegalEntitiesV2
      ];
    }
});
