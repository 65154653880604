import type { TaskType } from '@/common/types/entity/Task';
import type { UUID } from '@/common/types/types';
import type { Dayjs } from 'dayjs';
import type { ActivityType, EntityLinkType } from '@/common/types/entity/Link';
import { MetadataValueDto } from '@/common/types/entity/Metadata';

enum ContactType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY'
}

interface Address {
  street?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  additionalInformation?: string;
}

interface Employer {
  id: string;
  firstName: string | null;
  lastName: string | null;
  type: ContactType;
  email: string | null;
  phone: string | null;
  country: string | null;
  company: string | null;
  userId: string | null;
  address: Address | null;
  description: string | null;
  founded: number | null;
  ceo: string | null;
  employer: Employer | null;
  createdBy: string;
  createdDate: number;
  lastModifiedBy: string;
  lastModifiedDate: number;
}

interface ContactBase {
  id: UUID;
  firstName?: string;
  lastName?: string;
  type: ContactType;
  email?: string;
  phone?: string;
  company?: string;
  accountId?: string;
  spaceId?: string;
  metadataValues?: MetadataValueDto[];
}

interface ContactLookup extends ContactBase {
  country?: string;
}

interface Employer {
  id: UUID;
  firstName: string | null;
  lastName: string | null;
  type: ContactType;
  email: string | null;
  phone: string | null;
  country: string | null;
  company: string | null;
  userId: string | null;
  address: Address | null;
  description: string | null;
  founded: number | null;
  ceo: string | null;
  employer: Employer | null;
  createdBy: string;
  createdDate: number;
  lastModifiedBy: string;
  lastModifiedDate: number;
}

interface Contact extends ContactBase {
  id: UUID;
  firstName: string;
  lastName: string;
  type: ContactType;
  email: string;
  phone: string;
  country: string;
  company: string;
  userId: string;
  address: Address;
  employer: Employer;
  createdBy: string;
  createdDate: number;
  lastModifiedBy: string;
  lastModifiedDate: number;
  companyId?: UUID;
  // Exclusive to company contacts
  description?: string;
  founded?: string;
  ceo?: string;
}

interface FlatContact extends Omit<Contact, 'address'>, Address {
  photo?: File;
}

type ContactCreationPayload = Omit<Contact, 'id' | 'createdBy' | 'createdDate' | 'lastModifiedBy' | 'lastModifiedDate'>;

interface NoteCreationPayload {
  title: string;
  content: string;
}

interface NoteDTO {
  createdDate: string;
  id: string;
  title: string;
  date: string;
  author: {
    firstName: string;
    lastName: string;
    login: string;
    authorId: string;
  };
  content: string;
  conversationId?: number;
}

interface TaskDTO {
  id: string;
  title: string;
  description: string;
  type: TaskType;
  assignee: TaskAssignee;
  createdDate: string;
  dueDate?: string;
  lastModifiedDate: string;
  reminder: Reminders;
  priority: Priority;
  status: Status;
  createdBy: string;
}

interface TaskAssignee {
  id: number;
  type: string;
  companyId: number;
  firstName: string;
  lastName: string;
  login: string;
  contactId: string;
}

export const RemindersToTranslationId: Record<Reminders, string> = {
  NO_REMINDER: 'erp.reminders.noReminder',
  AT_DUE_TIME: 'erp.reminders.atTaskDueTime',
  MIN_60: 'erp.reminders.sixtyMinsBefore',
  MIN_90: 'erp.reminders.ninetyMinsBefore',
  MIN_240: 'erp.reminders.twoHundredFortyMinsBefore',
  DAY_1: 'erp.reminders.oneDayBefore',
  DAY_3: 'erp.reminders.threeDaysBefore',
  DAY_7: 'erp.reminders.sevenDaysBefore'
};

export enum Reminders {
  NO_REMINDER = 'NO_REMINDER',
  AT_DUE_TIME = 'AT_DUE_TIME',
  MIN_60 = 'MIN_60',
  MIN_90 = 'MIN_90',
  MIN_240 = 'MIN_240',
  DAY_1 = 'DAY_1',
  DAY_3 = 'DAY_3',
  DAY_7 = 'DAY_7'
}

export enum Priority {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH'
}

export enum Status {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  COMPLETED = 'COMPLETED',
  REMOVED = 'REMOVED'
}

interface TaskCreationPayload {
  title: string;
  description: string;
  status: Status;
  priority: Priority;
  dueDate: Dayjs;
  reminder: Reminders;
  assignee?: { id: number };
}

export enum ActivityActionType {
  CREATE_TASK = 'CREATE_TASK',
  UPDATE_TASK = 'UPDATE_TASK',
  DELETE_COMMENT_TASK = 'DELETE_COMMENT_TASK',
  CREATE_COMMENT_TASK = 'CREATE_COMMENT_TASK',
  REMINDER_TASK = 'REMINDER_TASK'
}

interface ActivityDTO {
  entityType: ActivityType;
  entityId: string;
  type: ActivityActionType;
  companyId: number;
  timestamp: string;
  relatedEntities: RelatedEntitiesActivity[];
  userId: number;
  updatedValue: string;
  previousValue: string;
}

export enum ActivityRelatedEntityType {
  USER = 'USER'
}

interface RelatedEntitiesActivity {
  id: string;
  isCreator: boolean;
  isDeleted: boolean;
  name: string;
  type: EntityLinkType & ActivityRelatedEntityType;
}

export { ContactType };
export type { Contact, FlatContact, ContactLookup, ContactCreationPayload, Address, NoteCreationPayload, NoteDTO, TaskDTO, TaskCreationPayload, TaskAssignee, ActivityDTO };
