import { requestApi } from '@/core';
import { AssetPricesFilter, PriceDto } from '../types';

export const getAssetPrices = (params: AssetPricesFilter): Promise<PriceDto[]> =>
  requestApi({
    method: 'GET',
    url: '/api/v2/prices',
    service: 'asset',
    params
  }).then(({ data }) => data);
