import getMetadataKeys from '@/api/getMetadataKeys';
import { BasicTagType, EntityTagType, SystemTag, TagValueType } from '@/common/types/entity/DocumentTags';
import EntityLookup from '@/common/types/entity/EntityLookup';
import HierarchicalEntityLookup from '@/common/types/entity/HierarchicalEntityLookup';
import { MetadataEntityType } from '@/common/types/entity/Metadata';
import { MetadataTreeParser } from '@/modules/DMS/components/EntityTree/MetadataTreeParser';
import { buildBasicTree, buildEntityTree } from '@/modules/DMS/components/utils/utils';
import { dmsTagsAtom, dmsViewModeAtom, documentInboxState } from '@/modules/DMS/recoil/dms.atoms';
import DocumentTag from '@/modules/DMS/types/DocumentTag';
import DocumentTagValue from '@/modules/DMS/types/DocumentTagValue';
import { getParams } from '@/modules/DMS/types/Params';
import { SearchFilter } from '@/modules/DMS/types/SearchFilter';
import TransientDocumentTagValue from '@/modules/DMS/types/TransientDocumentTagValue';
import { ViewTypeConfigTitle } from '@/modules/DMS/types/ViewType';
import { DataNode } from 'antd/lib/tree';
import { atom, selector, selectorFamily } from 'recoil';

export const parseCustomTags = (tags: DocumentTag[]) => {
  return tags
    .filter(tag => !Object.keys(SystemTag).includes(tag.name))
    .filter(tag => tag.name !== 'All' && tag.name !== 'Recycle Bin')
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const dmsTagValuesSelector = selector<DocumentTagValue<TagValueType>[] | undefined>({
  key: 'dms-tag-values-selector',
  get: async ({ get }) => {
    const viewMode = get(dmsViewModeAtom);
    const tags = get(dmsTagsAtom);
    const fetchDocumentTagValue = async (fromTagId: string, tagsParam?: DocumentTag[]): Promise<DocumentTagValue<TagValueType>[]> => {
      const associatedTag = (tagsParam ?? tags).find(it => it.id === fromTagId);
      return associatedTag ? associatedTag.allowedValues.map(it => DocumentTagValue.from<TagValueType>(associatedTag, it)) : [];
    };

    let tagValues: undefined | DocumentTagValue<TagValueType>[] = undefined;
    if (viewMode.tag) {
      tagValues = await fetchDocumentTagValue(viewMode.tag.id as string, tags);
    }

    return tagValues;
  }
});
export const dmsTagValuesAtom = atom<any>({
  key: 'dms-tag-values-atom',
  default: dmsTagValuesSelector
});
export const dmsTagsNodeTreeSelector = selector<any>({
  key: 'dms-tag-node-tree-selector',
  get: async ({ get }) => {
    const viewMode = get(dmsViewModeAtom);
    const tagValues = get(dmsTagValuesSelector);
    const tags = get(dmsTagsAtom);

    if (viewMode.viewConfig.title === ViewTypeConfigTitle.PROPERTIES) {
      const treeTags = parseCustomTags(tags);

      return treeTags.map(tag => {
        const node: DataNode = { key: tag.id, title: tag.name };

        if (tag.allowedValues?.length > 0) {
          node.children = tag.allowedValues.map(value => MetadataTreeParser.buildValueNode(tag, value));
        }

        return node;
      });
    }
    const getEntities = async () => {
      if (viewMode.viewConfig.tagTree.entitiesSupplier) {
        return get(viewMode.viewConfig.tagTree.entitiesSupplier);
      } else {
        return [];
      }
    };

    const getTagNodes = async (entitiesPassed: HierarchicalEntityLookup<EntityLookup<number>>[]) => {
      return entitiesPassed.length > 0
        ? buildEntityTree(entitiesPassed, viewMode.tag!, tagValues as TransientDocumentTagValue<EntityTagType>[])
        : buildBasicTree(viewMode.tag!, tagValues as TransientDocumentTagValue<BasicTagType>[]);
    };

    return getEntities().then(async entitiesToPassed => {
      return await getTagNodes(entitiesToPassed);
    });
  }
});
export const dmsFilterSelector = selector<any>({
  key: 'dms-filter-selector',
  get: async ({ get }) => {
    const viewMode = get(dmsViewModeAtom);
    const tagValues = get(dmsTagValuesSelector);

    let filter: SearchFilter;
    const params = getParams();

    if (viewMode.tag) {
      const paramsTagValue = TransientDocumentTagValue.fromParams(params, viewMode.tag);
      const tagValue = tagValues?.find(t => t.equals(paramsTagValue));
      filter = SearchFilter.fromParams(params, viewMode.tag, tagValue || paramsTagValue);
    } else {
      filter = SearchFilter.fromParams(params);
    }

    return filter;
  }
});

export const getTagValuesFromTagId = selectorFamily<Array<string>, string>({
  key: 'dms-tag-values-from-id-selector',
  get: id => async () => {
    const keys = await getMetadataKeys(MetadataEntityType.DOCUMENT);
    const currentMetadata = keys.find(key => key.id === id);
    return currentMetadata?.allowedValues ?? [];
  }
});

export const dmsFilterAtom = atom<SearchFilter | undefined>({
  key: 'dms-filter-atom',
  default: dmsFilterSelector
});

export const isDocmunetInboxEnabled = selector<boolean>({
  key: 'dms-is-inbox-enabled-selector',
  get: async ({ get }) => {
    const inbox = get(documentInboxState);
    return !!inbox && inbox.enabled;
  }
});
