import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { ViewMode } from '@/modules/DMS/types/ViewMode';
import DocumentTag from '@/modules/DMS/types/DocumentTag';
import { DocumentTagType, SystemTag, TagValueType } from '@/common/types/entity/DocumentTags';
import getMetadataKeys from '@/api/getMetadataKeys';
import { MetadataEntityType } from '@/common/types/entity/Metadata';
import { InboxViewTypeConfig, ViewTypeConfig, ViewTypeConfigTitle } from '@/modules/DMS/types/ViewType';
import Params, { getParams } from '@/modules/DMS/types/Params';
import { Document } from '@/modules/DMS/types/Document';
import TransientDocumentTagValue from '@/modules/DMS/types/TransientDocumentTagValue';
import { TablePaginationConfig } from 'antd';
import DocumentTagValue from '@/modules/DMS/types/DocumentTagValue';
import { userHsetsSelector } from '@/recoil/holdingSets';
import { assetsFromValidatedHoldingSetsSelector } from '@/modules/DMS/api/getAssets';
import { UUID } from '@/common/types/types';
import { DocumentInbox } from '../types/DocumentInbox';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { hasPermission } from '@/utils/hasPermission';
import { Permissions } from '@/utils/security';

const getFirstTags = async () => {
  return [
    new DocumentTag('', 'Entity', DocumentTagType.HOLDING_SET),
    new DocumentTag('', 'Asset', DocumentTagType.ASSET),
    ...(await getMetadataKeys(MetadataEntityType.DOCUMENT)).map(it => DocumentTag.from(it))
  ];
};

const firstViewModeSelector = selector<ViewMode>({
  key: 'dms-first-view-mode-selector',
  get: ({ get }) => {
    const tags = get(dmsTagsAtom);

    const urlParams = new URLSearchParams(window.location.search);

    const existingDmsParams = Object.values(Params).filter(param => urlParams.has(param));

    const viewParam = urlParams.get(Params.view);

    const isAllView = viewParam === SystemTag.All || existingDmsParams.length <= 0;

    if (isAllView) {
      return new ViewMode(ViewTypeConfig.find(item => item.title === ViewTypeConfigTitle.ALL));
    }

    if (viewParam === ViewTypeConfigTitle.RECENT_DOCUMENTS) {
      return new ViewMode(ViewTypeConfig.find(item => item.title === ViewTypeConfigTitle.RECENT_DOCUMENTS));
    }

    let viewTypeConfig;
    let tag;

    if (viewParam === ViewTypeConfigTitle.ENTITY) {
      viewTypeConfig = ViewTypeConfig.find(item => item.title === ViewTypeConfigTitle.ENTITY);
      tag = tags.find(tag => tag.type === DocumentTagType.HOLDING_SET);
    }

    if (viewParam === ViewTypeConfigTitle.ASSET) {
      viewTypeConfig = ViewTypeConfig.find(item => item.title === ViewTypeConfigTitle.ASSET);
      tag = tags.find(tag => tag.type === DocumentTagType.ASSET);
    }

    if (viewParam === ViewTypeConfigTitle.PROPERTIES) {
      viewTypeConfig = ViewTypeConfig.find(item => item.title === ViewTypeConfigTitle.PROPERTIES);
      tag = tags.find(tag => tag.type !== DocumentTagType.HOLDING_SET && tag.type !== DocumentTagType.ASSET);
    }

    if (viewParam === ViewTypeConfigTitle.INBOX) {
      // check if it has permissions
      const currentUser = get(currentUserSelector);
      const permitted = hasPermission(currentUser, [Permissions.DOCUMENT_INBOX_READER]);
      if (permitted) {
        viewTypeConfig = InboxViewTypeConfig;
        tag = undefined;
      }
    }

    return new ViewMode(viewTypeConfig, tag);
  }
});

const firstSelectedMenuSelector = selector({
  key: 'dms-first-selected-menu-selector',
  get: ({ get }) => {
    const firstViewMode = get(firstViewModeSelector);

    return firstViewMode.viewConfig.title;
  }
});
export const dmsViewModeAtom = atom<ViewMode>({
  key: 'dms-view-mode-atom',
  default: firstViewModeSelector
});

export const firstTagsSelector = selector<DocumentTag[]>({
  key: 'dms-first-tags-selector',
  get: () => {
    return getFirstTags();
  }
});
export const dmsTagsAtom = atom<DocumentTag[]>({
  key: 'dms-tags-atom',
  default: firstTagsSelector
});

export const getFirstQuery = () => {
  let query = '';
  const params = getParams();
  if (params.has('id')) {
    query = String(params.get('id'));
  }
  return query;
};
export const dmsQueryAtom = atom<string>({
  key: 'dms-query-atom',
  default: undefined
});

export const dmsQueryInputValueAtom = atom<string>({
  key: 'dms-query-input-value-atom',
  default: undefined
});

export const dmsDocumentsAtom = atom<any>({
  key: 'dms-documents-atom',
  default: []
});

export const dmsDocumentNameEditState = atomFamily<boolean, UUID>({
  key: 'dms-document-name-edit-state',
  default: false
});

export const documentCellTagValueSelector = selectorFamily<DocumentTagValue<any> | undefined, [string, string | DocumentTagType, string]>({
  key: 'doc-cell-tag-value-selector',
  get:
    ([_, tagValueId, value]) =>
    ({ get }) => {
      const tags = get(dmsTagsAtom);

      const isEntityTag = [DocumentTagType.HOLDING_SET, DocumentTagType.ASSET].includes(tagValueId as DocumentTagType);

      const retrievePropertyToCompare = (tag: DocumentTag) => (isEntityTag ? tag.type : tag.id);

      const tagValue = tags.find(tag => retrievePropertyToCompare(tag) === tagValueId);

      if (isEntityTag) {
        let entity;

        if (tagValueId === DocumentTagType.HOLDING_SET) {
          const hsets = get(userHsetsSelector);

          entity = hsets.find(item => item.id === value);
        } else if (tagValueId === DocumentTagType.ASSET) {
          const assets = get(assetsFromValidatedHoldingSetsSelector);

          entity = assets.find(item => item.id === value);
        }

        return DocumentTagValue.from(tagValue!, entity);
      }

      return DocumentTagValue.from(tagValue!, value);
    }
});

export const documentCellTagValueState = atomFamily<DocumentTagValue<any> | undefined, [string, string, string]>({
  key: 'document-cell-tag-value-state',
  default: documentCellTagValueSelector
});

export const documentDefaultCellTagValueState = atomFamily<string | undefined, [string, string, string | undefined, undefined]>({
  key: 'document-default-cell-tag-value-state',
  default: undefined
});

export const documentTableLoadingAtom = atom<boolean>({
  key: 'document-table-docs-loading',
  default: false
});

export const dmsSharedFilter = atom<boolean>({
  key: 'dms-shared-filter-state',
  default: false
});

export const dmsDateFilter = atom<[string, string]>({
  key: 'dms-date-filter-state',
  default: ['', '']
});

export const entityTreeFilter = atom<string>({
  key: 'dms-entity-tree-search-filter-state',
  default: ''
});

export const entityTreeFilterValue = atom<string>({
  key: 'dms-entity-tree-search-filter-value-state',
  default: ''
});

export const dmsPaginationAtom = atom<TablePaginationConfig>({
  key: 'dms-pagination-atom',
  default: {
    pageSize: 10,
    current: 1,
    showSizeChanger: true,
    style: {
      marginRight: 12
    }
  }
});

export const dmsTableRefresherState = atom<number>({
  key: 'dms-table-refresher-state-atom',
  default: 0
});

export const dmsDraggedDocumentAtom = atom<any>({
  key: 'dms-dragged-document-atom',
  default: undefined
});

export const dmsSelectedDocumentsAtom = atom<Set<Document>>({
  key: 'dms-selected-document-atom',
  default: new Set<Document>()
});

export const dmsPathDocumentsAtom = atom<TransientDocumentTagValue<TagValueType>[]>({
  key: 'dms-path-document-atom',
  default: []
});

export const dmsDocumentsInProgressAtom = atom<any>({
  key: 'dms-documents-in-progress',
  default: new Set<Document>()
});

export const selectedDmsMenu = atom<string>({
  key: 'dms-menu-selected-atom',
  default: firstSelectedMenuSelector
});

export const bulkSelectedPropertiesAtom = atom<{ tagId: string; value: string }[]>({
  key: 'dms-bulk-selected-properties-state',
  default: []
});

export const bulkSelectedEntitiesAtom = atom<number[]>({
  key: 'dms-bulk-selected-entities-state',
  default: []
});

export const bulkSelectedAssetsAtom = atom<number[]>({
  key: 'dms-bulk-selected-assets-state',
  default: []
});

export const dmsLoadingRemountState = atom<boolean>({ key: 'dms-loading-remount-state', default: false });

export const propertiesBulkDrawerOpenedState = atom<boolean>({ key: 'dms-properties-bulk-drawer-opened', default: false });

export const whitelistedEmailsState = atom<string[]>({
  key: 'dms-inbox-white-listed-emails',
  default: []
});
export const documentInboxState = atom<DocumentInbox | null>({
  key: 'dms-inbox',
  default: null
});
export const dmsInboxDocumentsAtom = atom<Document[]>({
  key: 'dms-inbox-documents-atom',
  default: []
});
export const dmsSelectedInboxDocumentsAtom = atom<Set<Document>>({
  key: 'dms-selected-inbox-document-atom',
  default: new Set<Document>()
});
