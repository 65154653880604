import { message } from 'antd';
import { CalcExpression } from '@/modules/reporting-v2/core/CalcExpression';
import {
  buildConditionalMeasure,
  Column,
  ColumnFormatting,
  ConditionalColor,
  ConditionalMeasureObject,
  customColumnIdentifier,
  OverrideSummability
} from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { Filter } from '@/modules/reporting-v2/core/Filter';
import GroupByColumn from '@/modules/reporting-v2/core/GroupByColumn';
import { HistoricalConfig } from '@/modules/reporting-v2/core/HistoricalConfig';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { ChartAxis } from '@/modules/reporting-v2/core/visuals/HistoricalChart/HistoricalChartUtils';
import { Hyperlinks } from '@/modules/reporting-v2/types/Column';
import { RawFilter, RawFilterGroup } from '@/modules/reporting-v2/types/Filter';
import { getRange } from '@/modules/reporting-v2/types/HistoricalConfig';
import { Index } from '@/modules/reporting-v2/types/Index';
import { IndexNodeName } from '@/modules/reporting-v2/types/IndexNode';
import { RawColumn, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { VisualType } from '@/modules/reporting-v2/types/VisualType';
import { duplicateColumnSeparator } from '@/utils/getGenericFieldPath';
import { uniqueValues } from './CollectionUtils';
import { HeaderConfig } from './HeaderConfig';
import { FormatType, NumberFormatType } from '@/common/types/elastic/FormatType';
import { DataPointsFrequency } from '../core/visuals/HistoricalChart/VisualSpecificProps';
import { LogicalFilterGroup } from '@/modules/report-builder/components/Filters/LogicalFilterGroup';
import { FilterGroup } from '../core/FilterGroup';

export const getIndex = (field: string): Index => {
  const properties = field.split(Field.fieldSeparator);
  return properties[0] as Index;
};

export const elasticFieldName = (field: string): Index => {
  return field.split(Field.fieldSeparator).slice(1).join(Field.fieldSeparator) as Index;
};

export type ConfigMappedRawColumn = Partial<{
  default: boolean;
  sortBy: RawColumn;
  field: string;
  compounded: boolean;
  documents: boolean;
  summable: boolean;
  groupable: boolean;
  showSubtotal: boolean;
  initialFieldPath: string;
  isHoldingSetLevel: boolean;
  targetAxis: ChartAxis;
  aggregation: Partial<{
    method: string;
    timeDim: string;
  }>;
  absolute: boolean;
  _sort: string;
  category: string;
  id: string;
  limits: boolean;
  displayName: string;
  isDuplicateColumn: boolean;
  visible: boolean;
  conditionalCalculation: RawFilter[];
  filter: RawFilterGroup[];
  count?: boolean;
  countDistinct?: boolean;
  calc: Array<string | number>;
  calcOnGroup?: boolean;
  calcAfterNormalise?: boolean;
  calcIgnoreFilter?: boolean;
  hyperlink: Hyperlinks;
  currency: { path: string; value: string };
  formatting: Partial<{
    overrideSummability: OverrideSummability;
    type: FormatType;
    decimals: string;
    mode: string;
    color: ConditionalColor;
    displayName: string;
    currency: string;
    customCategory: string;
    description: string;
    format: string;
    nameMeasure?: string;
    numberFormat?: NumberFormatType;
  }>;
  _hideCurrency?: boolean;
  normalise?: boolean;
  dataBars?: boolean;
  displayMode?: boolean;
  quantFilters?: Filter[];
  type?: 'bars' | 'line' | 'area';
  stackedBar?: boolean;
  dataApproximation?: 'close' | 'average' | 'open' | 'sum';
  forceDataGrouping?: boolean;
  hideZeroValues?: boolean;
  customValue?: string;
  rowSpan?: number;
  conditionalMeasure?: ConditionalMeasureObject;
  conditionalMeasureOnGroup?: boolean;
  conditionalMeasureForGroups?: string;
  sortCustom?: string[];
  styling?: Record<string, any>;
  dataPointsFrequency?: DataPointsFrequency;
  min?: number;
  max?: number;
  hideAtGroupLevels?: string[];
  groupMeasureLink: string;
  groupTotalValues?: boolean;
}>;

const specialFields = ['limits.*'];

export const getColumns = (columns: ConfigMappedRawColumn[]): Column[] => {
  const mappedColumns = columns.map(col => {
    const doesNotExist = ReportingService.metas[col.field!] === undefined && !specialFields.includes(col.field!);
    const isCustomField = col.field?.includes(customColumnIdentifier);
    if (doesNotExist && !isCustomField) {
      message.error(`Field ${col.field} does not exist anymore. An attempt to ignore it will be made in order to display the report. Please update the report configuration.`, 10);
      return undefined;
    }

    const code = ReportingService.quantMetas[col.field!] ? col.field : undefined;
    const field = new Field(ReportingService.quantMetas[col.field!]?.elasticPath ?? col.field);
    const currencyDefaultValue = col.currency?.value;
    const overrideSummability = col.formatting?.overrideSummability;

    let currency = col._hideCurrency ? null : col.formatting?.currency || col.currency?.path;
    const isCodeCurrency = currency && !currency?.includes(Field.fieldSeparator);
    if (isCodeCurrency) {
      currency = ReportingService.quantMetas[currency]?.elasticPath;
    }

    let sort = undefined;

    if (col._sort === 'asc') {
      sort = SortOrder.ASC;
    }

    if (col._sort === 'desc') {
      sort = SortOrder.DESC;
    }

    const formatting = new ColumnFormatting(
      currency ? new Field(currency) : undefined,
      col.formatting?.type || FormatType.string,
      ColumnFormatting.buildConditionalColor(col.formatting?.color, field, columns),
      col.formatting?.decimals,
      col.formatting?.description,
      col.formatting?.format,
      currencyDefaultValue,
      col.formatting?.numberFormat
    );
    const isDefault = col.default!;
    const visible = col.visible!;
    const id = col.id;
    const mode = col.formatting?.mode;
    const displayName = col.formatting?.displayName || col.displayName || ReportingService.metas[col.field!]?.displayName;
    const measureAsName = col.formatting?.nameMeasure;
    const category = col.formatting?.customCategory || col.category;
    const headerConfig = new HeaderConfig(displayName, category, measureAsName);
    const hyperlinks = col.hyperlink;
    const isDuplicateColumn = col.isDuplicateColumn;
    const showFirstValueSubtotal = col.showSubtotal;
    const countOnAggregation = col.count;
    const countDistinctOnAggregation = col.countDistinct;
    const targetAxis = col.targetAxis;
    const absolute = col.absolute;
    const normalise = col.normalise;
    const dataBars = col.dataBars;
    const displayMode = col.displayMode;
    const defaultSort = sort;
    const hideZeroValues = col.hideZeroValues;
    const customValue = col.customValue;
    const rowSpan = col.rowSpan;
    const conditionalMeasure = buildConditionalMeasure(col.conditionalMeasure, field, columns);
    const conditionalValueDisplay = col.conditionalCalculation?.flatMap(filter => {
      if (filter.operator === undefined) return [];
      return Filter.fromRawFilter(filter, columns, field);
    });

    let conditionalRowDisplay: LogicalFilterGroup[] = [];
    if (Array.isArray(col.filter)) {
      conditionalRowDisplay = [new LogicalFilterGroup(col.filter, columns, undefined, field)];
    }

    let calcFields: Array<string | number> = [];
    if (col.calc) {
      calcFields = col.calc.map(expr => {
        const expression = String(expr);

        if (!expression.includes(duplicateColumnSeparator)) {
          if (ReportingService.metas[expression]) {
            return new Field(expression).getElasticPath();
          }
          return expr;
        } else {
          const [, fieldId] = expression.split(duplicateColumnSeparator);
          const column = columns.find(col => col.initialFieldPath?.includes(fieldId));

          if (!column) {
            const errorMessage = `Calculation formula for column ${col.initialFieldPath} is pointing at fields that have been modified. Please update the configuration`;
            message.error(errorMessage);
          }

          if (!column?.field || !column?.id) {
            message.error(`Issue with calculation formula for column ${col.initialFieldPath}`);
            return field.getElasticPath();
          }

          const elasticPath = ReportingService.metas[column.field].elasticPath;
          return elasticPath + column.id;
        }
      });
    }
    const hasCalcExpression = col.calc && col.calc[0] !== null && col.calc[0] !== undefined;
    const calcExpression = hasCalcExpression ? new CalcExpression(calcFields) : undefined;
    const calcOnGroup = col.calcOnGroup ?? false;
    const calcAfterNormalise = col.calcAfterNormalise;
    const calcIgnoreFilter = col.calcIgnoreFilter;

    const quantFilters = ReportingService.metas[col.field!]?.quantFilters ?? undefined;
    const sortBy = col.sortBy?.defaultColumns?.length ? col.sortBy.defaultColumns[0] : undefined;

    return new Column({
      code,
      id,
      field,
      sortBy,
      formatting,
      defaultSort,
      targetAxis,
      headerConfig,
      hyperlinks,
      visible,
      isDefault,
      conditionalValueDisplay,
      calcExpression,
      calcOnGroup,
      calcAfterNormalise,
      calcIgnoreFilter,
      countOnAggregation,
      overrideSummability,
      showFirstValueSubtotal,
      countDistinctOnAggregation,
      absolute,
      conditionalRowDisplay,
      isDuplicateColumn,
      mode,
      normalise,
      dataBars,
      displayMode,
      quantFilters,
      hideZeroValues,
      customValue,
      rowSpan,
      conditionalMeasure,
      conditionalMeasureOnGroup: col.conditionalMeasureOnGroup,
      conditionalMeasureForGroups: col.conditionalMeasureForGroups,
      styling: col.styling,
      sortCustom: col.sortCustom,
      dataPointsFrequency: col.dataPointsFrequency,
      min: col.min,
      max: col.max,
      initialFieldPath: col.initialFieldPath,
      groupTotalValues: col.groupTotalValues,
      hideAtGroupLevels: col.hideAtGroupLevels,
      forceDataGrouping: col.forceDataGrouping
    });
  });

  return mappedColumns.filter(col => col !== undefined) as Array<Column>;
};

const getGroupColumnFieldDataPath = (groupMeasureLink: string | undefined, groupColumnField: Field, columns: Column[]): string => {
  if (!groupMeasureLink) {
    return groupColumnField.getElasticPath();
  }

  const measureLinkedIsDuplicateMeasure = groupMeasureLink.includes(duplicateColumnSeparator);
  if (!measureLinkedIsDuplicateMeasure) {
    const column = columns.find(col => col.code === groupMeasureLink);
    if (!column) {
      console.warn('Unable to find the measure linked to group ' + groupColumnField.name);
      return groupColumnField.getElasticPath();
    }
    return column.fieldDataPath;
  }

  const [colCode, colId] = groupMeasureLink.split(duplicateColumnSeparator);
  const column = columns.find(col => col.code === colCode && col.id === colId);

  if (!column) {
    console.warn('Unable to find the measure linked to group ' + groupColumnField.name);
    return groupColumnField.getElasticPath();
  }

  return column.fieldDataPath;
};

export const getGroupColumns = (groupColumns: ConfigMappedRawColumn[], columns: Column[]) => {
  return groupColumns.map(groupColumn => {
    const field = new Field(groupColumn.field as string);
    const isDefault = Boolean(groupColumn.default);
    const displayName = groupColumn.formatting?.displayName || groupColumn.displayName || ReportingService.metas[groupColumn.field!]?.displayName;
    const headerConfig = new HeaderConfig(displayName);
    let conditionalRowDisplay: LogicalFilterGroup[] = [];
    if (Array.isArray(groupColumn.filter)) {
      conditionalRowDisplay = [new LogicalFilterGroup(groupColumn.filter, [...columns, ...groupColumns] as Column[], undefined, field)];
    }
    const conditionalValueDisplay =
      groupColumn.conditionalCalculation?.flatMap(filter =>
        filter.operator === undefined ? [] : Filter.fromRawFilter(filter, [...columns, ...groupColumns] as Column[], field)
      ) || [];
    const formatting = new ColumnFormatting(
      undefined,
      groupColumn.formatting?.type || FormatType.string,
      ColumnFormatting.buildConditionalColor(groupColumn.formatting?.color, field, columns),
      undefined,
      groupColumn.formatting?.description,
      groupColumn.formatting?.format
    );
    const fieldDataPath = getGroupColumnFieldDataPath(groupColumn.groupMeasureLink, field, columns);

    return new GroupByColumn(fieldDataPath, field, isDefault, headerConfig, undefined, conditionalRowDisplay, conditionalValueDisplay, formatting);
  });
};

export const getHistoricalConfig = (type: VisualType, range?: string, sampling?: boolean) => {
  if (range === 'SELECTED_DATE' || type !== VisualType.HISTORICAL) {
    return HistoricalConfig.disabled();
  }

  return HistoricalConfig.enabled(getRange(range), sampling);
};

// TODO : more or less naive implemention of knowing if a visual is historical. might have to work some more on it. there may be specific index calls that are when he's originally not
export const getType = (component: VisualComponent, forceHistorical: boolean) => {
  if (forceHistorical) {
    return VisualType.HISTORICAL;
  }

  switch (component) {
    case VisualComponent.HoldingSetInfo:
      return VisualType.WIDGET;

    case VisualComponent.DateSlicer:
    case VisualComponent.SelectBox:
      return VisualType.FILTER;

    case VisualComponent.HistoricalChart:
    case VisualComponent.HistoricalMonthlyTab:
    case VisualComponent.PerformanceTable:
    case VisualComponent.ScatterChart:
    case VisualComponent.FlowsTable:
    case VisualComponent.LimitsTable:
    case VisualComponent.CallOut:
      return VisualType.HISTORICAL;

    default:
      return VisualType.DEFAULT;
  }
};

export const getIndexNodeName = (field: string): IndexNodeName => {
  const properties = field.split(Field.fieldSeparator);

  if (properties.length > 2) {
    return properties.slice(0, -1).join(Field.fieldSeparator);
  } else {
    return properties[0];
  }
};

export const concatenateUniqueIndexFields = (...fields: Field[][]): Field[] => {
  return uniqueValues(fields.flatMap(item => item).map(item => item.getElasticPath())).map(name => new Field(name));
};

export const isReceiver = (contextType: string): boolean => ['BOTH', 'RECEIVER'].includes(contextType);

export const isEmitter = (contextType: string): boolean => ['BOTH', 'EMITTER'].includes(contextType);

export const arrayAsKey = (values: Array<any>, separator = '|'): string => {
  return values
    .map(value => {
      switch (typeof value) {
        case 'boolean':
          return value ? 1 : 0;
        case 'undefined':
          return 'undefined';
        case 'object':
          if (value === null) return 'undefined';
          else return value.toString();
        default:
          return value.toString();
      }
    })
    .join(separator);
};
