import TransientDocumentTag from '@/modules/DMS/types/TransientDocumentTag';
import HierarchicalEntityLookup from '@/common/types/entity/HierarchicalEntityLookup';
import { BasicTagType, DocumentTagType, EntityTagType } from '@/common/types/entity/DocumentTags';
import TransientDocumentTagValue from '@/modules/DMS/types/TransientDocumentTagValue';
import { DocumentTagValueNode } from '@/modules/DMS/components/EntityTree/TagTree';
import EntityLookup from '@/common/types/entity/EntityLookup';
import { Document } from '../../types/Document';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const convertToDocumentTag = (
  tag: TransientDocumentTag,
  lookup: HierarchicalEntityLookup<EntityTagType>,
  tagValues: Map<number, TransientDocumentTagValue<EntityTagType>>
): DocumentTagValueNode<EntityTagType> => {
  return {
    value: tagValues.get(lookup.id) || new TransientDocumentTagValue(tag, tag.type, lookup),
    children: lookup.children?.map(node => convertToDocumentTag(tag, node, tagValues))
  } as DocumentTagValueNode<EntityTagType>;
};

export const buildEntityTree = async (
  entities: HierarchicalEntityLookup<EntityLookup>[],
  tag: TransientDocumentTag,
  tagValues: TransientDocumentTagValue<EntityTagType>[]
): Promise<DocumentTagValueNode<EntityTagType>[]> => {
  const tagValuesMap = new Map(tagValues.map(value => [value.value.id, value]));
  return entities.map(node => convertToDocumentTag(tag, node, tagValuesMap));
};

export const buildBasicTree = async (tag: TransientDocumentTag, tagValues: TransientDocumentTagValue<BasicTagType>[]): Promise<DocumentTagValueNode<BasicTagType>[]> => {
  return Promise.resolve(
    tagValues.map(value => {
      return { value } as DocumentTagValueNode<BasicTagType>;
    })
  );
};
export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const updateAllDocuments = (allDocuments: Document[], updatedDocuments: Document[]) => {
  const updatedDocumentMap = new Map(updatedDocuments.map(doc => [doc.id, doc])); // Create a map from updated documents

  const updatedAllDocuments = allDocuments.map(doc =>
    updatedDocumentMap.has(doc.id) ? updatedDocumentMap.get(doc.id)! : doc // Replace the document if it's in the updated set
  );

  return updatedAllDocuments;
};

export const timeAgo = (time?: string | Date) => dayjs(time).fromNow();

type TagWidthT<R> = { [key in keyof typeof DocumentTagType]: R };
export const TagColumnWidth: TagWidthT<number> = {
  TEXT: 120,
  DATE: 106,
  NUMBER: 120,
  ASSET: 120,
  HOLDING_SET: 120,
  JSON: 120
};

