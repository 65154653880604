import { ActivityDTO, Contact, ContactLookup, ContactType, NoteDTO, Status, TaskDTO } from '@/modules/ERP/Contacts/types';
import { atom, selector, selectorFamily } from 'recoil';
import { getContacts, getContactsEnriched } from '@/modules/ERP/Contacts/Api/getContacts';
import { getContactDetails } from '@/modules/ERP/Contacts/Api/getContactDetails';
import { UUID } from '@/common/types/types';
import { retrieveContactRecoilNode } from '@/modules/ERP/Contacts/Utils/ContactUtils';
import { PageTemplate } from '@/utils/retrievePageTemplate';
import { getNotes, getNotesByIds } from '@/modules/ERP/Contacts/Api/getNotes';
import { getTasks, SearchTasksInterface } from '@/modules/ERP/Contacts/Api/getTasks';
import { ActivityType, EntityLinkType } from '@/common/types/entity/Link';
import { entityLinksSelector, erpFilterState } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { taskDateFilterNodeId } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Tasks';
import { compareLastModifiedDate } from '@/modules/ERP/Documents/utils';
import { ActivityFilter, DaysPeriodFilter } from '@/modules/ERP/Contacts/ContactDetails/Tabs/TabsFilters';
import { User } from '@/types';
import getUsersForCurrentUser from '@/api/getUsersForCurrentUser';
import { getTaskById } from '@/modules/ERP/Contacts/Api/getTaskById';
import { getActivity } from '@/modules/ERP/Contacts/Api/getActivity';
import { activityActivityFilterNodeId, activityDateFilterNodeId } from '@/modules/ERP/Common/Activity/Activity';
import { notesDateFilterNodeId } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Notes/Notes';
import { EntitySearch } from '@/modules/Onboarding/Shared/types';
import { notesFilterState, taskFilterState } from '@/modules/TMS/components/list/task.atoms';
import { loggedUserSelector } from '@/common/auth/recoil/user.selector';
import { taskAssigneeFilterState, taskCreatedByFilterState, taskPriorityFilterState, taskStatusFilterState } from '@/modules/TMS/recoil/task.atoms';
import { noteCreatedByFilterState } from '@/modules/NMS/recoil/note.atoms';
import { ListItem } from '@/common/components/SearchOverlay';
import { siteTreeEntryState } from '@/modules/App/recoil/app.atoms';
import { PersistentTask } from '@/common/types/entity/Task';
import { PaginatedResponse } from '@/modules/ReportCentre/components/DynamicTable/types';

export const tablePersonContactsSelector = selectorFamily<PaginatedResponse<Array<ContactLookup>>, EntitySearch>({
  key: 'contacts-persons-table-list-selector',
  get:
    search =>
    ({ get }) => {
      return get(enrichedContactLookupsSelector({ type: ContactType.PERSON, ...search }));
    }
});

export const tableCompanyContactsSelector = selectorFamily<PaginatedResponse<Array<ContactLookup>>, EntitySearch>({
  key: 'contacts-company-table-list-selector',
  get:
    search =>
    ({ get }) => {
      return get(enrichedContactLookupsSelector({ type: ContactType.COMPANY, ...search }));
    }
});

const enrichedContactLookupsSelector = selectorFamily<PaginatedResponse<Array<ContactLookup>>, EntitySearch>({
  key: 'contacts-enriched-lookup-selector',
  get: params => async () => {
    const { ids, ...rest } = params;

    return getContactsEnriched(rest, ids);
  }
});

const contactLookupsSelector = selectorFamily<Array<ContactLookup>, EntitySearch>({
  key: 'contacts-lookup-selector',
  get: params => async () => {
    const { ids, ...rest } = params;

    return getContacts(rest, ids);
  }
});

const usersLookupsSelector = selector<Array<User>>({
  key: 'users-lookup-selector',
  get: async ({ get }) => {
    const currentLoggedInUser = get(loggedUserSelector);
    return getUsersForCurrentUser(currentLoggedInUser.accountId);
  }
});

const contactDetailsSelector = selectorFamily<Contact | null, UUID | null>({
  key: 'contact-details-selector',
  get: id => async () => {
    if (id === null) {
      return null;
    }
    return getContactDetails(id);
  }
});

const contactListItemSelector = selectorFamily<ListItem, UUID>({
  key: 'contact-details-selector',
  get:
    id =>
    ({ get }) => {
      const template = get(siteTreeEntryState)?.view;
      const contact = get(contactDetailsSelector(id))!;
      return {
        id: contact.id,
        label: template === PageTemplate.CONTACTS_LIST_COMPANIES ? contact.company : contact.firstName + ' ' + contact.lastName
      };
    }
});

const contactTaskByIdSelector = selectorFamily<TaskDTO, UUID>({
  key: 'contact-task-by-id-selector',
  get: id => async () => {
    return getTaskById(id);
  }
});

export const currentTaskState = atom<PersistentTask | null>({
  key: 'current-task-state',
  default: null
});

const contactNoteByIdSelector = selectorFamily<Array<NoteDTO>, UUID>({
  key: 'contact-note-by-id-selector',
  get: id => async () => {
    return getNotesByIds(id);
  }
});

const entityActivitySelector = selectorFamily<ActivityDTO[], [ActivityType, string]>({
  key: 'entity-activities-selector',
  get:
    ([entityType, entityId]) =>
    async () => {
      return await getActivity(entityType, entityId);
    }
});

const filteredEntityActivitySelector = selectorFamily<ActivityDTO[], [ActivityType, string]>({
  key: 'filtered-entity-activities-selector',
  get:
    ([entityType, contactId]) =>
    async ({ get }) => {
      let activities = get(entityActivitySelector([entityType, contactId]));
      const dateFilter = get(erpFilterState(activityDateFilterNodeId));
      const typeFilter = get(erpFilterState(activityActivityFilterNodeId));

      if (dateFilter) {
        activities = activities.filter(activity => compareLastModifiedDate(activity.timestamp, dateFilter as DaysPeriodFilter));
      }

      if (typeFilter && typeFilter !== ActivityFilter.ALL) {
        activities = activities.filter(activity => activity.entityType === typeFilter);
      }
      return activities;
    }
});

const contactsTasksSelector = selectorFamily<Array<TaskDTO>, [SearchTasksInterface, EntityLinkType, UUID?, boolean?]>({
  key: 'contacts-tasks-selector',
  get:
    ([search, entityType, id, ifNoIdsReturnEmpty]) =>
    async ({ get }) => {
      const links = get(entityLinksSelector([entityType, id]));
      const tasksLinks = links.filter(link => link.type === EntityLinkType.TASK);
      const ids = tasksLinks.map(link => link.entityId);

      if (!ids.length && ifNoIdsReturnEmpty) {
        return [];
      }

      const searchParam = { ...search };
      if (ids.length !== 0 || search.ids) {
        searchParam.ids = search.ids ? search.ids : ids;
      }

      const tasks = await getTasks(searchParam);

      const filter = get(erpFilterState(taskDateFilterNodeId));

      if (filter) {
        return tasks.filter(task => compareLastModifiedDate(task.lastModifiedDate, filter as DaysPeriodFilter));
      }

      return tasks;
    }
});

const tasksListSelector = selectorFamily<Array<TaskDTO>, boolean | undefined>({
  key: 'contacts-tasks-selector',
  get: includeRemoved => async () => {
    return await getTasks({ includeRemoved: !!includeRemoved });
  }
});

const noteListSelector = selector<Array<NoteDTO>>({
  key: 'contacts-notes-selector',
  get: async () => {
    return await getNotes();
  }
});

const nodeListItemsSelector = selector<Array<ListItem>>({
  key: 'contacts-nodes-list-item-selector',
  get: ({ get }) => {
    const notes = get(noteListSelector);
    return notes.map(note => ({
      id: note.id,
      label: note.title
    }));
  }
});

const taskListTableSelector = selector<Array<TaskDTO>>({
  key: 'task-table-selector',
  get: ({ get }) => {
    let tasks = get(tasksListSelector(true));

    const taskFilter = get(taskFilterState);
    const taskAssigneeFilter = get(taskAssigneeFilterState);
    const taskCreatedByFilter = get(taskCreatedByFilterState);
    const taskPriorityFilter = get(taskPriorityFilterState);
    const taskStatusFilter = get(taskStatusFilterState);
    const filterTask = (filter: string, fieldToFilter: string) => {
      return fieldToFilter?.replace(/ /g, String()).toLowerCase().includes(filter.toLowerCase().replace(/ /g, String()));
    };

    if (taskFilter) {
      tasks = tasks.filter(task => filterTask(taskFilter, task.title));
    }
    if (taskAssigneeFilter) {
      tasks = tasks.filter(task => !!task.assignee).filter(task => filterTask(taskAssigneeFilter, task.assignee.firstName + ' ' + task.assignee.lastName));
    }
    if (taskCreatedByFilter) {
      tasks = tasks.filter(task => filterTask(taskCreatedByFilter, task.createdBy));
    }
    if (taskPriorityFilter) {
      tasks = tasks.filter(task => filterTask(taskPriorityFilter, task.priority));
    }
    if (taskStatusFilter) {
      tasks = tasks.filter(task => filterTask(taskStatusFilter, task.status));
    }
    if (taskStatusFilter !== Status.REMOVED) {
      return tasks.filter(task => task.status !== Status.REMOVED);
    }
    return tasks;
  }
});

const notesListTableSelector = selector<Array<NoteDTO>>({
  key: 'notes-table-selector',
  get: ({ get }) => {
    let notes = get(noteListSelector);

    const notesFilter = get(notesFilterState);
    const notesCreatedByFilter = get(noteCreatedByFilterState);

    const filterNote = (filter: string, filterNote: string) => {
      return filterNote?.replace(/ /g, String()).toLowerCase().includes(filter.toLowerCase().replace(/ /g, String()));
    };

    if (notesFilter) {
      notes = notes.filter(note => filterNote(notesFilter, note.title));
    }
    if (notesCreatedByFilter) {
      notes = notes.filter(note => !!note.author).filter(note => filterNote(notesCreatedByFilter, note.author.firstName + ' ' + note.author.lastName));
    }

    return notes;
  }
});

const contactsNotesSelector = selectorFamily<Array<NoteDTO>, [string, EntityLinkType, UUID]>({
  key: 'contacts-notes-selector',
  get:
    ([search, entityType, contactId]) =>
    async ({ get }) => {
      const links = get(entityLinksSelector([entityType, contactId]));
      const noteLinks = links.filter(link => link.type === EntityLinkType.NOTE);

      const ids = noteLinks
        // temp, we should remove entries in db and see if creation is correct now
        .filter(l => l.entityId !== 'undefined')
        .map(link => link.entityId);
      let notes = await getNotesByIds(ids.join(','));

      const filter = get(erpFilterState(notesDateFilterNodeId));
      if (filter) {
        notes = notes.filter(note => compareLastModifiedDate(note.date, filter as DaysPeriodFilter));
      }

      if (search) {
        notes = notes.filter(note => note.title.toLowerCase().includes(search) || note.content.toLowerCase().includes(search));
      }

      notes.sort((a, b) => b.date.localeCompare(a.date));
      return notes;
    }
});

const contactListItemsSelector = selector<Array<ListItem>>({
  key: 'contacts-list-item-selector',
  get: ({ get }) => {
    const template = get(siteTreeEntryState)?.view as PageTemplate;
    const dataSourceContacts = get(retrieveContactRecoilNode({}, template ?? undefined));
    return dataSourceContacts.content.map(contact => ({
      id: contact.id,
      label: template === PageTemplate.CONTACTS_LIST_COMPANIES ? contact.company! : contact.firstName + ' ' + contact.lastName
    }));
  }
});

export {
  contactDetailsSelector,
  contactLookupsSelector,
  enrichedContactLookupsSelector,
  contactsNotesSelector,
  contactsTasksSelector,
  contactTaskByIdSelector,
  usersLookupsSelector,
  entityActivitySelector,
  contactNoteByIdSelector,
  filteredEntityActivitySelector,
  tasksListSelector,
  taskListTableSelector,
  notesListTableSelector,
  nodeListItemsSelector,
  contactListItemSelector,
  contactListItemsSelector
};
