import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import fastSort from 'fast-sort';
import HistoricalChartConfig from './HistoricalChartConfig';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { ExtendedRowGroup, HistoricChartOptions, HistoricChartSeriesOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import deepmerge from 'deepmerge';
import { Column } from '@/modules/reporting-v2/core/Column';
import { PointOptionsObject, TooltipFormatterCallbackFunction, TooltipFormatterContextObject, YAxisOptions } from 'highcharts';
import { DataPointsFrequency, TickRate } from './VisualSpecificProps';
import { EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { getDateTimeLabelFormats } from './utils/getDateTimeLabelFormats';
import { buildTicks } from './utils/buildTicks';
import { type FirstLastPoints, getFirstAndLastPoints } from './utils/getFirstAndLastPoints';
import { getTooltipString } from './utils/getTooltipString';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { getLabelsNamesHistoricalSeries } from '@/modules/reporting-v2/utils/getLabelsNamesHistoricalSeries';
import { getRecoilState } from '@/core/RecoilExternalStatePortal';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { IContext } from '../../ReportContext';
import { groupBy } from '@/utils/groupBy';
import { getConditionalColor } from '@/modules/reporting-v2/core/utils';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import type { FormattedTooltipPoint } from './types';
import './styles.css';

dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);

export enum ChartAxis {
  Axis0,
  Axis1
}

type CustomDataGrouping = {
  approximation: 'close' | 'average' | 'sum' | 'open';
  units: DataPointsFrequency;
};

class HistoricalChartUtils {
  private static getBarColumn = function (serie: Record<'axis0' | 'axis1', Record<string, any>> | undefined, columns: Column[]): Column | undefined {
    if (!serie || (!serie.axis0 && !serie.axis1)) {
      return undefined;
    }

    const axis0ColumnBar = Object.entries(serie.axis0).find(([code, col]) => col.type === 'bars');
    if (axis0ColumnBar) {
      return columns.find(column => column.code === axis0ColumnBar[0]);
    }

    const axis1ColumnBar = Object.entries(serie.axis1).find(([code, col]) => col.type === 'bars');
    if (axis1ColumnBar) {
      return columns.find(column => column.code === axis1ColumnBar[0]);
    }

    return undefined;
  };

  private static getSmallestFrequency = (array: DataPointsFrequency[]): DataPointsFrequency => {
    const sortedList = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];

    return [...array].sort((a, b) => sortedList.indexOf(a) - sortedList.indexOf(b))[0];
  };

  private static getTimeRange = (frequency: DataPointsFrequency) => {
    const oneDay = 24 * 3600 * 1000;
    switch (frequency) {
      case DataPointsFrequency.YEARLY:
      case DataPointsFrequency.QUARTERLY:
      case DataPointsFrequency.MONTHLY:
        return 30 * oneDay;
      case DataPointsFrequency.WEEKLY:
        return 7 * oneDay;
      case DataPointsFrequency.DAILY:
      default:
        return oneDay;
    }
  };

  private static getPointRange = function (serie: Record<'axis0' | 'axis1', Record<string, any>> | undefined, columns: Column[]): number | undefined {
    // point range should be calculated only if we have measures set for Axis #2 in Report
    if (!serie || !Object.keys(serie.axis1).length) {
      return undefined;
    }
    const getRange = (axisBarColumns: string[]) => {
      const dataPointsFrequencies = columns
        .filter(col => col.code && axisBarColumns.includes(col.code))
        .reduce<DataPointsFrequency[]>((acc, col) => {
          return col.dataPointsFrequency ? [...acc, col.dataPointsFrequency] : acc;
        }, []);
      if (!dataPointsFrequencies.length) {
        return undefined;
      }
      const smallestFrequency = HistoricalChartUtils.getSmallestFrequency(dataPointsFrequencies);

      return HistoricalChartUtils.getTimeRange(smallestFrequency);
    };

    const axis0BarColumns = Object.entries(serie.axis0)
      .filter(([code, col]) => col.type === 'bars')
      .map(item => item[0]);

    if (axis0BarColumns.length) {
      return getRange(axis0BarColumns);
    }

    const axis1BarColumns = Object.entries(serie.axis1)
      .filter(([code, col]) => col.type === 'bars')
      .map(item => item[0]);

    if (axis1BarColumns.length) {
      return getRange(axis1BarColumns);
    }

    return undefined;
  };

  static retrieveEntities(entityOrdering: Array<number>, multiEntityFeatures?: boolean) {
    const currentUser = getRecoilState(currentUserSelector);
    const tree = currentUser.holdingSetTree;

    const params = EntityParametersHandler.retrieveParamsList(currentUser, undefined, multiEntityFeatures);
    return entityOrdering.map(index => findHoldingSetById(tree, params[index]?.holdingSetId)!).filter(hset => hset !== undefined);
  }

  static getOptions = (
    visual: HistoricalChartConfig,
    columns: Column[],
    rows: ExtendedRowGroup[],
    fullSize?: boolean,
    multiEntityFeatures?: boolean,
    context?: IContext
  ): HistoricChartOptions => {
    const deepGroup = visual.groupByColumns.filter(group => group.isDefault).length > 1;
    const entities = this.retrieveEntities(visual.entityOrdering, multiEntityFeatures);
    const columnWithColorFormatting = columns.find(column => {
      const columnColorFormatting = column.formatting?.color;
      if (!columnColorFormatting) {
        return false;
      }

      if (typeof columnColorFormatting === 'string') {
        return true;
      }

      return !!columnColorFormatting.highlight || columnColorFormatting.conditions?.length;
    });
    const multipleEntities = entities.length > 1;
    const isEntityBenchmark =
      visual.columns.filter(col => {
        const fieldPath = col.field.getElasticPath();
        return ['holdingset', 'benchmark'].includes(fieldPath.split('.')[1]?.toLowerCase());
      })?.length >= 2;

    const getGroupsFromRows = (_rows: ExtendedRowGroup[]) => {
      return [
        ...new Set(
          _rows.flatMap(row => {
            return row.group ?? '';
          })
        )
      ];
    };

    // remove date column from columns
    const dateColumnIndex = columns.findIndex(column => column.fieldDataPath.includes('.date'));
    if (dateColumnIndex === -1) {
      throw new Error('Date column not found');
    }
    const columnsFiltered = columns.toSpliced(dateColumnIndex, 1);

    const series = visual.series! ?? {};
    const axisIndexedData = [
      {
        columns: columns.filter(column => series.axis0?.[column.code ?? column.field.name] && column.targetAxis === ChartAxis.Axis0)
      },
      {
        columns: columns.filter(column => series.axis1?.[column.code ?? column.field.name] && column.targetAxis === ChartAxis.Axis1)
      }
    ].filter(({ columns }) => columns.length);
    const barColumn = this.getBarColumn(series, columns);
    const pointRange = this.getPointRange(series, columns);
    const rowsSorted = fastSort(rows).asc(row => row && dayjs(new Date(row.data[columns[dateColumnIndex].field.getElasticPath()] as string)).valueOf());

    // get column index, ignoring date column
    const getColumnIndex = (columnIndex: number) => {
      if (dateColumnIndex <= columnIndex) {
        return columnIndex + 1;
      }
      return columnIndex;
    };

    const groupSeries = (sortedSeries: HistoricChartSeriesOptions[]): HistoricChartSeriesOptions[] => {
      const seriesToShow = sortedSeries.splice(0, visual.showTopSeries);

      const tempValues: PointOptionsObject[] = [];
      sortedSeries.forEach(serie => {
        for (const d of serie.data as PointOptionsObject[]) {
          const el = tempValues.find(t => t.x === d.x);
          if (el) {
            el.y! += d.y || 0;
          } else {
            tempValues.push(d);
          }
        }
      });

      seriesToShow.push({
        stacking: seriesToShow[0].stacking,
        type: seriesToShow[0].type,
        id: 'Other',
        name: 'Other',
        data: tempValues
      });

      return seriesToShow;
    };

    const sortSeries = (allSeries: HistoricChartSeriesOptions[]) => {
      allSeries.sort((a, b) => {
        const aSum = (a.data as PointOptionsObject[]).reduce((prev: number, curr: PointOptionsObject) => prev + (curr.y || 0), 0);
        const bSum = (b.data as PointOptionsObject[]).reduce((prev: number, curr: PointOptionsObject) => prev + (curr.y || 0), 0);

        const aAverage = aSum / (a.data?.length || 1);
        const bAverage = bSum / (b.data?.length || 1);

        return bAverage - aAverage;
      });
    };

    const filterByTimeRange = (series: HistoricChartSeriesOptions[]) => {
      return series.map(seriesItem => {
        if (!seriesItem.data || seriesItem.data.length === 0) {
          return seriesItem;
        }
        const endDate = seriesItem.data[seriesItem.data.length - 1] as PointOptionsObject | undefined;

        if (!endDate || !endDate.x) {
          return seriesItem;
        }
        const start = ReportingService.calculateFromDate(endDate.x, visual.dateRange as any);

        const useCustomStartDate = visual.startDate !== undefined && dayjs(endDate.x).isAfter(visual.startDate);

        const startChecked = useCustomStartDate ? visual.startDate : start;

        if (!startChecked) {
          return seriesItem;
        }

        const startDate = new Date(startChecked).getTime();

        return {
          ...seriesItem,
          data: seriesItem.data
            ? seriesItem.data.filter(item => {
                return (item as any).x >= startDate && (item as any).x <= (endDate.x as any);
              })
            : []
        };
      });
    };

    const sortAndFormatTooltipPoints = (points: TooltipFormatterContextObject[]): FormattedTooltipPoint[] => {
      const sortedPoints = [...points].sort((a, b) => {
        return b.y - a.y;
      });

      return sortedPoints.map(point => {
        const formattedValue = HighChartsDataUtils.formatCell(
          point.y,
          point.point.options.custom?.column ?? columns[0],
          point.point.options.custom?.data ?? {},
          context?.reportConfiguration?.config.numberLocale
        );

        return {
          value: formattedValue as string,
          serieName: point.series.name,
          series: point.series
        };
      });
    };

    const tooltipFormatter: TooltipFormatterCallbackFunction = function () {
      const sortedPoints = sortAndFormatTooltipPoints(this.points ?? []);
      const date = new Date(this.x);

      return [date.toDateString(), ...getTooltipString(sortedPoints)];
    };

    const getSeriesStacking = (isStacked: boolean | undefined, seriesType: HistoricChartSeriesOptions['type']): HistoricChartSeriesOptions['stacking'] => {
      if (!isStacked) {
        return undefined;
      }

      if (seriesType === 'column') {
        return 'normal';
      }

      if (seriesType === 'area') {
        return 'percent';
      }

      return undefined;
    };

    const getSeriesType = (axisItemType: string | undefined): HistoricChartSeriesOptions['type'] => {
      if (!axisItemType || axisItemType === 'line') {
        return 'line';
      }

      if (axisItemType === 'bars') {
        return 'column';
      }

      return 'area';
    };

    const getSafeTickRate = (tickRate: TickRate, { firstPoint, lastPoint }: FirstLastPoints) => {
      const tickrateIsDaily = tickRate === TickRate.daily;
      const tickRateIsWeekly = tickRate === TickRate.week_ends || tickRate === TickRate.week_starts;

      if (!tickrateIsDaily && !tickRateIsWeekly) {
        return tickRate;
      }

      const monthDiffBetweenPoints = dayjs(lastPoint).diff(dayjs(firstPoint), 'months');
      const dateRangeIsMoreThan3Months = monthDiffBetweenPoints > 3;
      if (tickrateIsDaily && dateRangeIsMoreThan3Months) {
        return TickRate.auto;
      }

      const dateRangeIsMoreThan6Months = monthDiffBetweenPoints > 6;
      if (tickRateIsWeekly && dateRangeIsMoreThan6Months) {
        return TickRate.auto;
      }

      return tickRate;
    };

    const getColorFromRows = (data: PointOptionsObject[]): string | undefined => {
      let color = undefined;
      const colors = [...new Set(data.map(d => d.custom?.color))];

      const serieOnlyHas1Color = colors.length === 1;
      const colorIsNotUndefined = colors[0] !== undefined;
      if (serieOnlyHas1Color && colorIsNotUndefined) {
        color = colors[0];
      }

      return color;
    };

    const customSortSeries = (series: HistoricChartSeriesOptions[]): HistoricChartSeriesOptions[] => {
      const newSeries: HistoricChartSeriesOptions[] = [];

      visual.customSort?.forEach(customSort => {
        const serie = series.find(serie => serie.name === customSort);
        if (serie) {
          newSeries.push(serie);
        }
      });

      series.forEach(serie => {
        const isAlreadyInNewSeries = newSeries.some(s => s.name === serie.name);
        if (!isAlreadyInNewSeries) {
          newSeries.push(serie);
        }
      });

      return newSeries;
    };

    const getDateUnitFromFrequency = (datapointsFrequency: DataPointsFrequency): 'month' | 'year' | 'quarter' | 'week' | 'dayOfYear' => {
      switch (datapointsFrequency) {
        case DataPointsFrequency.MONTHLY: {
          return 'month';
        }
        case DataPointsFrequency.YEARLY: {
          return 'year';
        }
        case DataPointsFrequency.QUARTERLY: {
          return 'quarter';
        }
        case DataPointsFrequency.WEEKLY: {
          return 'week';
        }
        case DataPointsFrequency.DAILY:
        default: {
          return 'dayOfYear';
        }
      }
    };

    const getRowDataGrouped = (rows: ExtendedRowGroup[], dataGrouping: CustomDataGrouping, columnCellIndex: number, hasDataGrouping: boolean | undefined) => {
      if (!hasDataGrouping) {
        return rows;
      }

      const useRowGroup = visual.groupByColumns.length > 1;
      const dateUnit = getDateUnitFromFrequency(dataGrouping.units);
      const groupedRows: Record<string, ExtendedRowGroup[]> = groupBy(rows, (row: ExtendedRowGroup) => {
        const date = dayjs(new Date(row.cells[dateColumnIndex] as string));

        return `${date.get('year')}-${date[dateUnit]()}-${useRowGroup ? row.group || '' : ''}`;
      });

      const dataGroupedRows = Object.values(groupedRows).map(rows => {
        const flatRows = deepGroup ? rows : rows.flatMap(row => row.rows ?? row);

        if (dataGrouping.approximation === 'open') {
          return flatRows[0];
        }

        const lastRow = flatRows[flatRows.length - 1];
        if (dataGrouping.approximation === 'average') {
          const sum = flatRows.reduce((prev, curr) => prev + (curr.cells[columnCellIndex] as number), 0);
          lastRow.cells[columnCellIndex] = sum / flatRows.length;
        }
        if (dataGrouping.approximation === 'sum') {
          const sum = flatRows.reduce((prev, curr) => prev + (curr.cells[columnCellIndex] as number), 0);
          lastRow.cells[columnCellIndex] = sum;
        }

        return lastRow;
      });

      return dataGroupedRows;
    };

    const getRowColor = (data: FlattenObject) => {
      if (!columnWithColorFormatting) {
        return;
      }

      return getConditionalColor(columnWithColorFormatting, data);
    };

    let mappedSeries = entities.flatMap(entity => {
      let benchmarkNames = entity.performanceBenchmarks.map(entity => entity.name).join(' + ');
      const defaultBenchmark = entity.performanceBenchmarks.find(benchmark => benchmark.default);
      // we should display name of default benchmark, if it exists
      if (defaultBenchmark) {
        benchmarkNames = defaultBenchmark?.name || '';
      }

      return columnsFiltered.flatMap<HistoricChartSeriesOptions>((column, index) => {
        const columnCellIndex = getColumnIndex(index);
        const axisItem = series?.[`axis${column.targetAxis!}` as const][column.code ?? column.field.name];

        const type = getSeriesType(axisItem.type);
        const stacking = getSeriesStacking(axisItem.stackedBar, type);
        const zIndex = 10 - index;

        const dataGrouping = {
          approximation: axisItem.dataApproximation || 'close',
          units: visual.datapointsFrequency || DataPointsFrequency.DAILY
        };
        if (column.dataPointsFrequency) {
          dataGrouping.units = column.dataPointsFrequency;
        }

        const columnHasColorFormatting = !!column.formatting?.color;
        const columnHasDataGrouping = dataGrouping.units !== DataPointsFrequency.DAILY || column.forceDataGrouping;

        const rowDataGrouped = getRowDataGrouped(rowsSorted, dataGrouping, columnCellIndex, columnHasDataGrouping);

        if (deepGroup) {
          const groups = getGroupsFromRows(rowDataGrouped as ExtendedRowGroup[]);

          return groups.map<HistoricChartSeriesOptions>(group => {
            const data = rowDataGrouped
              .filter(row => 'group' in row && row.group === group && typeof row.cells[columnCellIndex] === 'number')
              .flatMap(row => {
                return {
                  x: dayjs(new Date(row.cells[dateColumnIndex] as string)).valueOf(),
                  y: row.cells[columnCellIndex] as number,
                  custom: { column, data: row.data, color: row.color }
                };
              });

            const color = getColorFromRows(data);

            return {
              color,
              stacking,
              type,
              zIndex,
              id: `${entity.name}${group}`,
              name: getLabelsNamesHistoricalSeries(isEntityBenchmark, multipleEntities, group, entity.name, benchmarkNames),
              data,
              custom: {
                colorFormatting: columnHasColorFormatting
              }
            } as HistoricChartSeriesOptions;
          });
        }

        const serieName = getLabelsNamesHistoricalSeries(isEntityBenchmark, multipleEntities, column.headerConfig.displayName as string, entity.name, benchmarkNames);
        if (!serieName.length) {
          console.warn(`${visual.title || visual.component}: One of the serie has no name and has been removed`);
          return [];
        }

        const data = rowDataGrouped
          .filter(row => {
            // we need to check if the cell is a number, because we can have a row with no data
            return typeof row.cells[columnCellIndex] === 'number';
          })
          .map(row => {
            return {
              color: getRowColor(row.data),
              x: dayjs(new Date(row.cells[dateColumnIndex] as string)).valueOf(),
              y: row.cells[columnCellIndex],
              custom: { column, data: row.data }
            };
          });

        return {
          stacking,
          type,
          zIndex,
          custom: {
            colorFormatting: columnHasColorFormatting,
            dataGrouping: columnHasDataGrouping
          },
          yAxis: column.targetAxis!,
          id: column.fieldDataPath,
          name: serieName,
          data
        } as HistoricChartSeriesOptions;
      });
    });

    if (mappedSeries.length > 1 && !isEntityBenchmark && visual.showTopSeries) {
      sortSeries(mappedSeries);
    }

    if (mappedSeries.length > 1 && visual.showTopSeries) {
      mappedSeries = groupSeries(mappedSeries);
    }

    if (visual.historicalConfig && visual.type === 'HISTORICAL') {
      mappedSeries = filterByTimeRange(mappedSeries);
    }

    if (visual.customSort?.length) {
      mappedSeries = customSortSeries(mappedSeries);
    }

    const firstLastPoints = getFirstAndLastPoints(mappedSeries);
    const safeTickRate = getSafeTickRate(visual.tickRate, firstLastPoints);

    // this is used when we have multiple axis and we want to have the same number of ticks on each axis
    let axisNumberOfTicks: number | undefined;

    return {
      stockTools: {
        gui: { enabled: visual.stockTools?.enabled && fullSize }
      },
      plotOptions: {
        line: {
          compare: visual.compareValues ? 'percent' : undefined,
          compareBase: visual.compareBase === '0' ? 0 : 100,
          compareStart: visual.compareValues ? true : undefined
        },
        series: {
          turboThreshold: 0,
          connectNulls: visual.connectGaps,
          marker: {
            enabled: visual.showMarkers ? true : false
          },
          pointRange: pointRange // adjust bars/columns width
        },
        column: {
          dataLabels: {
            enabled: visual.showValueInsideBars === true,
            align: 'center',
            color: 'black',
            allowOverlap: true,
            inside: true,
            formatter: function (this) {
              if (!barColumn) {
                return this.y;
              }

              const compactColumnFormat = deepmerge(barColumn, {
                formatting: { compact: true }
              });
              return HighChartsDataUtils.formatCell(this.y, compactColumnFormat, this.point.options.custom?.data) as string;
            }
          }
        }
      },
      chart: {
        alignTicks: true,
        zoomType: 'x'
      },
      navigator: {
        enabled: fullSize
      },
      legend: {
        align: visual.chartOptions?.legend?.align || 'center',
        verticalAlign: visual.chartOptions?.legend?.verticalAlign || 'bottom',
        enabled: visual.chartOptions?.legend?.enabled,
        symbolRadius: 0
      },
      yAxis: axisIndexedData.map<YAxisOptions>((axis, index) => {
        if (!rows[index]) return {};

        const height = 100 / axisIndexedData.length;
        return {
          opposite: !!index,
          crosshair: true,
          title: {
            text: undefined
          },
          height: visual.stacked ? `${height}%` : undefined,
          top: visual.stacked ? `${index * height}%` : undefined,
          resize: {
            enabled: !!visual.stacked
          },
          labels: {
            formatter() {
              const isStackedPercentValue = mappedSeries[0].stacking === 'percent' && mappedSeries[0].type === 'area';
              const value = isStackedPercentValue ? Number(this.value) / 100 : this.value;
              const compactColumnFormat = deepmerge(axis.columns[0], {
                formatting: { compact: true }
              });
              return HighChartsDataUtils.formatCell(value, compactColumnFormat, rows[index].data) as string;
            }
          },
          min: axis.columns[0].min ?? visual.minValue ?? undefined,
          max: axis.columns[0].max ?? visual.maxValue ?? undefined,
          startOnTick: visual.yAxis?.startOnTick ?? false,
          endOnTick: visual.yAxis?.endOnTick ?? false,
          tickInterval: visual.yAxisInterval,
          tickPositioner: function (this: Highcharts.Axis) {
            const defaultTickPositions = this.tickPositions!;
            if (!visual.alignAxisToCenter) {
              return defaultTickPositions;
            }

            const currentAxisMinTick = defaultTickPositions[0];
            const currentAxisMaxTick = defaultTickPositions[defaultTickPositions.length - 1];

            const center = currentAxisMaxTick >= 100 && currentAxisMinTick <= 100 ? 100 : 0;
            const diffMinToCenter = Math.abs(center - currentAxisMinTick);
            const diffMaxToCenter = Math.abs(center - currentAxisMaxTick);
            const biggestDiffToCenter = Math.max(diffMinToCenter, diffMaxToCenter);

            let numberOfTicks = defaultTickPositions.length;
            if (numberOfTicks % 2 === 0) {
              numberOfTicks += 1;
            }
            if (numberOfTicks < 3) {
              numberOfTicks = 3;
            }
            if (axisNumberOfTicks) {
              numberOfTicks = axisNumberOfTicks;
            } else {
              axisNumberOfTicks = numberOfTicks;
            }

            const ticksToMaxDiff = (numberOfTicks - 1) / 2;
            const tickInterval = biggestDiffToCenter / ticksToMaxDiff;
            const newTicks = [];
            for (let i = 0; i < numberOfTicks; i++) {
              newTicks.push(center + (i - ticksToMaxDiff) * tickInterval);
            }

            return newTicks;
          }
        };
      }),
      xAxis: {
        type: 'datetime',
        crosshair: true,
        tickPositions: buildTicks(safeTickRate, firstLastPoints),
        dateTimeLabelFormats: getDateTimeLabelFormats(visual.dateFormat, safeTickRate),
        labels: {
          rotation: visual.diagonalAxisLabel ? -45 : undefined,
          autoRotation: visual.diagonalAxisLabel ? [-45] : undefined
        },
        endOnTick: visual.xAxis?.endOnTick,
        startOnTick: visual.xAxis?.startOnTick
      },
      tooltip: {
        formatter: visual.compareValues ? undefined : tooltipFormatter
      },
      series: mappedSeries
    };
  };
}

export { HistoricalChartUtils };
