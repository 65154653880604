import { requestApi } from '@/core';
import { AssetNameType } from '@/modules/Onboarding/Shared/types';
import { PaginatedResponse } from '@/modules/ReportCentre/components/DynamicTable/types';

export const getAssetNames = (assetType: string): Promise<PaginatedResponse<AssetNameType[]>> =>
  requestApi({
    method: 'POST',
    service: 'asset',
    url: `/api/v2/assets`,
    data: {
      assetType
    },
    headers: {
      'x-search-with-body': true
    }
  }).then(response => response.data);
