import { requestApi } from '@/core';
import { ApiService } from 'ui-sesame-components';
import { AssetEnumDto } from '../../AssetV2/types';

export const getPmsAssetTypes = async (): Promise<AssetEnumDto[]> => {
  return requestApi({
    url: `/api/pms/assets/asset-types`,
    service: ApiService.ASSET,
    method: 'GET'
  }).then(({ data }) => data);
};
