import { Address, Contact, ContactLookup, ContactType, FlatContact } from '@/modules/ERP/Contacts/types';
import { useHistory } from 'react-router-dom';
import retrievePageTemplate, { PageTemplate } from '@/utils/retrievePageTemplate';
import { tableCompanyContactsSelector, tablePersonContactsSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { findRoute } from '@/modules/ERP/Common/utils';
import type { UUID } from '@/common/types/types';
import type SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';
import { MetadataEntityType } from '@/common/types/entity/Metadata';
import { EntitySearch } from '@/modules/Onboarding/Shared/types';

const contactIdParameterKey = 'id';

export const sortContactsByName = (a: ContactLookup, b: ContactLookup, template: PageTemplate) => {
  if (template === PageTemplate.CONTACTS_LIST_COMPANIES) {
    return a.company!.toLowerCase().localeCompare(b.company!.toLowerCase());
  } else {
    const firstFullName = a.firstName! + a.lastName!;
    const secondFullName = b.firstName! + b.lastName!;

    return firstFullName.toLowerCase().localeCompare(secondFullName.toLowerCase());
  }
};

export const getContactUrlInfo = (contactId: UUID, contactType: ContactType, siteTree: SiteTreeEntry[]) => {
  const searchParams = new URLSearchParams({
    [contactIdParameterKey]: contactId
  });

  const pageTemplate = contactType === ContactType.COMPANY ? PageTemplate.CONTACTS_LIST_COMPANIES : PageTemplate.CONTACTS_LIST;

  const pathname = findRoute(siteTree, pageTemplate).path;

  return { pathname, searchParams };
};

export const appendUrlContactIdParameter = (history: ReturnType<typeof useHistory>, contactId: UUID): void => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(contactIdParameterKey, contactId);

  history.push({ search: searchParams.toString() });
};

export const removeUrlContactIdParameter = (history: ReturnType<typeof useHistory>): void => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete(contactIdParameterKey);

  history.push({ search: searchParams.toString() });
};

export const filterContacts = (contactFilter: string, pageTemplate: PageTemplate) => (contact: ContactLookup) => {
  let string;

  if (pageTemplate === PageTemplate.CONTACTS_LIST_COMPANIES) {
    string = contact.company?.toLowerCase();
  } else {
    string = contact.firstName?.toLowerCase()! + contact.lastName?.toLowerCase()!;
  }

  if (contact.email) {
    string += contact.email.toLowerCase();
  }

  return string?.replace(/ /g, String()).includes(contactFilter.toLowerCase().replace(/ /g, String()));
};

export const flattenContact = (contact: Contact): FlatContact => {
  const { address, ...rest } = contact;

  return {
    ...rest,
    country: address?.country,
    postalCode: address?.postalCode,
    street: address?.street,
    city: address?.city,
    additionalInformation: address?.additionalInformation
  };
};

interface FormContact extends Partial<Contact> {
  photo?: File;
}

export const unflattenContact = (flatContact: Partial<FlatContact>, originalAddress?: Address): FormContact => {
  const { country, street, postalCode, additionalInformation, city, ...rest } = flatContact;

  const address: Address = {};

  if (country) {
    address.country = country;
  }
  if (street) {
    address.street = street;
  }
  if (postalCode) {
    address.postalCode = postalCode;
  }
  if (additionalInformation) {
    address.additionalInformation = additionalInformation;
  }
  if (city) {
    address.city = city;
  }

  const hasUpdatedAddressFields = Reflect.ownKeys(address).length > 0;

  return {
    ...rest,
    ...(hasUpdatedAddressFields ? { address: { ...originalAddress, ...address } } : {})
  };
};

export const retrieveContactRecoilNode = (search: EntitySearch, _template?: PageTemplate) => {
  const template = _template ?? retrievePageTemplate();

  if (template === PageTemplate.CONTACTS_LIST_COMPANIES) {
    return tableCompanyContactsSelector(search);
  }

  return tablePersonContactsSelector(search);
};

export const formatNumberWithDecimals = (value: number, decimalPlaces: number) => {
  const safeDecimalPlaces = Math.max(0, decimalPlaces);

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: safeDecimalPlaces,
    maximumFractionDigits: safeDecimalPlaces
  }).format(value);
};

export const retrieveContactMetadataEntityType = (pageTemplate: PageTemplate) => {
  return pageTemplate === PageTemplate.CONTACTS_LIST_COMPANIES ? MetadataEntityType.CONTACT_COMPANY : MetadataEntityType.CONTACT_PERSON;
};
