import { atom, selector, selectorFamily } from 'recoil';
import { AssetCompositionType, AssetListFilter } from '../types';
import { getAssets } from '../api/getAssets';
import { getAssetCategories } from '../api/getAssetCategories';
import { getAssetById } from '../api/getAssetById';
import { getPrivateIssuers } from '../api/getPrivateIssuers';
import { getPrivateIssuerById } from '../api/getPrivateIssuerById';
import { AssetDtoV2 } from '../../PortfolioV2/types';
import { getAssetCompositionById } from '../api/getAssetCompositionById';
import { getLiquidityFrequencies } from '../api/getLiquidityFrequencies';
import { getAssetCompositionsByCategoryByDate } from '../api/getAssetCompositionsByCategoryByDate';
import { getAssetCompositionsByCurrencyByDate } from '../api/getAssetCompositionsByCurrencyByDate';
import { getAssetCompositionsBySectorByDate } from '../api/getAssetCompositionsBySectorByDate';
import { getAssetCompositionsByRiskCountryByDate } from '../api/getAssetCompositionsByRiskCountryByDate';
import { getLatestAssetCompositionsByCategory } from '../api/getLatestAssetCompositionsByCategory';
import { getLatestAssetCompositionsByCurrency } from '../api/getLatestAssetCompositionsByCurrency';
import { getLatestAssetCompositionsBySector } from '../api/getLatestAssetCompositionsBySector';
import { getLatestAssetCompositionsByRiskCountry } from '../api/getLatestAssetCompositionsByRiskCountry';
import { getAssetPrices } from '../api/getAssetPrices';

export const assetsLastUpdated = atom({
  key: 'assets-last-updated',
  default: new Date()
});

export const assetsList = selectorFamily({
  key: 'assets-list',
  get:
    (params: Readonly<AssetListFilter>) =>
    ({ get }) => {
      get(assetsLastUpdated);
      return getAssets(params);
    }
});

export const assetCategories = selector({
  key: 'asset-categories-selector',
  get: async () => getAssetCategories()
});

export const assetById = selectorFamily({
  key: 'asset-by-id',
  get:
    ({ id, processLogo, options }: { id: NonNullable<AssetDtoV2['id']> } & Partial<Pick<AssetListFilter, 'processLogo'>> & { options?: { errors?: boolean } }) =>
    ({ get }) => {
      get(assetsLastUpdated);
      return getAssetById(id, processLogo, options);
    }
});

export const assetNameSelector = selectorFamily({
  key: 'asset-name-selector',
  get:
    (id: NonNullable<AssetDtoV2['id']>) =>
    ({ get }) => {
      const asset = get(assetById({ id }));
      return {
        id: String(asset.id),
        label: asset.name as string
      };
    }
});

export const assetsNameSelector = selectorFamily({
  key: 'holding-sets-as-label-selector',
  get:
    (filters: Readonly<AssetListFilter>) =>
    ({ get }) => {
      const source = get(assetsList(filters));
      return Array.from(source.content).map(it => ({
        id: String(it.id),
        label: it.name as string
      }));
    }
});

export const conditionalAssetById = selectorFamily({
  key: 'conditional-asset-by-id-v2-selector',
  get:
    ({ id, processLogo }: { id?: number } & Partial<Pick<AssetListFilter, 'processLogo'>>) =>
    async ({ get }) => {
      if (!id) {
        return undefined;
      }
      return get(assetById({ id, processLogo }));
    }
});

export const privateIssuersLastUpdated = atom({
  key: 'private-issuers-last-updated',
  default: new Date()
});

export const privateIssuers = selectorFamily({
  key: 'private-issuers',
  get:
    (params?: Parameters<typeof getPrivateIssuers>[0]) =>
    ({ get }) => {
      get(privateIssuersLastUpdated);
      return getPrivateIssuers(params);
    }
});

export const privateIssuerById = selectorFamily({
  key: 'private-issuers',
  get:
    ({ params, opts }: { params: Parameters<typeof getPrivateIssuerById>[0]; opts?: { errors?: boolean } }) =>
    ({ get }) => {
      get(privateIssuersLastUpdated);
      return getPrivateIssuerById(params, opts);
    }
});

export const conditionalIssuerById = selectorFamily({
  key: 'conditional-private-issuer-by-id',
  get:
    ({ params, opts }: Partial<Parameters<typeof privateIssuerById>[0]>) =>
    ({ get }) => {
      if (!params?.id) {
        return undefined;
      }
      return get(privateIssuerById({ params, opts }));
    }
});

export const lastUpdatedCompositionByDate = atom({ key: 'last-updated-composition-by-date', default: new Date() });

export const conditionalLatestAssetCompositionsById = selectorFamily({
  key: 'conditional-latest-asset-composition-by-id-by-date',
  get:
    ({ id, params, type, enabled = true }: Partial<Parameters<typeof getLatestAssetCompositionsByCategory>[0]> & { type: AssetCompositionType; enabled?: boolean }) =>
    ({ get }) => {
      get(lastUpdatedCompositionByDate);
      if (!id || !enabled) {
        return undefined;
      }
      try {
        switch (type) {
          case 'assetCompositionsByCategory': {
            return getLatestAssetCompositionsByCategory({ id, params });
          }
          case 'assetCompositionsByCurrency': {
            return getLatestAssetCompositionsByCurrency({ id, params });
          }
          case 'assetCompositionsBySector': {
            return getLatestAssetCompositionsBySector({ id, params });
          }
          case 'assetCompositionsByRiskCountry': {
            return getLatestAssetCompositionsByRiskCountry({ id, params });
          }
        }
      } catch {
        return undefined;
      }
    }
});

export const conditionalAssetCompositionsByIdByDate = selectorFamily({
  key: 'conditional-asset-composition-by-id-by-date',
  get:
    ({ id, date, type }: Partial<Parameters<typeof getAssetCompositionsByCategoryByDate>[0]> & { type: AssetCompositionType }) =>
    ({ get }) => {
      get(lastUpdatedCompositionByDate);
      if (!id || !date) {
        return undefined;
      }
      switch (type) {
        case 'assetCompositionsByCategory': {
          return getAssetCompositionsByCategoryByDate({ id, date });
        }
        case 'assetCompositionsByCurrency': {
          return getAssetCompositionsByCurrencyByDate({ id, date });
        }
        case 'assetCompositionsBySector': {
          return getAssetCompositionsBySectorByDate({ id, date });
        }
        case 'assetCompositionsByRiskCountry': {
          return getAssetCompositionsByRiskCountryByDate({ id, date });
        }
      }
    }
});

export const assetCompositionById = selectorFamily({
  key: 'asset-composition-by-id',
  get: (params: Parameters<typeof getAssetCompositionById>[0]) => () => getAssetCompositionById(params)
});

export const liquidityFrequencies = selector({
  key: 'liquidity-frequencies',
  get: getLiquidityFrequencies
});

export const assetPricesLastUpdated = atom({
  key: 'assets-prices-last-updated',
  default: new Date()
});

export const assetPrices = selectorFamily({
  key: 'asset-prices-v2',
  get:
    (params: Parameters<typeof getAssetPrices>[0]) =>
    ({ get }) => {
      get(assetPricesLastUpdated);
      return getAssetPrices(params);
    }
});
