import { EntityParameters, EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { AggregationMethod } from '@/common/types/entity/QuantCatalogueItem';
import { DataFrame, IDataFrame, ISeries } from 'data-forge';
import fastSort from 'fast-sort';
import { parseChartStyles } from '@/modules/Styling/utils/parseChartStyles';
import { nanoid } from 'nanoid';
import { SpreadsheetVisualComponent } from '@/modules/report-builder/views/Sheet/types';
import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import contrastColors from '@/modules/reporting-v2/core/components/Highcharts/contrastColors.json';
import * as configs from '@/modules/reporting-v2/core/visuals/configs';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { Dict } from '@/modules/reporting-v2/types/Common';
import { FieldName } from '@/modules/reporting-v2/types/Field';
import { FieldResolver } from '@/modules/reporting-v2/types/FieldResolver';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { ConfigMappedVisual, RawColumn, RawConfig, RawVisual, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { IVisualDataStructure } from '@/modules/reporting-v2/types/ReportViewerServiceTypes';
import { Row, RowGroup, SortOrder, StylesConfig, VisualConfigProps, VisualSort } from '@/modules/reporting-v2/types/VisualEngine';
import { VisualType } from '@/modules/reporting-v2/types/VisualType';
import { Align, ColumnHeader } from '@/modules/reporting-v2/types/VisualUtils';
import { uniqueValues, uniqueValuesForKey } from '@/modules/reporting-v2/utils/CollectionUtils';
import { concatenateUniqueIndexFields, getColumns, getGroupColumns, getHistoricalConfig, getType, isEmitter, isReceiver } from '@/modules/reporting-v2/utils/IndexUtils';
import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { singleVisualWrapper } from '@/modules/reporting-v2/views';
import { FilterOperator } from '@/types/Filters';
import { ExcelUtils, ExcelVisual } from '@/utils/excel';
import { HTMLHelper } from '@/utils/HTMLHelper';
import { PopupHelper } from '@/utils/PopupHelper';
import { error } from '@/utils/error';
import { Column, customColumnIdentifier, OverrideSummability } from './Column';
import { getChart, mergeChartOptions } from './components/Highcharts';
import { Condition } from './Condition';
import { Field } from './Field';
import { Filter } from './Filter';
import format from './format';
import GroupByColumn from './GroupByColumn';
import { HistoricalConfig } from './HistoricalConfig';
import { IndexNode } from './IndexNode';
import { IContext } from './ReportContext';
import { ColourGroup, ColourGroupType, GroupByColourItem, ReportingService } from './ReportingService';
import { computeReferences, getConditionalColor } from './utils';
import * as VisualComponents from './visuals/components';
import { CashCommitment } from '@/modules/reporting-v2/core/visuals/AllocationTable/types';
import { doCalcExpressions, transformAbsoluteNumbers, transformConditionalMeasures, transformGroupTotalValues } from '@/modules/reporting-v2/core/dataframeTransformation';
import { findColumnWithCode, findConditionalMeasureColumn } from '@/modules/reporting-v2/core/findConditionalMeasureColumn';
import { getRecoilState } from '@/core/RecoilExternalStatePortal';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { UNCLASSIFIED } from '@/const';

type HoldingSetId = number;

export const MULTI_ENTITY_COMPONENTS = [
  VisualComponent.StatsTable,
  VisualComponent.BarChart,
  VisualComponent.TopTab,
  VisualComponent.HistoricalMonthlyTab,
  VisualComponent.CallOut,
  SpreadsheetVisualComponent
];
export const TOTALS_BY_ENTITY_ENABLED_VISUAL = [VisualComponent.StatsTable, VisualComponent.HistoricalMonthlyTab, VisualComponent.CallOut, SpreadsheetVisualComponent];
export const ROWS_BY_ENTITY_ENABLED_VISUAL = [VisualComponent.BarChart, VisualComponent.HistoricalMonthlyTab, SpreadsheetVisualComponent];

const holdingSetFieldRoot = 'holdingset';
const skipNanValues = value => !isNaN(value);

abstract class VisualEngine implements FieldResolver {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    this.id = rawVisual.id;
    this.version = rawVisual.version;
    this.multiEntityFeatures = multiEntityFeatures;

    this.component = rawVisual.component;
    this.multiEntity = MULTI_ENTITY_COMPONENTS.includes(rawVisual.component);

    this.retrieveEntityOrdering(rawVisual, this.multiEntity);

    this.data = {
      rows: [],
      totals: {},
      totalsByEntity: new Map(),
      rowsByEntity: new Map()
    };
    this.updateDataRefId = null;

    const config = this.getVisualMappedConfig(rawVisual.config);
    this.type = getType(rawVisual.component, rawVisual.config?._historical);
    this.setVisualConfig(this.getVisualConfigProps(config));
    this.globalFiltersFields = (rawVisual.globalFilters || [])
      .map(filter => filter.getAllUsedFields())
      .flat()
      .filter(field => field.name !== undefined);
    this.selectBoxFields = (rawVisual.selectBoxFields || []).map(field => new Field(field));
    this.conditionalRowDisplayFields =
      this.columns
        ?.filter(col => col.conditionalRowDisplay.length)
        .map(col => col.conditionalRowDisplay.map(filter => filter.getAllUsedFields()))
        .flat(2) || [];
    this.conditionalValueDisplayFields =
      this.columns
        ?.filter(col => col.conditionalValueDisplay.length)
        .map(col => col.conditionalValueDisplay.map(filter => filter.getAllUsedFields()))
        .flat(2) || [];
    this.conditionalMeasureFields =
      this.columns
        ?.reduce((acc, col) => {
          if (col.conditionalMeasure?.conditions) {
            return [...acc, col.conditionalMeasure.conditions.map(filter => filter.condition.getAllUsedFields())];
          }
          return acc;
        }, [])
        .flat(2) || [];
    this.conditionalColorFields =
      this.columns
        ?.reduce((acc, col) => {
          if (typeof col.formatting?.color === 'object' && col.formatting?.color.conditions) {
            return [...acc, col.formatting.color.conditions.map(filter => filter.condition.getAllUsedFields())];
          }
          return acc;
        }, [])
        .flat(2) || [];
    this.allFieldsUsedInExpressions = this.getAllFieldsUsedInCalcExpressions();

    if (this.displayCategories) this.rearrangeCategories(this.columns);
    this.htmlEditableOnly = false;
    this.improvedLeftJoin = false;

    this.colourGroups = rawVisual.colourGroups;
    this.groupByColoursToApply = this.getGroupByColoursToApply(this.colourGroups);
    this.tableVersion = rawVisual.config?.tableVersion;
  }

  public retrieveEntityOrdering(rawVisual: RawVisual, isMultiEntityComponent: boolean) {
    const currentUser = getRecoilState(currentUserSelector);
    const params = EntityParametersHandler.retrieveParamsList(currentUser, undefined, this.multiEntityFeatures);

    if (rawVisual.config?.entity && rawVisual.config.entity.length) {
      const ordering = rawVisual.config.entity.filter(index => index in params);

      this.entityOrdering = ordering.length ? (isMultiEntityComponent ? ordering : [ordering[0]]) : VisualEngine.getDefaultOrdering(isMultiEntityComponent, params);
    } else {
      this.entityOrdering = VisualEngine.getDefaultOrdering(isMultiEntityComponent, params);
    }
  }

  private static getDefaultOrdering(isMultiEntityComponent: boolean, params: Array<EntityParameters>) {
    if (isMultiEntityComponent && params.length) {
      return params.map((_, index) => index);
    } else {
      return [0];
    }
  }

  getVisualMappedConfig(config: RawConfig): ConfigMappedVisual {
    const defaultConfig = configs[this.component] as RawConfig;
    const configWithDefaults = { ...defaultConfig, ...config };
    this.rawConfig = configWithDefaults;

    return (VisualComponents[this.component] as any)?.configMapper(configWithDefaults, this.multiEntityFeatures);
  }

  getVisualConfigProps(config: ConfigMappedVisual): VisualConfigProps {
    const title = config.title || '';
    const crossFilterReceiver = isReceiver(config._reportContext);
    const crossFilterEmitter = isEmitter(config._reportContext);
    const historicalConfig = getHistoricalConfig(this.type, config.dateRange, config.sampling);
    const visualPositionAlignment = config._align!;
    const hiddenTitle = config._hiddenTitle!;
    const attributesDict = config._attrs;
    const displayCategories = config.categories!;
    const columns = getColumns(config.columns || []);
    const groupByColumns = getGroupColumns(config.group || [], columns);
    const sortable = config.sortable;
    const reportContextFields = config.reportContextFields;
    const improvedLeftJoin = config._improvedLeftJoin;
    const sort = this.getInitialSort(config.sort, columns);
    const hideInPdfIfNoData = config.hideInPdfIfNoData;
    const dateField = config.dateField;

    return {
      crossFilterReceiver,
      crossFilterEmitter,
      groupByColumns,
      historicalConfig,
      visualPositionAlignment,
      hiddenTitle,
      attributesDict,
      displayCategories,
      columns,
      title,
      sortable,
      sort,
      reportContextFields,
      improvedLeftJoin,
      hideInPdfIfNoData,
      dateField
    };
  }

  getInitialSort(visualSort: undefined | VisualSort, columns: Column[]): VisualSort | undefined {
    if (visualSort) {
      return visualSort;
    }

    const sortCustomMeasure = columns.find(col => col.sortCustom?.length);
    if (sortCustomMeasure) {
      let field = sortCustomMeasure.fieldDataPath;
      if (sortCustomMeasure.sortBy) {
        field = ReportingService.quantMetas[sortCustomMeasure.sortBy]?.elasticPath;
      }
      return {
        field: field,
        order: undefined,
        custom: sortCustomMeasure.sortCustom
      };
    }

    const defaultSortColumn = columns.find(col => col.defaultSort || col.sortBy);

    if (defaultSortColumn) {
      const field = defaultSortColumn.sortBy ? ReportingService.quantMetas[defaultSortColumn.sortBy]?.elasticPath : defaultSortColumn.fieldDataPath;

      return {
        field,
        order: defaultSortColumn.defaultSort! ?? SortOrder.ASC
      };
    }

    return undefined;
  }

  setVisualConfig(config: VisualConfigProps): void {
    this.title = config.title;
    this.groupByColumns = config.groupByColumns || [];
    this.columns = config.columns;
    this.crossFilterReceiver = config.crossFilterReceiver || false;
    this.crossFilterEmitter = config.crossFilterEmitter || false;
    this.historicalConfig = config.historicalConfig || HistoricalConfig.disabled();
    this.attributesDict = config.attributesDict;
    this.visualPositionAlignment = config.visualPositionAlignment;
    this.hiddenTitle = config.hiddenTitle || false;
    this.displayCategories = config.displayCategories || false;
    this.sortable = config.sortable;
    this.sort = config.sort;
    this.styles = config.styles;
    this.reportContextFields = config.reportContextFields;
    this.improvedLeftJoin = config.improvedLeftJoin;
    this.hideInPdfIfNoData = config.hideInPdfIfNoData;
    this.dateField = config.dateField;
  }

  dataFilter?: Column[]; // field used to filter selectbox options, need to find a better way of doing this
  measureToFilterOn?: Column[]; // field used to filter selectbox options, need to find a better way of doing this
  id: string;
  version?: string;
  rawConfig: RawConfig;
  title: string;
  component: VisualComponent;
  type: VisualType;
  groupByColumns: GroupByColumn[];
  columns: Column[];
  crossFilterReceiver: boolean;
  crossFilterEmitter: boolean;
  historicalConfig: HistoricalConfig;
  visualPositionAlignment?: Align;
  hiddenTitle: boolean;
  multiEntityFeatures?: boolean;
  attributesDict?: Dict<any>;
  displayCategories: boolean;
  sortable?: boolean;
  sort?: VisualSort;
  total?: boolean;
  limit?: number;
  initialDataframe?: IDataFrame<number, FlattenObject>;
  data: IVisualDataStructure;
  columnsHeaders?: Array<ColumnHeader>;
  globalFiltersFields: Field[];
  conditionalRowDisplayFields: Field[];
  conditionalValueDisplayFields: Field[];
  conditionalMeasureFields: Field[];
  conditionalColorFields: Field[];
  allFieldsUsedInExpressions: Field[];
  selectBoxFields: Field[];
  dataRefId?: string;
  htmlEditableOnly: boolean;
  updateDataRefId: null | string;
  styles?: StylesConfig;
  reportContextFields?: RawColumn;
  multiEntity?: boolean;
  improvedLeftJoin?: boolean;
  hideInPdfIfNoData?: boolean;
  colourGroups?: ColourGroup[];
  groupByColoursToApply: GroupByColourItem[];
  tableVersion?: string;
  dateField?: string;

  /**
   * @description List of 0 indexed numbers that represent which entities this visual is getting data from. (e.g, if 0-it will be the first entity)
   */
  entityOrdering: Array<number>;

  public setOrdering(rank: number, multiEntity?: boolean) {
    if (this.entityOrdering.includes(rank)) {
      this.entityOrdering = this.entityOrdering.filter(order => order !== rank);
    } else {
      if (!multiEntity) {
        this.entityOrdering = [rank];
      } else {
        this.entityOrdering = this.entityOrdering.concat(rank);
      }
    }
  }

  public appendNewEntity(rank: number, multiEntityReport?: boolean) {
    const newOrdering = this.entityOrdering.filter(oldRank => oldRank !== rank);

    if (this.multiEntity && multiEntityReport) {
      this.entityOrdering = newOrdering.concat(rank);
    } else {
      this.entityOrdering = [rank];
    }

    this.rawConfig.entity = this.entityOrdering;
  }

  public reprocessOrdering(params?: Array<EntityParameters>): boolean | undefined {
    if (!params) {
      return;
    }

    const newOrdering = this.entityOrdering.filter(rank => params[rank] !== undefined);

    if (newOrdering.length !== this.entityOrdering.length) {
      console.error(`Entity ordering out of bounds for visual ${this.title}. It will be adjusted automatically.`);

      if (newOrdering.length === 0) {
        newOrdering.push(0);
      }

      this.entityOrdering = newOrdering;
      this.rawConfig.entity = newOrdering;
      return true;
    }
  }

  public getSecondaryIndexNode(): IndexNode | undefined {
    return this.getPrimaryIndexNode(true);
  }

  public getPrimaryIndexNode(secondary = false): IndexNode | undefined {
    if (this.component === VisualComponent.DashboardTable) return new IndexNode(holdingSetFieldRoot);

    const columnsCandidate = this.columns.filter(column => {
      return (
        !column.field.getIndexNode().isRootNode() &&
        (column.field.getIndex() as string) !== PopupHelper.PopupIndex &&
        !datasourceConfigs.get(column.field.getIndex())!.notPrimaryNodeUnlessSingle
      );
    });

    if (!columnsCandidate.length) {
      return secondary ? undefined : this.columns[0]?.field.getIndexNode();
    }

    return columnsCandidate[secondary ? 1 : 0]?.field.getIndexNode();
  }

  public getReferenceIndexNodes(primaryIndexNode = this.getPrimaryIndexNode()): IndexNode[] {
    const uniquePrimaryIndexNodes: IndexNode[] = [];
    const uniqueNodes = uniqueValues(this.getAllUsedFields().map(field => field.getIndexNode().node));

    for (const value of uniqueNodes) {
      const node = value;

      const nodeIsNotPrimary = primaryIndexNode?.node !== node;
      const nodesAreBothLimits = primaryIndexNode?.node.includes('limits.limits') && node.includes('limits.limits'); // prevent issue when mapping limits.limits with limits.limits.operator
      if (nodeIsNotPrimary && !nodesAreBothLimits) {
        uniquePrimaryIndexNodes.push(new IndexNode(node));
      }
    }

    return uniquePrimaryIndexNodes;
  }

  getAllFieldsUsedInCalcExpressions() {
    if (!this.columns) {
      return [];
    }

    const columnsIds = this.columns.map(column => column.id);
    return this.columns.reduce((acc, column) => {
      if (column?.calcExpression) {
        const fields = column.calcExpression.getAllUsedFields().map(field => {
          const columnIdToRemove = columnsIds.find(colId => field.name.endsWith(colId));
          if (columnIdToRemove) {
            return new Field(field.name.split(columnIdToRemove)[0]);
          }
          return field;
        });

        return [...acc, ...fields];
      }
      return acc;
    }, []);
  }

  public getAllUsedFields(): Field[] {
    if (!this.columns?.length) {
      return [];
    }

    if (this.component === VisualComponent.DateSlicer) {
      return this.columns.flatMap(column => column.getAllUsedFields());
    }

    const primaryIndexNode = this.getPrimaryIndexNode();
    const primaryIndex = primaryIndexNode?.getIndex();
    const isMatchedPrimaryIndex = (field: Field) => {
      return primaryIndex && field.getIndex() === primaryIndex;
    };

    const fields = concatenateUniqueIndexFields(
      this.dataFilter ? this.dataFilter.flatMap(column => column.getAllUsedFields()) : [],
      this.measureToFilterOn ? this.measureToFilterOn.flatMap(column => column.getAllUsedFields()) : [],
      this.conditionalRowDisplayFields,
      this.conditionalValueDisplayFields,
      this.selectBoxFields,
      this.globalFiltersFields,
      this.getSortingField(),
      this.conditionalMeasureFields,
      this.conditionalColorFields,
      this.allFieldsUsedInExpressions,
      this.groupByColumns.filter(i => i.isDefault || isMatchedPrimaryIndex(i.field)).flatMap(groupByColumn => groupByColumn.getAllUsedFields()),
      this.columns.filter(i => i.isDefault || isMatchedPrimaryIndex(i.field)).flatMap(column => column.getAllUsedFields())
    ).filter(field => !PopupHelper.IsPopupField(field.name) && !field.name.includes(customColumnIdentifier));

    return fields;
  }

  public getFieldsToRetrieve(): Field[] {
    const allUsedFields = this.getAllUsedFields();
    const primaryIndexNode = this.getPrimaryIndexNode();
    const referenceIndexNodes = this.getReferenceIndexNodes();

    if (!primaryIndexNode) return [];

    const mappingFields = referenceIndexNodes.flatMap<Field, IndexNode>(referenceIndexNode => {
      if (PopupHelper.IsPopupField(referenceIndexNode.node)) return [];

      const primaryIndexMappingFields =
        computeReferences(primaryIndexNode, referenceIndexNode) ??
        error(`Missing reference mapping for primary node ${primaryIndexNode.node} and reference ${referenceIndexNode.node}`);
      const referenceIndexMappingFields =
        computeReferences(referenceIndexNode, primaryIndexNode) ??
        error(`Missing reference mapping for primary node ${referenceIndexNode.node} and reference ${primaryIndexNode.node}`);

      return primaryIndexMappingFields.concat(referenceIndexMappingFields);
    });

    const entityIdField = primaryIndexNode?.getEntityIdField();
    if (entityIdField) {
      mappingFields.push(new Field(entityIdField));
    }

    const otherRequiredFields = primaryIndexNode.getOtherRequiredFields();
    mappingFields.push(...otherRequiredFields);

    return concatenateUniqueIndexFields(allUsedFields, mappingFields);
  }

  /**
   * @summary Dataforge gets its column names from keys of the first row. If data is missing in the first row, it will drop the columns for all the other rows. so we need to have every field in the first row.
   */
  private preserveColumnNames(originalData: Array<FlattenObject>) {
    if (originalData.length !== 0) {
      const existingKeys = Object.keys(originalData[0]);

      this.getAllUsedFields()
        .filter(field => !existingKeys.includes(field.name))
        .forEach(nonExistingField => {
          originalData[0][nonExistingField.name] = null;
        });
    }

    return originalData;
  }

  private getSortingField() {
    // If custom sort is set for visual in the Sort section, all sort rules set for columns in options will be ignored
    if (this.sort?.field) {
      return this.sort.field !== 'date' ? [new Field(this.sort.field)] : [];
    } else {
      const sortByField = ReportingService.quantMetas[this.columns.find(col => col.sortBy)?.sortBy!]?.elasticPath;

      return sortByField ? [new Field(sortByField)] : [];
    }
  }

  private updateDisplayNamesForAllColumns() {
    this.columns.forEach(column => {
      if (!column.headerConfig.measureAsName) {
        return;
      }

      const measureAsNameColumn = findColumnWithCode(column.headerConfig.measureAsName, this.columns);
      if (!measureAsNameColumn) {
        return;
      }

      const dynamicName = this.data.rows[0]?.data[measureAsNameColumn.fieldDataPath];

      const formattedDynamicName = VisualEngine.formatCell(dynamicName, measureAsNameColumn, this.data.rows[0]?.data);
      if (dynamicName) {
        column.headerConfig.displayName = formattedDynamicName as string;
      }
    });
  }

  private getColourGroupMeasures = (type: ColourGroupType): string[] => {
    const measuresByType = {
      ASSET_TYPE: ['asset_info_undated.assets.assetType'],
      ASSET_CATEGORY: ['asset_info_undated.assets.assetCategory'],
      REGION: ['asset_info_undated.assets.issuerRegion', 'asset_info_undated.assets.riskRegionName'],
      SECTOR: [
        'asset_info_undated.assets.gicsLevel1Name',
        'asset_info_undated.assets.gicsLevel2Name',
        'asset_info_undated.assets.gicsLevel3Name',
        'asset_info_undated.assets.gicsLevel4Name'
      ]
    };

    return measuresByType[type] || ['asset_info_undated.assets.assetType'];
  };

  private getGroupByColoursToApply(colourGroups: ColourGroup[] | undefined): Array<GroupByColourItem> {
    if (!colourGroups?.length) {
      return [];
    }

    const result: GroupByColourItem[] = [];
    colourGroups.forEach(colourGroup => {
      const measures = this.getColourGroupMeasures(colourGroup.type);

      measures.forEach(measure => {
        result.push({ measure: measure, colour: colourGroup.colour, value: colourGroup.name });
      });
    });

    return result;
  }

  public generateProcessedData(originalData: Array<FlattenObject>, globalFilters: Array<Filter>, crossFilters: Array<Filter>): void {
    if (!originalData) {
      return;
    }

    this.dataRefId = nanoid(10); // Used for context serialization

    const dataFrame = new DataFrame(this.preserveColumnNames(originalData));
    const originalRows = this.createOriginalRows(dataFrame);

    if (!this.initialDataframe) {
      this.initialDataframe = originalRows;
    }

    const filteredData = this.filterRows(originalRows, globalFilters, crossFilters);

    this.data = this.prepareData(filteredData, this.columns);
    this.updateDisplayNamesForAllColumns();
  }

  private hasCashCommitment(): boolean {
    if (!('cashCommitment' in this) || this.cashCommitment === undefined) {
      return false;
    }
    const cashCommitment = this.cashCommitment as CashCommitment;
    return Boolean(cashCommitment.group && cashCommitment.currency && cashCommitment.remainingCommitment);
  }

  private applyCashCommitment(data: IDataFrame<number, FlattenObject>): IDataFrame<number, FlattenObject> {
    const cashCommitment = (this as any).cashCommitment as CashCommitment;

    const currencyMap = new Map<string, number>();
    const allocationLocalCurrency = new Field(cashCommitment.currency).getElasticPath();
    const remainingCommitment = new Field(cashCommitment.remainingCommitment).getElasticPath();
    const groupFieldPath = new Field(cashCommitment.group!).getElasticPath();

    data.forEach((row, index) => {
      const currency = row[allocationLocalCurrency] as string | undefined;
      const value = row[remainingCommitment] as number | undefined;

      if (value && currency) {
        currencyMap.set(currency, (currencyMap.get(currency) || 0) + value);
      }
    });

    const tempCurrencyMap = new Map();
    // I had to use toArray to avoid dealing with lazy evaluation in dataFrame (it was not working)
    const newData = data.toArray().map((row, index) => {
      const group = row[groupFieldPath] as string | undefined;
      if (group?.toLowerCase() !== 'cash') {
        return row;
      }

      const currency = row[allocationLocalCurrency] as string;

      if (!tempCurrencyMap.has(currency)) {
        tempCurrencyMap.set(currency, true);
        return {
          ...row,
          [remainingCommitment]: -currencyMap.get(currency)!
        };
      }

      return row;
    });

    return new DataFrame(newData);
  }

  private filterRows(originalData: IDataFrame<number, FlattenObject>, globalFilters: Array<Filter>, crossFilters: Array<Filter>): IDataFrame<number, FlattenObject> {
    const filters: Filter[] = [];
    const quantFilters: Filter[] = [];

    for (const col of this.columns) {
      if (col.conditionalRowDisplay.length) {
        filters.push(...col.conditionalRowDisplay);
      }

      if (col.quantFilters?.length) {
        quantFilters.push(...col.quantFilters);
      }
    }

    if (this.crossFilterReceiver) {
      filters.push(...globalFilters, ...crossFilters);
    }

    const noFilters = !filters.length && !quantFilters.length;

    if (noFilters) {
      return originalData;
    }

    filters.push(...this.mergeQuantFilters(quantFilters));

    return originalData.where(row => {
      return filters.every(filter => filter.evaluate(row));
    });
  }

  private mergeQuantFilters(quantFilters: Filter[]): Filter[] {
    if (!quantFilters.length) return [];
    const filterFields = [...new Set(quantFilters.map(filter => filter.field.name))];
    return filterFields.map(field => {
      const sameFieldFilters = quantFilters.filter(filter => filter.field.name === field);
      const values = sameFieldFilters.reduce<string[]>((prev, curr) => prev.concat(...(curr.condition.values as string[])), []);
      return new Filter(new Field(field), new Condition(FilterOperator.EQUALS, values), sameFieldFilters[0].filterId);
    });
  }

  /**
   * @summary Groups together & increase table column span for similar categories in datatable visuals
   */
  public rearrangeCategories(columns: Column[]) {
    const defaultColumns = columns.filter(col => col.isDefault);
    const columnHeaders: any[] = [...new Set(defaultColumns.flatMap(column => column.headerConfig.category))];

    const newColumns = columnHeaders.reduce((accum: Column[], columnHeader: string, index: number) => {
      const headerGroupColumns = defaultColumns.filter(column => {
        return column.headerConfig.category === columnHeader;
      });

      columnHeaders[index] = {
        displayName: columnHeader,
        colSpan: headerGroupColumns.length
      };

      return accum.concat(headerGroupColumns);
    }, []);

    this.columnsHeaders = columnHeaders;
    return newColumns;
  }

  private createOriginalRows(dataFrame: IDataFrame<number, FlattenObject>): IDataFrame<number, FlattenObject> {
    const newSerieGenerator = (column: Column) => (df: IDataFrame<any, any>) => df.getSeries(column.field.getElasticPath());

    const columnsEntries = [];
    for (const value of this.columns) {
      const column = value;

      if (!column.isDuplicateColumn) {
        continue;
      }

      columnsEntries.push([column.fieldDataPath, newSerieGenerator(column)]);
    }
    const object = Object.fromEntries(columnsEntries);

    const dataFrameWithDuplicatedColumns = dataFrame.withSeries(object);
    const dataFrameWithGroupTotalValues = transformGroupTotalValues(dataFrameWithDuplicatedColumns, this.columns);
    const dataFrameWithAbsolute = transformAbsoluteNumbers(dataFrameWithGroupTotalValues, this.columns);
    const dataFrameWithCalc = doCalcExpressions(dataFrameWithAbsolute, this.columns, false);
    const dataFrameWithConditionalMeasure = transformConditionalMeasures(dataFrameWithCalc, this.columns);

    return dataFrameWithConditionalMeasure;
  }

  private normalise(dataFrame: IDataFrame<number, FlattenObject>, normaliseColumns: Column[]) {
    if (!normaliseColumns.length) return dataFrame;

    const selectors: Record<string, (value: number) => number> = {};
    const memoizedSums: Record<string, number | null> = {};

    for (const column of normaliseColumns) {
      selectors[column.fieldDataPath] = (value: number) => {
        const memoizedSum = memoizedSums[column.fieldDataPath];
        const sum = memoizedSum !== undefined ? memoizedSum : dataFrame.getSeries(column.fieldDataPath).sum();
        if (memoizedSum === undefined) {
          memoizedSums[column.fieldDataPath] = sum || null;
        }
        if (!sum) return 0;
        return value / sum;
      };
    }

    return dataFrame.transformSeries(selectors);
  }

  /**
   * This function can be used, when we need to normalize historical values (values grouped by date)
   * For each date (group of values that related to the specific date) it calculates a separate "sum" value
   *  */
  private normaliseHistorical(dataFrame: IDataFrame<number, FlattenObject>, normaliseColumns: Column[], dateField: string) {
    if (!normaliseColumns.length) return dataFrame;

    const dataArray = dataFrame.toArray();
    const isNotHistoricalData = !dataArray[0][dateField];

    if (isNotHistoricalData) {
      console.error(`Error: Can't normalise historical values. The data object is missing date field : ${dateField}.`);
      return dataFrame;
    }

    const selectors: Record<string, (value: number, index: number) => number> = {};
    const memoizedSums: Record<string, number> = {};

    // calculate all sum and store them into memoizedSums object
    for (const column of normaliseColumns) {
      dataArray.forEach(item => {
        const day = item[dateField];
        const memoizedKey = column.fieldDataPath + '.' + day;

        const itemValue = item[column.fieldDataPath] as undefined | null | number;
        const value: number = typeof itemValue === 'number' ? itemValue : 0;

        if (memoizedSums[memoizedKey] === undefined) {
          memoizedSums[memoizedKey] = value;
        } else {
          memoizedSums[memoizedKey] = memoizedSums[memoizedKey] + value;
        }
      });
    }

    // create selector function for every column that uses normalise
    for (const column of normaliseColumns) {
      selectors[column.fieldDataPath] = (value: number, index: number) => {
        const dataItem = dataArray[index];
        const day = dataItem[dateField];
        const sum = memoizedSums[column.fieldDataPath + '.' + day];
        if (!sum) return 0;

        return value / sum;
      };
    }

    return dataFrame.transformSeries(selectors);
  }

  private getColumnByField(name: FieldName, columns: Column[]): Column {
    return columns.find(column => column.fieldDataPath === name) || columns.find(column => column.field.name + column.id === name) || new Column({ field: new Field(name) });
  }

  private getFirstTruthyValue(values: ISeries<number, Primitive>) {
    for (const value of values) {
      if (typeof value !== 'undefined') {
        return value;
      }
    }

    return undefined;
  }

  private getAggregationMethod(metas: ColumnMetas, dataFrame: IDataFrame<number, FlattenObject>) {
    if (!metas?.aggregation?.reference) {
      return metas?.aggregation?.method;
    }

    const aggregField = metas.aggregation.reference;
    const aggreg = dataFrame.first()[aggregField];
    const allFieldSameAggregation = dataFrame.all(row => row[aggregField] === aggreg);

    return allFieldSameAggregation ? (aggreg as AggregationMethod) : undefined;
  }

  private aggregate(column: Column, dataFrame: IDataFrame<number, FlattenObject>): Primitive {
    const values = dataFrame.deflate(item => item[column.fieldDataPath]);

    if (column.showFirstValueSubtotal) {
      return this.getFirstTruthyValue(values);
    }

    if (column.overrideSummability) {
      switch (column.overrideSummability) {
        case OverrideSummability.NONSUMMABLE:
          return undefined;
        case OverrideSummability.SUMMABLE:
          return values.filter(skipNanValues).sum();
        default:
          break;
      }
    }

    if (column.countOnAggregation) {
      return values.count();
    }

    if (column.countDistinctOnAggregation) {
      return values
        .distinct()
        .filter(value => value !== null && value !== undefined && value !== '')
        .count();
    }

    const metas = ReportingService.metas[column.code ?? column.field.name];
    const method = this.getAggregationMethod(metas, dataFrame);
    switch (method) {
      case AggregationMethod.sum: {
        const isHistoricalOrDashboardTable = [VisualComponent.HistoricalChart, VisualComponent.DashboardTable].includes(this.component);
        if (isHistoricalOrDashboardTable) return values.sum() || undefined;

        return values.filter(skipNanValues).sum();
      }
      case AggregationMethod.avg: {
        return values.filter(skipNanValues).average();
      }
      case AggregationMethod.weightedAvg: {
        return (
          dataFrame.aggregate(0, (accum, value) => accum + (value[column.fieldDataPath] as number) * (value[metas?.aggregation?.weights!] as number)) /
          dataFrame
            .deflate(item => item[metas?.aggregation?.weights!])
            .filter(skipNanValues)
            .sum()
        );
      }
      default:
        const isDashboardOrTopTable = [VisualComponent.DashboardTable, VisualComponent.TopTab].includes(this.component);
        if (!isDashboardOrTopTable) {
          column.hideSubtotal = true;
        }
        return this.getFirstTruthyValue(values);
    }
  }
  private applyConditionalDisplayValueFiltersOnGroup(data: FlattenObject, groupByColumnsWithFilter: GroupByColumn[]): void {
    groupByColumnsWithFilter.forEach(groupByColumn => {
      const passFilter = groupByColumn.conditionalValueDisplay?.every(filter => filter.evaluate(data));
      if (!passFilter) {
        data[groupByColumn.fieldDataPath] = undefined;
      }
    });
  }

  private applyConditionalDisplayValueFilters(originalData: IDataFrame<number, FlattenObject>): IDataFrame<number, FlattenObject> {
    const conditionalValueDisplayColumns = this.columns.filter(column => column.conditionalValueDisplay.length || column.quantFilters?.length);

    if (!conditionalValueDisplayColumns.length) {
      return originalData;
    }

    return originalData.map(row => {
      const conditionalValueDisplayData = conditionalValueDisplayColumns.reduce((accum, column) => {
        if (!column.conditionalValueDisplay.every(filter => filter.evaluate(row)) || !column.quantFilters?.every(filter => filter.evaluate(row))) {
          return Object.assign(accum, { [column.fieldDataPath]: undefined });
        }
        return accum;
      }, {});

      return Object.assign(row, conditionalValueDisplayData);
    });
  }

  private getColumnsMaxRangeValue = (rows: Array<Row | RowGroup>): Record<FieldName, number> => {
    return Object.fromEntries(
      this.columns.filter(column => column.dataBars).map(column => [column.fieldDataPath, Math.max(...rows.map(row => Math.abs(row.data[column.fieldDataPath] as number)))])
    );
  };

  private getAllColumns = (originalData: IDataFrame<number, FlattenObject>, _columns: Column[]): Column[] => {
    const mergedColumns = [..._columns, ...originalData.getColumnNames().map(name => this.getColumnByField(name, _columns))];
    return uniqueValuesForKey(mergedColumns, col => col.fieldDataPath);
  };

  private prepareData(originalData: IDataFrame<number, FlattenObject>, _columns: Column[]): IVisualDataStructure {
    let _originalData = this.applyConditionalDisplayValueFilters(originalData);

    if (this.hasCashCommitment()) {
      _originalData = this.applyCashCommitment(_originalData);
    }

    const columns = this.getAllColumns(_originalData, _columns);
    const defaultColumns = this.columns.filter(col => col.isDefault);

    const defaultGroupByColumns = this.groupByColumns.filter(col => col.isDefault);

    const entityIdField = this.getPrimaryIndexNode()?.getEntityIdField()!;

    const conditionalRowDisplayOnGroup = this.groupByColumns
      .filter(col => col.conditionalRowDisplay?.length)
      .map(col => col.conditionalRowDisplay)
      .flat();
    const conditionalValueDisplayOnGroupColumns = this.groupByColumns.filter(col => col.conditionalValueDisplay?.length);

    const deepGroupBy = (groupByColumnsQueue: GroupByColumn[], data: IDataFrame<number, FlattenObject>, holdingSetId?: number): Array<Row | RowGroup> => {
      if (!groupByColumnsQueue.length) {
        return this.getDataWithoutGroupBy(data, defaultColumns, entityIdField, holdingSetId);
      }

      const level = defaultGroupByColumns.length - groupByColumnsQueue.length;
      const calcOnGroupColumns = this.columns.filter(column => column.calcExpression && column.calcOnGroup);
      const conditionalColumnsForGroups = this.columns.filter(column => column.conditionalMeasureForGroups);
      const conditionalColumns = this.columns.filter(column => {
        if (!column.conditionalMeasureOnGroup) return false;
        if (!column.conditionalMeasure?.conditions?.length) return false;
        if (conditionalColumnsForGroups.some(col => col === column)) return false;
        return true;
      });

      const groupData = holdingSetId ? data.where(obj => obj[entityIdField] === holdingSetId) : data;

      const rowGroups: (Row | RowGroup)[] = [];

      const groupByColumn = groupByColumnsQueue[0];
      const groupByColumnPath = groupByColumn.fieldDataPath;

      groupData
        .groupBy(item => {
          const groupValue = item[groupByColumnPath];
          if (groupValue === null || groupValue === undefined) {
            return undefined;
          }
          return groupValue;
        })
        .forEach(dataFrame => {
          const originalObject: FlattenObject = columns.reduce((accum, column) => {
            return {
              ...accum,
              [column.fieldDataPath]: this.aggregate(column, dataFrame)
            };
          }, {});

          for (const column of columns) {
            if (calcOnGroupColumns.some(col => col.id === column.id)) {
              originalObject[column.fieldDataPath] = column.calcExpression!.calc(originalObject, this.columns, true);
            }
            if (conditionalColumns.some(col => col.id === column.id)) {
              const newColumnToUse = findConditionalMeasureColumn(column, originalObject, columns);
              if (newColumnToUse) {
                originalObject[column.fieldDataPath] = originalObject[newColumnToUse.fieldDataPath];
              }
            }
            if (conditionalColumnsForGroups.some(col => col.id === column.id)) {
              const newColumnToUse = findColumnWithCode(column.conditionalMeasureForGroups!, columns);
              if (newColumnToUse) {
                originalObject[column.fieldDataPath] = originalObject[newColumnToUse.fieldDataPath];
              }
            }
          }

          const groupRows = deepGroupBy(groupByColumnsQueue.slice(1), dataFrame, holdingSetId);

          const group = originalObject[groupByColumnPath] || UNCLASSIFIED;
          const color =
            getConditionalColor(groupByColumn, originalObject) ||
            this.groupByColoursToApply.find(groupByColourItem => groupByColourItem.measure === groupByColumnPath && groupByColourItem.value === group)?.colour;

          const passFilters = !conditionalRowDisplayOnGroup.length || conditionalRowDisplayOnGroup.every(filter => filter?.evaluate(originalObject));
          if (passFilters) {
            this.applyConditionalDisplayValueFiltersOnGroup(originalObject, conditionalValueDisplayOnGroupColumns);

            rowGroups.push({
              level,
              color,
              __id__: nanoid(10),
              data: originalObject,
              group,
              rows: groupRows,
              cells: defaultColumns.map(column => originalObject[column.fieldDataPath]),
              maxRangeValues: this.getColumnsMaxRangeValue(groupRows)
            });
          }
        });

      return rowGroups;
    };

    const normaliseColumns = this.columns.filter(column => column.normalise);
    const normalisedData = this.dateField ? this.normaliseHistorical(_originalData, normaliseColumns, this.dateField) : this.normalise(_originalData, normaliseColumns);
    const dataFrameWithCalc = doCalcExpressions(normalisedData, this.columns, true);

    let rows = deepGroupBy(defaultGroupByColumns, dataFrameWithCalc);
    rows = this.sortAndAggregateRows(rows);

    const currentUser = getRecoilState(currentUserSelector);
    const params = EntityParametersHandler.retrieveParamsList(currentUser, undefined, this.multiEntityFeatures);
    const visualHoldingSetIds = this.entityOrdering.map(index => params[index]?.holdingSetId).filter(param => param !== undefined);

    const totals = this.getTotals(rows, columns);
    const rowsByEntity = this.buildRowsPerEntity(visualHoldingSetIds, deepGroupBy, defaultGroupByColumns, normalisedData);
    const totalsByEntity = this.getTotalsByEntity(rows, columns, visualHoldingSetIds);
    const maxRangeValues = this.getColumnsMaxRangeValue(rows);
    return {
      rows,
      totals,
      totalsByEntity,
      rowsByEntity,
      maxRangeValues
    } as IVisualDataStructure;
  }

  private buildRowsPerEntity(
    visualHoldingSetIds: number[],
    deepGroupBy: (groupByColumnsQueue: GroupByColumn[], data: IDataFrame<number, FlattenObject>, holdingSetId?: number) => Array<Row | RowGroup>,
    defaultGroupByColumns: GroupByColumn[],
    normalisedData: IDataFrame<number, FlattenObject>
  ) {
    const rowsByEntity = new Map();

    if (ROWS_BY_ENTITY_ENABLED_VISUAL.includes(this.component)) {
      visualHoldingSetIds.forEach(id => {
        let entityRows = deepGroupBy(defaultGroupByColumns, normalisedData, id);
        entityRows = this.sortAndAggregateRows(entityRows);

        rowsByEntity.set(id, entityRows);
      });
    }

    return rowsByEntity;
  }

  private sortAndAggregateRows(rows: Array<Row | RowGroup>) {
    if (this.sort) {
      this.sortRows(rows);
    }

    if (this.limit) {
      rows = rows.slice(0, this.limit);
    }

    return rows;
  }

  private getVisualFilters(filters: Filter[]) {
    if (this.reportContextFields === undefined || !this.reportContextFields.defaultColumns.length) return filters;

    const reportContextFields = this.reportContextFields;
    return filters.filter(filter => {
      for (const fieldName of reportContextFields.defaultColumns) {
        if (fieldName === filter.field.name) return true;
      }
      return false;
    });
  }

  public updateData(crossFilters: Array<Filter>, globalFilters: Array<Filter>): void {
    if (this.initialDataframe! === undefined) {
      return;
    }

    this.updateDataRefId = nanoid(5);

    const filters = this.getVisualFilters(crossFilters);
    const filteredData = this.filterRows(this.initialDataframe, globalFilters, filters);

    this.data = this.prepareData(filteredData, this.columns);
  }

  public getTotalsByEntity(data: Array<Row | RowGroup>, columns: Column[], holdingSetIds: Array<number>): Map<HoldingSetId, FlattenObject> {
    const enabled = TOTALS_BY_ENTITY_ENABLED_VISUAL.includes(this.component);

    if (enabled) {
      const map = new Map();

      const totalsDataFrame = new DataFrame(data).select(row => row.data);
      const holdingSetIdField = this.getPrimaryIndexNode()!.getEntityIdField()!;

      holdingSetIds.forEach(holdingSetId => {
        const totals = this.getTotals(
          data,
          columns,
          totalsDataFrame.where(object => object[holdingSetIdField] === holdingSetId)
        );

        map.set(holdingSetId, totals);
      });

      return map;
    } else {
      return new Map();
    }
  }

  public getTotals(data: Array<Row | RowGroup>, columns: Column[], dataFrame?: IDataFrame<number, FlattenObject>): FlattenObject {
    if (!this.total) return {};

    const totalsDataFrame = dataFrame ?? new DataFrame(data).select(row => row.data);

    const totals: FlattenObject = columns.reduce((accum, column) => {
      if (column.calcOnGroup && column.calcExpression) return accum;

      return Object.assign(accum, {
        [column.fieldDataPath]: this.aggregate(column, totalsDataFrame)
      });
    }, {});

    const calcOnGroupColumns = this.columns.filter(column => column.calcExpression && column.calcOnGroup);

    const conditionalColumnsForGroups = this.columns.filter(column => column.conditionalMeasureForGroups);
    const conditionalColumns = this.columns.filter(column => {
      if (!column.conditionalMeasureOnGroup) return false;
      if (!column.conditionalMeasure?.conditions?.length) return false;
      if (conditionalColumnsForGroups.some(col => col === column)) return false;
      return true;
    });

    for (const column of this.columns) {
      if (calcOnGroupColumns.some(col => col.id === column.id)) {
        totals[column.fieldDataPath] = column.calcExpression!.calc(totals, columns, true);
      }

      if (conditionalColumns.some(col => col.id === column.id)) {
        const newColumnToUse = findConditionalMeasureColumn(column, totals, this.columns);
        if (newColumnToUse) {
          totals[column.fieldDataPath] = totals[newColumnToUse.fieldDataPath];
        }
      }

      if (conditionalColumnsForGroups.some(col => col.id === column.id)) {
        const newColumnToUse = findColumnWithCode(column.conditionalMeasureForGroups!, this.columns);
        if (newColumnToUse) {
          totals[column.fieldDataPath] = totals[newColumnToUse.fieldDataPath];
        }
      }
    }

    return totals;
  }

  private sortRows(rows: Array<Row | RowGroup>): void {
    const sort = this.sort;
    if (!sort || !sort.field) {
      return;
    }

    if (sort.custom) {
      rows.sort((a, b) => {
        let aIndex = sort.custom!.findIndex(c => c === a.data[sort.field!]);
        if (aIndex < 0) {
          aIndex = 999;
        }

        let bIndex = sort.custom!.findIndex(c => c === b.data[sort.field!]);
        if (bIndex < 0) {
          bIndex = 999;
        }

        return aIndex - bIndex;
      });

      return;
    }

    if (!sort.order) {
      return;
    }

    const fieldPath = new Field(sort.field!).getElasticPath();

    fastSort(rows)[sort.order](row => {
      const isGroup = (row as RowGroup).rows?.length;
      if (isGroup) {
        this.sortRows((row as RowGroup).rows);
      }

      const isMatchingColumn = sort.field === this.columns.find(col => col.isDefault)?.field.name;
      if (isMatchingColumn && isGroup) {
        return (row as RowGroup).group;
      }

      return row.data[fieldPath];
    });
  }

  private getDataWithoutGroupBy(originalData: IDataFrame<number, FlattenObject>, defaultColumns: Column[], entityIdField: string, holdingSetId?: number) {
    let _originalData = holdingSetId ? originalData.where(data => data[entityIdField] === holdingSetId) : originalData;

    if (this.sort && this.sort.field) {
      const sortFieldPath = new Field(this.sort.field).getElasticPath();
      _originalData = (_originalData as any)[this.sort.order === SortOrder.ASC ? 'orderBy' : 'orderByDescending']((item: FlattenObject) => item[sortFieldPath]);
    } else {
      /**
       * Applying limitation in case we have sorting may cause data mismatch
       * @todo: Check, if still need this line, as we apply limitation in sortAndAggregateRows() as well
       */
      if (this.limit) _originalData = _originalData.take(this.limit);
    }

    return _originalData.toArray().map(row => {
      const data = Object.assign(
        row,
        this.columns.reduce((acc, column) => {
          const value = row[column.fieldDataPath];
          return Object.assign(acc, { [column.fieldDataPath]: value });
        }, {})
      );

      return {
        __id__: nanoid(10),
        data,
        cells: defaultColumns.map(column => data[column.fieldDataPath])
      };
    });
  }

  public static formatCell(cell: Primitive, column: Column, data?: FlattenObject, isChart?: boolean, forcedCurrency?: false | string, locale?: string) {
    return format(column, cell, data, undefined, isChart, forcedCurrency, locale);
  }

  public mutateInitialDataframeAfterEdit(data: FlattenObject, value: Primitive, field: string, crossFilters: Filter[], globalFilters: Filter[]) {
    if (!this.initialDataframe) {
      return;
    }

    this.initialDataframe = this.initialDataframe
      .transformSeries({
        [field]: (oldValue, index) => {
          const originalDataObject = this.initialDataframe?.at(index);
          if (JSON.stringify(originalDataObject) === JSON.stringify(data)) {
            return value;
          } else {
            return oldValue;
          }
        }
      })
      .bake();

    this.updateData(crossFilters, globalFilters);
  }

  public mutateGroupByData(data: FlattenObject, value: number, field: string, rowId: string) {
    const defaultColumns = this.columns.filter(col => col.isDefault);
    const fieldIndexInCells = defaultColumns.findIndex(col => col.fieldDataPath === field);
    const column = defaultColumns[fieldIndexInCells];

    const updateRowValue = (row: Row) => {
      const index = row.cells.indexOf(row.data[field]);
      row.data[field] = value;
      row.cells[index] = value;
    };

    const mutateGroupRow = (row: RowGroup) => {
      const columnUseGroupCondition = column.conditionalMeasureForGroups || column.conditionalMeasureOnGroup;
      if (columnUseGroupCondition) {
        return;
      }

      if (typeof row.data[field] === 'number') {
        row.data[field] = row.rows.reduce((acc, r) => {
          return acc + ((r as RowGroup).data[field] as number);
        }, 0);

        row.cells[fieldIndexInCells] = row.data[field];
      }
    };

    const deepFindRow = (rows: RowGroup[] | Row[]): RowGroup | Row | undefined => {
      const row = rows.find((row: RowGroup | Row) => {
        if (row.__id__ === rowId) {
          updateRowValue(row);
          return true;
        }

        const hasNoRows = !('rows' in row);
        if (hasNoRows) {
          return false;
        }

        const findRowInChildren = deepFindRow(row.rows);
        if (findRowInChildren) {
          mutateGroupRow(row);
        }

        return findRowInChildren;
      });

      return row;
    };

    const entityId = data[this.getPrimaryIndexNode()!.getEntityIdField()!] as number;
    const rowsByEntityEnabled = ROWS_BY_ENTITY_ENABLED_VISUAL.includes(this.component);
    const rows = rowsByEntityEnabled ? this.data.rowsByEntity.get(entityId)! : this.data.rows;

    const row = deepFindRow(rows);

    if (!row) {
      throw new Error("The selected row can't be found");
    }

    const columns = this.initialDataframe?.getColumnNames().map(name => this.getColumnByField(name, this.columns)) || this.columns;
    this.data.totals = this.getTotals(this.data.rows, columns);

    const totalsByEntityEnabled = TOTALS_BY_ENTITY_ENABLED_VISUAL.includes(this.component);

    if (totalsByEntityEnabled) {
      const currentUser = getRecoilState(currentUserSelector);
      const params = EntityParametersHandler.retrieveParamsList(currentUser, undefined, this.multiEntityFeatures);
      const visualHoldingSetIds = this.entityOrdering.map(index => params[index].holdingSetId);

      const totalsByEntity = this.getTotalsByEntity(this.data.rows, columns, visualHoldingSetIds);
      this.data.totalsByEntity = totalsByEntity;
    }
  }

  public updateSort(crossFilters: Array<Filter>, globalFilters: Array<Filter>, order?: SortOrder, field?: FieldName, custom?: string[]) {
    this.sort = {
      order,
      field,
      custom
    };

    this.updateData(crossFilters, globalFilters);
  }

  public getDataFrame(rowData: (Row | RowGroup)[]): IDataFrame<number, FlattenObject> {
    const flattenData = (rows: (Row | RowGroup)[]): FlattenObject[] => {
      return rows.flatMap(row => {
        if ('rows' in row) return flattenData(row.rows);
        return [row.data];
      });
    };

    const flattenedData = flattenData(rowData);
    const dataFrame = new DataFrame(this.preserveColumnNames(flattenedData));

    return this.createOriginalRows(dataFrame);
  }

  getHTML = (): string | null => {
    const visualSelector = `#report-visual-${this.id}`;
    const visualWrapper = document.querySelector<HTMLElement>(visualSelector) as HTMLElement;

    const reportStyles = document.querySelector<HTMLStyleElement>('#report-styles');
    const visualStyles = document.querySelector<HTMLStyleElement>(`#report-visual-${this.id}-styles`);

    if (!visualWrapper) {
      return null;
    }

    const ghostWrapper = document.createElement('div');
    ghostWrapper.setAttribute('style', 'position:absolute;top:0;left:0;width:0;height:0;overflow:hidden;z-index:-1;');
    ghostWrapper.innerHTML = singleVisualWrapper(visualWrapper.outerHTML);
    if (reportStyles) {
      ghostWrapper.appendChild(reportStyles);
    }
    if (visualStyles) {
      ghostWrapper.appendChild(visualStyles);
    }

    document.body.appendChild(ghostWrapper);

    const clone = ghostWrapper.querySelector<HTMLElement>(visualSelector)!;

    clone.querySelector('.zoom-in')?.classList.remove('zoom-in');
    clone.querySelector('.visual-interactivity')!.remove();

    const table = clone.querySelector<HTMLElement>('data-table');
    if (table) {
      [...table.querySelectorAll('th')].forEach(cell => {
        cell.style.removeProperty('min-width');
        cell.style.removeProperty('white-space');
      });
      if (!table.classList.contains('collapsible')) {
        ([...clone.querySelectorAll('tbody tr')] as HTMLElement[]).forEach(row => {
          if (row.style.display === 'none') row.remove();
        });
      }
    }

    const chartWrapper = clone.querySelector<HTMLElement>('div[data-highcharts-chart]');

    if (chartWrapper) {
      const chartId = parseInt(chartWrapper.getAttribute('data-highcharts-chart')!);
      const chart = getChart(chartId);

      if (!chartWrapper.offsetHeight) {
        chartWrapper.remove();
      } else {
        let chartStyles = {};
        if (this.component !== VisualComponent.TreeMap) {
          chartStyles = parseChartStyles(this.styles ?? {});
        }
        const svg = document.createElement('div');
        const exportOptions = {
          chart: {
            width: clone.parentElement!.offsetWidth,
            height: clone.parentElement!.offsetHeight
          },
          colors: (contrastColors as Dict<string[]>)[getTheme()],
          plotOptions: { series: { animation: false } }
        };
        svg.innerHTML = chart!.getSVG(mergeChartOptions(exportOptions, chartStyles));
        (svg.firstChild! as HTMLElement).style.display = 'block';
        chartWrapper.replaceWith(svg.firstChild!);
      }
    }

    return ghostWrapper.innerHTML;
  };

  getReportTableForExcel = () => {
    let reportTable;
    switch (this.component) {
      case VisualComponent.AllocationPie:
      case VisualComponent.BarChart:
      case VisualComponent.HistoricalChart: {
        const chartElement = document.querySelector(`#report-visual-${this.id} div[data-highcharts-chart]`);
        if (!chartElement) {
          return null;
        }
        const chart = getChart(Number(chartElement.getAttribute('data-highcharts-chart')));
        if (!chart) {
          return null;
        }

        const table = HTMLHelper.markupToElement<HTMLTableElement>(chart.getTable());

        ExcelUtils.formatAriaTable(table, this.columns, this.data, this.component, this.groupByColumns);

        if (this.component === VisualComponent.HistoricalChart) {
          const [_tbody] = table.tBodies;
          const tbody = _tbody.cloneNode(true) as HTMLTableSectionElement;
          const rows = Array.from(tbody.rows);

          fastSort(rows).asc(tr => {
            return new Date(tr.firstElementChild?.getAttribute('v')?.trim() as string);
          });

          for (let i = 0; i < tbody.rows.length; i++) {
            _tbody.rows[i].innerHTML = rows[i].innerHTML;
          }
        }

        if (this.component === VisualComponent.BarChart) {
          table.querySelectorAll('tr').forEach(row => {
            const cell = row.querySelector('td');
            const cellHead = row.querySelector('th');
            if (cell) {
              const newValue = cell.innerText;
              cell.setAttribute('v', newValue ?? '');
            }
            if (cellHead) {
              const newValue = cellHead.innerText;
              cellHead.setAttribute('v', newValue ?? '');
            }
          });
        }

        reportTable = table;
        break;
      }
      case VisualComponent.LimitsTable:
        reportTable = document.querySelector(`#report-visual-${this.id} table`)?.cloneNode(true) as HTMLTableElement | undefined;
        if (!reportTable) {
          break;
        }
        [...reportTable.querySelectorAll('tbody tr')].forEach(tr => {
          const td = tr.querySelector('td');
          const tdAttribute = td?.getAttribute('v');

          const div = td?.querySelector('div:nth-of-type(2)');
          const validation = (div?.firstElementChild as HTMLSpanElement)?.innerText;

          if (validation) {
            const newValue = tdAttribute + ' - ' + validation;
            td?.setAttribute('v', newValue);
          }
        });
        break;

      case VisualComponent.TextImage: {
        const visualWrapper = document.querySelector<HTMLDivElement>(`#report-visual-${this.id}`);
        if (visualWrapper && !visualWrapper.closest('#cover')) {
          const table = document.createElement('table');
          table.innerHTML = `<tbody><tr><td>${visualWrapper.querySelector('.text-image-content')?.innerHTML}</td></tr></tbody>`;
          reportTable = table;
        }
        break;
      }
      default:
        reportTable = document.querySelector(`#report-visual-${this.id} table`);
        break;
    }

    return reportTable;
  };

  getExcel = (context: IContext): ExcelVisual | null => {
    const reportTable = this.getReportTableForExcel();
    if (!reportTable) {
      return null;
    }

    const table = reportTable.cloneNode(true) as HTMLTableElement;

    [...table.querySelectorAll('tr.group-row:not(.no-children)')].forEach(row => {
      // insert a new empty row before group with children to visually separate groups
      row.parentElement!.insertBefore(document.createElement('tr'), row);
    });

    [...table.querySelectorAll('tr.group-row.no-children')].forEach(row => {
      // insert a new empty row before group without children to visually separate groups
      if (!row.previousElementSibling?.className?.includes('group-row')) {
        row.parentElement!.insertBefore(document.createElement('tr'), row);
      }
    });

    [...table.querySelectorAll('span[contenteditable]')].forEach(node => node.remove());

    if (!table.classList.contains('collapsible')) {
      [...table.querySelectorAll('tr')].forEach(node => {
        if (node?.style?.display === 'none') node.remove();
      });
    }

    return new ExcelVisual(this, table, context);
  };
}

export { VisualEngine };
