import SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';
import { PageTemplate } from '@/utils/retrievePageTemplate';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { siteTreeEntryState, siteTreeState } from '../recoil/app.atoms';

/**
 * Returns the first leaf child of a SiteTreeEntry object.
 * A leaf child is a child node that has no children of its own.
 */
const getFirstLeafChild = (obj?: SiteTreeEntry | null): SiteTreeEntry | null => {
  if (!obj) return null;

  // If there are no children, this is a leaf node, return it
  if (!obj.children || obj.children.length === 0) {
    return obj;
  }

  // Recursively check each child and return the first leaf found
  for (let i = 0; i < obj.children.length; i++) {
    const leaf = getFirstLeafChild(obj.children[i]);
    if (leaf) {
      return leaf;
    }
  }

  // If no leaf found, return null
  return null;
};

/**
 * Hook to retrieve settings-related routes from the site tree.
 *
 * @returns An object with the following properties:
 * - `isSettingsRoute`: A boolean indicating whether the current site entry is a settings route.
 * - `getNotificationsRoute`: A function that returns the notifications route if it exists.
 * - `getInboxSettingsRoute`: A function that returns the inbox settings route if it exists.
 * - `getFirstRoute`: A function that returns the first leaf child route of the current site entry.
 */
export const useSettingsRoute = () => {
  const currentSiteEntry = useRecoilValue(siteTreeEntryState);
  const siteTree = useRecoilValue(siteTreeState);

  const isSettingsRoute = currentSiteEntry?.nodes[0] === 'settings';

  /**
   * Find the settings route in the site tree.
   */
  const settingsRoute = useMemo(() => siteTree?.find(({ nodes, view }) => view === PageTemplate.APP_MENU && nodes[0] === 'settings'), [siteTree]);

  /**
   * Get the user preferences route if it exists.
   */
  const getUserPrefRoute = useCallback(() => settingsRoute?.children.find(r => r.nodes.includes('user-preferences')), [settingsRoute?.children]);

  /**
   * Get the notifications route if it exists.
   */
  const getNotificationsRoute = useCallback(() => getUserPrefRoute()?.children.find(r => r.view === PageTemplate.NOTIFICATIONS), [getUserPrefRoute]);

  /**
   * Get the space settings route if it exists.
   */
  const getSpaceSettingsRoute = useCallback(() => settingsRoute?.children.find(r => r.nodes.includes('space-settings')), [settingsRoute?.children]);

  /**
   * Get the inbox settings route if it exists.
   */
  const getInboxSettingsRoute = useCallback(() => getSpaceSettingsRoute()?.children.find(r => r.view === PageTemplate.INBOX_SETTINGS), [getSpaceSettingsRoute]);

  /**
   * Get the first leaf child route of the current site entry.
   */
  const getFirstSettingsRoute = useCallback(() => getFirstLeafChild(settingsRoute), [settingsRoute]);

  return {
    isSettingsRoute,
    getNotificationsRoute,
    getInboxSettingsRoute,
    getFirstSettingsRoute,
    getUserPrefRoute,
    settingsRoute
  };
};
