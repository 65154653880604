import React, { HTMLAttributes } from 'react';
import { CellWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/components/cell/Cell';
import type { Cell, Row as TableRow } from '@tanstack/react-table';
import type { Row as DataRow, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import type { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import cx from 'classnames';

type RowWrapperProps = {
  row: TableRow<RowGroup | DataRow>;
  onRowClick: undefined | ((rowData: FlattenObject) => void);
  isRowSelected: undefined | ((rowData: FlattenObject) => boolean);
};

export const Row: React.FC<RowWrapperProps> = ({ row, onRowClick, isRowSelected }) => {
  const ogRow = row.original;
  const rowCanExpand = row.getCanExpand();
  const className = cx({ expandable: rowCanExpand, selected: isRowSelected?.(ogRow.data) });
  const isGroupRow = 'group' in ogRow && row.getCanExpand();

  const onClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    e.stopPropagation();
    onRowClick?.(ogRow.data);
  };

  const trProps: Record<string, unknown> = {
    onClick,
    style: { '--groupLevel': row.depth }
  };

  if (isGroupRow) {
    trProps['data-group-level'] = row.depth;
  }

  if (className) {
    trProps.className = className;
  }

  return (
    <tr {...trProps}>
      {row.getVisibleCells().map((cell, index) => {
        return <CellWrapper key={cell.id} cell={cell as Cell<RowGroup | DataRow, Primitive>} index={index} />;
      })}
    </tr>
  );
};
