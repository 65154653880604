import { InputRef, message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { ContentType } from '@/common/types/entity/ContentType';
import { TransientCustomReport } from '@/common/types/entity/CustomReport';
import { requestApi } from '@/core';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { useHistory, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Snapshot, useRecoilSnapshot, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import getComponentTemplate from '@/modules/report-builder/api/getComponentTemplate';
import { RBConfigurationValidator } from '@/modules/report-builder/components/Forms/ConfigurableFormValidator';
import { errorsState, selectedVisualState, snapIndexState, tabState, targetPageState } from '@/modules/report-builder/recoil/atoms';
import { adapter } from '@/modules/report-builder/utils';
import { REPORT_BUILDER_CONFIG_ADAPTER_VERSION } from '@/modules/report-builder/utils/adapter';
import { RawConfig, RawReportConfig, ReportOrientation, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import useSetConfiguration from './useSetConfiguration';
import { useUndoRedo } from './useUndoRedo';
import { reportEntityTypeApi } from '@/api/reportEntityTypeApi';
import useSaveReportConfig from '@/modules/report-builder/hooks/useSaveReportConfig';
import { ReportEntityType } from '@/common/types/entity/ReportEntityType';
import { useIntl } from 'react-intl';
import { RBConfigurationSelector } from '@/modules/report-builder/recoil/selectors';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { SecurityUtils } from '@/utils/SecurityUtils';
import { InternalRoleClasses, internalSecuritySelector } from '@/modules/User/recoil/security.selectors';
import { loggedUserSelector } from '@/common/auth/recoil/user.selector';
import { createCustomReport } from '@/modules/ReportCentre/api/createCustomReport';
import { createTemplate } from '@/modules/ReportCentre/api/createTemplate';
import getCustomComponent from '../api/getCustomComponent';

export const REPORT_BUILDER_CANVAS_SIZE: {
  [key: string]: {
    ratio: number;
    rows: number;
  };
} = {
  [ReportOrientation.PORTRAIT]: { ratio: 1.416141, rows: 17 },
  [ReportOrientation.LANDSCAPE]: { ratio: 0.7063, rows: 11 }
};

type ReleaseableSnapshot = Snapshot & {
  release: () => void;
};

const toString = (configuration: RawReportConfig): string => {
  return JSON.stringify({
    ...configuration,
    version: REPORT_BUILDER_CONFIG_ADAPTER_VERSION
  });
};

const useReportBuilder = () => {
  const titleInputRef = useRef<InputRef>(null);
  const match = useRouteMatch<{
    entityType?: ReportEntityType;
    id?: string;
    action?: string;
  }>();
  const history = useHistory();
  const location = useLocation();
  const { entityType, id } = match.params;
  const currentUser = useRecoilValue(currentUserSelector);
  const initialConfiguration = React.useRef<RawReportConfig | null>(null);
  const [snapshots, setSnapshots] = React.useState<Array<ReleaseableSnapshot>>([]);
  const [snapshotIndex, setSnapshotIndex] = useRecoilState(snapIndexState);
  const snapshot = useRecoilSnapshot();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [reportData, setReportData] = React.useState<
    | {
        title: string;
      }
    | undefined
  >();
  const [configuration, _setConfiguration] = useRecoilState(RBConfigurationSelector);
  const [targetPage, setTargetPage] = useRecoilState(targetPageState);
  const [selected, setSelected] = useRecoilState(selectedVisualState);
  const [errors, setErrors] = useRecoilState(errorsState);
  const setCurrentTab = useSetRecoilState<string>(tabState);
  const canvas = React.useMemo(() => REPORT_BUILDER_CANVAS_SIZE[configuration.config.orientation], [configuration.config.orientation]);
  const isCreatingReportTemplate = React.useMemo(() => location.search.includes('action=create-crm'), [location.search]);
  const isCreatingCustomReport = React.useMemo(() => location.search.includes('type=custom'), [location.search]);
  const isCustomizingCustomReportTemplate = React.useMemo(() => location.search.includes('action=customize'), [location.search]);
  const [previewWindow, setPreviewWindow] = useState<Window | null>();
  const setConfiguration = useSetConfiguration();
  const translator = useIntl();
  const internal = useRecoilValue(internalSecuritySelector);
  const loggedUser = useRecoilValue(loggedUserSelector);

  const preventSnapshotMemoryLeak = React.useCallback(() => {
    if (snapshots.length > 200) {
      setSnapshots(snaps =>
        snaps.filter((snap, idx) => {
          if (idx < 100) {
            snap.release();
            return false;
          } else {
            return true;
          }
        })
      );
    }
  }, [snapshots.length]);

  React.useEffect(() => {
    preventSnapshotMemoryLeak();

    if (snapshots.every(s => s.getID() !== snapshot.getID())) {
      const release = snapshot.retain();
      setSnapshots([...snapshots, Object.assign(snapshot, { release })]);
    }
  }, [snapshot, snapshots, preventSnapshotMemoryLeak]);

  const undo = React.useCallback(async () => {
    const snapIndex = snapshotIndex === null ? snapshots.length - 2 : snapshotIndex - 1; // we should not have to do -2 if snapshotindex is null (it should be -1), we have an issue - to look into (maybe 2 updates instead of 1, unnecessary ?)

    setSnapshotIndex(snapIndex);
    _setConfiguration(await snapshots[snapIndex].getPromise(RBConfigurationSelector));
  }, [snapshots, setSnapshotIndex, _setConfiguration, snapshotIndex]);

  const redo = React.useCallback(async () => {
    if (snapshotIndex === null) {
      return;
    } else {
      const snapIndex = snapshotIndex + 1;
      setSnapshotIndex(snapIndex);
      _setConfiguration(await snapshots[snapIndex].getPromise(RBConfigurationSelector));
    }
  }, [_setConfiguration, setSnapshotIndex, snapshotIndex, snapshots]);

  useUndoRedo(undo, redo);

  React.useEffect(() => {
    const { entityType, id } = match.params;
    if (entityType && id && entityType in reportEntityTypeApi) {
      const { url, serializer, service } = reportEntityTypeApi[entityType];
      requestApi({ method: 'get', url: url(match.params.id!), service })
        .catch(() => undefined) // skip errors
        .then((response: AxiosResponse<any> | undefined) => {
          const { data, configuration } = serializer(response);
          if (!data) {
            message.error(translator.formatMessage({ id: 'generic.anErrorHasOccured' }));
            history.push('/builder');
            return;
          }
          setTimeout(() => {
            setReportData(data);
            if (configuration) {
              const parsedConfig = adapter(JSON.parse(configuration));

              initialConfiguration.current = parsedConfig;
              setConfiguration(parsedConfig);
            }
          }, 100);
        });
    }
  }, [history, match.params, setConfiguration, translator]);

  React.useEffect(() => {
    if (selected) {
      setCurrentTab('visual');
    }
  }, [selected, setCurrentTab]);

  const handleLayoutChange = React.useCallback(
    (layout: ReactGridLayout.Layout[]) => {
      setConfiguration((prevConfiguration: RawReportConfig) => ({
        ...prevConfiguration,
        pages: prevConfiguration.pages.map(page => {
          if (page.id === targetPage) {
            return { ...page, layout };
          }
          return page;
        })
      }));
    },
    [setConfiguration, targetPage]
  );

  const validate = React.useCallback(() => {
    if (!configuration.title?.trim()) {
      message.error(
        translator.formatMessage({
          id: 'report.builder.reportTitleIsRequired'
        })
      );
      return false;
    }

    if (!configuration.pages.length || !configuration.pages.some(page => page.components.length)) {
      message.error(
        translator.formatMessage({
          id: 'report.builder.theReportMustContainAtLeastOnePageAndOneVisual'
        })
      );
      return false;
    }

    const reportErrors = RBConfigurationValidator.validateReportConfiguration(configuration);
    if (Object.keys(reportErrors).length) {
      setErrors(reportErrors);

      for (const errs of Object.values(reportErrors)) {
        for (const err of Object.values(errs)) message.error(err.message);
      }
      return false;
    }

    return true;
  }, [configuration, setErrors, translator]);

  const handleCreateReportTemplate = React.useCallback(async () => {
    const validated = validate();

    if (!validated) {
      return;
    }

    setIsSaving(true);
    try {
      const canCreateTemplate = internal && internal[InternalRoleClasses.InternalClientOrProductOperator];
      const createQuery = !isCreatingCustomReport && canCreateTemplate ? createTemplate : createCustomReport;
      const data = new TransientCustomReport(
        configuration.title!,
        [],
        ContentType.JSON,
        toString(configuration),
        undefined,
        isCreatingCustomReport ? { id: currentUser.company.id, name: currentUser.company.name } : undefined
      );

      const report = await createQuery(data, loggedUser.spaceId);

      message.success(
        translator.formatMessage(
          { id: isCreatingCustomReport ? 'report.builder.successfullyCreatedTheReport' : 'report.builder.successfullyCreatedTheReportTemplate' },
          { title: configuration.title }
        )
      );

      history.push(`/builder/edit/crm/${report.id}${SecurityUtils.isInternalApp() ? '?internal=true' : ''}`);
    } catch (err) {
      if ((err as AxiosError).response?.status === 409) {
        message.error(translator.formatMessage({ id: 'report.aReportWithTheNameAlreadyExists' }, { name: configuration.title }));
      } else {
        message.error(
          translator.formatMessage({
            id: 'report.builder.somethingWentWrongWhenAttemptingToCreate'
          })
        );
      }
    } finally {
      setIsSaving(false);
    }
  }, [validate, internal, configuration, isCreatingCustomReport, currentUser, loggedUser.spaceId, translator, history]);

  const handleDrop = React.useCallback(
    async (_, item, event) => {
      let data;
      let textData = event.dataTransfer.getData('text');

      try {
        data = JSON.parse(textData);
      } catch {
        data = textData;
      }

      let config: RawConfig = {};

      if (data?.id) {
        const getComponent = Boolean(data.accountId) ? getCustomComponent : getComponentTemplate;
        await getComponent(data.id).then(template => {
          textData = template.type;
          config = JSON.parse(template.configuration);
        });
      }

      if (!(textData in VisualComponent)) {
        return;
      }

      const id = nanoid(10);
      setSelected(id);

      setConfiguration((prevConfiguration: RawReportConfig) => ({
        ...prevConfiguration,
        pages: prevConfiguration.pages.map(page => {
          if (page.id === targetPage) {
            return {
              ...page,
              layout: (page.layout || []).concat({ ...item, i: id }),
              components: (page.components || []).concat({
                id,
                component: textData,
                config
              })
            };
          }
          return page;
        })
      }));
    },
    [setConfiguration, setSelected, targetPage, internal]
  );

  const handleDeleteItem = React.useCallback(
    (pageId: string, id: string) => {
      setErrors(errs => {
        const { [id]: visualId, ...rest } = errs;
        return rest;
      });

      if (selected === id) {
        setSelected(undefined);
        setCurrentTab('1');
      }

      setConfiguration((prevConfiguration: RawReportConfig) => ({
        ...prevConfiguration,
        pages: prevConfiguration.pages.map(page => {
          if (page.id === pageId) {
            return {
              ...page,
              layout: page.layout.filter(item => item.i !== id),
              components: page.components.filter(component => component.id !== id)
            };
          }
          return page;
        })
      }));
    },
    [selected, setConfiguration, setCurrentTab, setErrors, setSelected]
  );

  const onBeforePreview = React.useCallback(() => {
    /**
     * Before saving in Local storage, configuration object should be extended with reportId and entityType properties.
     * We will use them later on Preview page.
     * */
    const configurationToSave = entityType && id ? { ...configuration, entityType, reportId: id } : configuration;

    window.localStorage.setItem('preview', toString(configurationToSave));

    if (previewWindow?.document.location && previewWindow.closed === false) {
      previewWindow.document.location.reload();
      previewWindow.focus();
      return;
    }

    setPreviewWindow(window.open(SecurityUtils.getUrlWithInternal('/builder/preview'), '_blank'));
  }, [configuration, entityType, id, previewWindow]);

  const onTitleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setConfiguration((prevConfiguration: RawReportConfig) => ({
        ...prevConfiguration,
        title: e.target.value
      }));
    },
    [setConfiguration]
  );

  const setFocusOnTitle = useCallback(() => titleInputRef.current && titleInputRef.current.focus(), []);

  const { isSaving: savingInProcess, handleSave } = useSaveReportConfig({
    id,
    entityType,
    configuration: { ...configuration, title: configuration.title?.trim() },
    validate,
    setFocusOnTitle
  });

  useEffect(() => {
    setIsSaving(savingInProcess);
  }, [savingInProcess]);

  const isUpdated = initialConfiguration.current && JSON.stringify(initialConfiguration.current) !== JSON.stringify(configuration);

  return {
    match,
    configuration,
    selected,
    canvas,
    reportData,
    isSaving,
    targetPage,
    errors,
    setConfiguration,
    onTitleChange,
    handleDrop,
    handleLayoutChange,
    setTargetPage,
    setSelected,
    handleDeleteItem,
    handleSave,
    handleCreateReportTemplate,
    onBeforePreview,
    titleInputRef,
    isCreatingReportTemplate,
    isCustomizingCustomReportTemplate,
    isUpdated
  };
};

export default useReportBuilder;
