import { requestApi } from '@/core';
import { PaginatedResponse } from '@/modules/ReportCentre/components/DynamicTable/types';
import { AssetListFilter } from '../types';
import { AssetDtoV2 } from '../../PortfolioV2/types';

export const getAssets = (tableParams: AssetListFilter): Promise<PaginatedResponse<AssetDtoV2[]>> => {
  return requestApi({
    url: '/api/v2/assets',
    service: 'asset',
    method: 'POST',
    headers: {
      'x-search-with-body': true
    },
    data: tableParams
  }).then(({ data }) => data);
};
