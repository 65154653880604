export default {
  'report.body.usage': '{name} usage',
  'report.body.customiseTemplate': 'Customise Template',
  'report.body.settings': '{name} Settings',
  'report.body.edit': 'Edit {name}',
  'report.body.schedule': 'Schedule',
  'generic.view': 'View',
  'generic.explore': 'Explore',
  'generic.changesWereSavedAutomatically': 'Changes were saved automatically',
  'generic.changesArePendingToBeSaved': 'Changes are pending to be saved',
  'generic.changesAreBeingSaved': 'Changes are being saved',
  'generic.saving': 'Saving',
  'generic.unclassified': 'Unclassified',
  'generic.classified': 'Classified',
  'generic.pending': 'Pending',
  'generic.error': 'Error',
  'generic.saved': 'Saved',
  'generic.noLicensesAvailable': 'No Licenses are configured. Please contact support@landytech.com',
  'generic.accept': 'Accept',
  'generic.bulkActions': 'Bulk actions',
  'generic.decline': 'Decline',
  'generic.usage': 'usage',
  'generic.clearFilters': 'Clear filters',
  'generic.selectedDocuments': 'selected document{plural}',
  'generic.cookiesMessage': 'We use cookies to ensure that we give you the best experience on our website. You must accept cookies in order to use our application.',
  'generic.cookies': 'Cookies',
  'report.builder.coverPageComponents': 'Cover Page Components',
  'report.builder.componentsLibrary': 'Components Library',
  'report.builder.addComponents': 'Add Components',
  'report.builder.manageComponentsLibrary': 'Manage Components Library',
  'report.builder.errors': 'Errors',
  'report.builder.preview': 'Preview',
  'report.generic.reportName': 'Report Name',
  'report.body.scheduleName': 'Schedule Name',
  'report.body.nextSchedule': 'Next Schedule',
  'report.body.reportType': 'Report Type',
  'report.body.consolidationType': 'Consolidation Type',
  'report.body.entity': 'Entity',
  'report.bulkRuns.createBulkRun': 'Create Bulk Run',
  'report.bulkRuns.bulkRun': 'Bulk Run',
  'report.bulkRuns.createDescription': 'You can find all your bulk run {link}.',
  'report.bulkRuns.searchMyJobs': 'Search My Jobs',
  'report.bulkRuns.filter': 'Filter',
  'report.bulkRuns.all': 'All',
  'report.bulkRuns.id': 'Run ID',
  'report.bulkRuns.name': 'Document Name',
  'report.bulkRuns.entity': 'Entity',
  'report.bulkRuns.date': 'As of date',
  'report.bulkRuns.status': 'Status',
  'report.bulkRuns.message': 'Message',
  'report.bulkRuns.inProgress': 'In progress',
  'report.bulkRuns.ready': 'Ready',
  'report.bulkRuns.merged': 'Merged',
  'report.bulkRuns.error': 'Error',
  'report.bulkRuns.reRunAllFailed': 'Re-run all failed',
  'report.bulkRuns.combineReports': 'Combine reports',
  'report.bulkRuns.downloadAllReports': 'Download all reports',
  'report.bulkRuns.reRun': 'Re-run',
  'report.bulkRuns.downloadReport': 'Download report',
  'report.bulkRuns.viewPdf': 'View PDF',
  'report.bulkRuns.removeDocument': 'Remove document',
  'report.bulkRuns.runBulk': 'Run Bulk',
  'report.bulkRuns.searchById': 'Search by Run ID',
  'report.bulkRuns.successfullyCreated': 'Successfully created run bulk',
  'report.bulkRuns.successfullyCreatedDescription': 'Report is running. You can view the results in {link} under run ID {bulkRunId} when it is complete.',
  'generic.companyName': 'Company Name',
  'generic.frequency': 'Frequency',
  'generic.date': 'Date',
  'generic.name': 'Name',
  'report.body.runDate': 'Run Date',
  'generic.status': 'Status',
  'generic.action': 'Action',
  'generic.company': 'Company',
  'report.body.numberOfReports': 'Number of reports',
  'report.builder.requiredValueForField': 'Required value for field {key}',
  'report.builder.invalidAmountOfMeasuresSelectedInField': 'Invalid amount of measures selected in field {key}.',
  'report.builder.imageMustBeUploadedInTheTextImageVisual': 'An image must be uploaded in the TextImage visual.',
  'report.body.accessibleByEveryCompany': 'Accessible by every company',
  'generic.lookThrough': 'Look Through',
  'generic.direct': 'Direct',
  'report.builder.coverPage': 'Cover Page',
  'report.builder.fontStyle': 'Font Style',
  'report.builder.fontColor': 'Font Color',
  'report.builder.customStyling': 'Custom Styling',
  'report.builder.quantitativeMetricCreation': 'Quantitative Metric Creation',
  'report.builder.quantitativeMetricEdition': 'Quantitative Metric Edition',
  'generic.empty': 'Empty',
  'report.body.endOfMonth': 'End of month',
  'generic.updated': 'Updated',
  'generic.emailSender': 'Email Sender',
  'generic.emailDate': 'Email date',
  'generic.update': 'Update',
  'generic.edit': 'Edit',
  'generic.entity': 'Entity',
  'generic.asset': 'Asset',
  'generic.assetCategory': 'Asset: {category}',
  'generic.size': 'Size',
  'generic.equalTo': 'Equal to {ini}',
  'generic.notBetweenAnd': 'Not between {ini} and {end}',
  'generic.betweenAnd': 'Between {ini} and {end}',
  'generic.lessThanOrEqualTo': 'Less than or equal to {ini}',
  'generic.lessThan': 'Less than {ini}',
  'generic.greaterThanOrEqualTo': 'Greater than or equal to {ini}',
  'generic.greaterThan': 'Greater than {ini}',
  'generic.differentFrom': 'Different from {ini}',
  'generic.uncategorized': 'Uncategorized',
  'generic.sesameReferralLink': 'Sesame Referral Link',
  'generic.invitationInfoMailBody':
    "We're using Sesame by Landytech for our Investment Reporting and thought it would be a useful solution for you. Landytech is a financial technology company offering investment reporting solutions to assets managers, family offices and fiduciaries, leveraging years of experience in developing financial databases, reporting capabilities. risk analytics and software applications.\\n\\nIf you book a call with one of their sales team and sign-up to their platform we'll both get 10% of our annual bill.\\n\\nBook a call with the sales team here: {referralLink}\n    ",
  'generic.yes': 'Yes',
  'generic.no': 'No',
  'generic.exportToExcel': 'Export to Excel (.xlsx)',
  'generic.exportToPdf': 'Export to PDF',
  'generic.exportToSvg': 'Export to SVG',
  'report.builder.desktop': 'Desktop',
  'generic.title': 'Title',
  'generic.visual': 'Visual',
  'report.builder.shadow': 'Shadow',
  'report.builder.enabled': 'Enabled',
  'report.builder.disabled': 'Disabled',
  'report.builder.background': 'Background',
  'report.builder.backgroundColor': 'Background Color',
  'report.builder.border': 'Border',
  'report.builder.borderStyle': 'Border Style',
  'report.builder.borderColor': 'Border Color',
  'report.builder.chart': 'Chart',
  'report.builder.plotShadow': 'Plot Shadow',
  'report.builder.labels': 'Labels',
  'report.builder.legends': 'Legends',
  'report.builder.customCssStyles': 'Custom CSS Styles',
  'generic.riskMetrics': 'Risk metrics',
  'report.builder.actions': 'Actions',
  'generic.compliant': 'Compliant',
  'generic.notApplicable': 'Not Applicable',
  'generic.notCompliant': 'Not Compliant',
  'generic.warning': 'Warning',
  'generic.rate': 'Rate',
  'report.builder.coverPageSettings': 'Cover Page Settings',
  'report.builder.styling': 'Styling',
  'report.builder.componentSettings': 'Component Settings',
  'report.filterForColumnError': 'Filter for column {initialFieldPath} is pointing at fields that have been modified. Please update the configuration',
  'generic.type': 'Type',
  'generic.group': 'Group',
  'report.builder.shortcut': 'Shortcut',
  'generic.allUsers': 'All Users',
  'generic.selectUsers': 'Select Users',
  'report.builder.untitled': 'Untitled',
  'generic.popups': 'Popups',
  'generic.limitsAlerts': 'Limits alerts',
  'generic.documents': 'Documents',
  'generic.dataCategoriesUppercase': 'DATA CATEGORIES',
  'generic.legacyMetricsUppercase': 'LEGACY METRICS',
  'report.builder.untitledReport': 'Untitled Report',
  'report.builder.untitledPage': 'Untitled Page',
  'generic.all': 'All',
  'generic.userLoggedIn': 'User logged in.',
  'generic.sorting': 'Sorting',
  'generic.grouping': 'Grouping',
  'generic.page': 'Page',
  'generic.pages': 'Pages',
  'generic.document': 'Document',
  'generic.limit': 'Limit',
  'generic.searchAndPlaceholder': 'Search: {search}',
  'report.body.createCustomReport': 'Create Custom Report',
  'report.body.createReport': 'Create Report',
  'report.body.createExcelTemplate': 'Create Excel Template',
  'report.body.createTemplate': 'Create Template',
  'generic.category': 'Category',
  'generic.icon': 'Icon',
  'report.builder.Library.missingCodeError': 'Please enter a code for the metric',
  'report.builder.quantLibrary.conflictingCodeError': 'Quant code is already used. This needs to be unique, please use another one.',
  'report.builder.quantLibrary.missingCategoryError': 'Please select a category to proceed.',
  'report.builder.quantLibrary.missingSubcategoryError': 'Please select a sub-category to proceed.',
  'report.builder.quantLibrary.missingElasticPathError': 'Please select an elastic path reference to proceed.',
  'report.builder.quantLibrary.missingDisplayNameError': 'Please select a display name to proceed.',
  'report.builder.quantLibrary.missingFormatError': 'Please select a formatting type to proceed',
  'report.builder.quantLibrary.displayNameRefAndValueError': 'You cannot add both a display name value and display name reference at the same time.',
  'report.builder.quantLibrary.currencyValueAndRefError': 'You cannot add both a currency value and currency reference at the same time.',
  'report.builder.quantLibrary.currencyLengthError': 'The currency value cannot contain more than 3 characters.',
  'report.builder.quantLibrary.filtersReferenceError': 'Please select a reference for the filter.',
  'report.builder.quantLibrary.filtersValueError': 'Please enter a value for the filter.',
  'report.builder.metric': 'Metric',
  'report.builder.fieldPath': 'Field Path',
  'report.builder.migrated': 'Migrated',
  'generic.unassigned': 'Unassigned',
  'notifications.body.limitRuleOperatorMessage': '{ruleDescription} and {operatorValueEnd}',
  'documents.header.byEntity': 'By Entity',
  'documents.header.byAsset': 'By Asset',
  'documents.header.byCustomTag': 'By Custom Tag',
  'documents.header.recycleBin': 'Recycle Bin',
  'documents.header.inbox': 'Documents Inbox',
  'documents.inbox.activation.title': 'Activate your Document Inbox',
  'documents.inbox.settings.general.title': 'Your Inbox',
  'documents.inbox.activation.description':
    'Streamline your workflow by forwarding important emails directly to Sesame. Once activated, Sesame will automatically process these emails, suggesting automations to extract data from either the email body or attachments. You’ll also be able to summarise and interact with your documents using the Sesame AI engine.',
  'documents.inbox.activation.scanningRule.heading': 'AI email scanning rule',
  'documents.inbox.activation.scanningRule.bullet1': 'No Attachments: The AI will analyse the email body.',
  'documents.inbox.activation.scanningRule.bullet2': 'Single Attachment: The AI will scan the attachment, ignoring the email body.',
  'documents.inbox.activation.scanningRule.bullet3':
    'Multiple Attachments: The AI will scan each attachment individually, except for JPEGs, which are typically part of email signatures.',
  'documents.inbox.activation.scanningRule.details': 'In all cases, you will be able to access all email information, including the sender’s name, date, and email body.',
  'documents.inbox.activation.button.copyEmail': 'Copy email address',
  'documents.inbox.activation.button.downloadContact': 'Download contact vCard (.vcf)',
  'documents.inbox.activation.email.prompt': 'We use complex email addresses that are harder to guess, keeping your account safer.',
  'documents.inbox.activation.text.showMore': 'Show more',
  'documents.inbox.activation.text.showLess': 'Show less',
  'documents.inbox.activation.howTo.title': 'How to create a forward rule to my Doc Inbox?',
  'documents.inbox.activation.howTo.desc': 'You can configure rules in Outlook or any other email client to automatically forward specific emails directly to your Document Inbox.',
  'documents.inbox.activation.howTo.button.learnMore': 'Learn more',
  'documents.inbox.activation.button.next': 'Next',
  'documents.inbox.activation.button.back': 'Back',
  'documents.inbox.activation.steps.general.label': 'General',
  'documents.inbox.activation.steps.whitelist.label': 'Whitelist emails',
  'documents.inbox.activation.steps.review.label': 'Review your settings',
  'documents.inbox.activation.whitelist.title': 'Custom Email Access',
  'documents.inbox.activation.whitelist.desc':
    'Any user in this Space is automatically authorised to send documents to the inbox. If needed, you can add additional email addresses, like report@mycompany.com, to allow forwarding from addresses that don’t have direct access to this Space or your external collaborators and partners.',
  'documents.inbox.activation.whitelist.email.title': 'Email address',
  'documents.inbox.activation.whitelist.email.placeholder': 'Insert email addess',
  'documents.inbox.activation.whitelist.button.addMore': 'Add more',
  'documents.inbox.activation.review.desc': 'You can adjust your Document Inbox settings anytime by clicking the button in the toolbar.',
  'documents.inbox.activation.review.button.enable': 'Save and enable Document Inbox',
  'documents.inbox.email.menu.markUpdated': 'Mark as Updated',
  'documents.inbox.email.menu.linkedEmail': 'Linked Email',
  'documents.inbox.email.menu.download': 'Download',
  'documents.inbox.email.menu.editProps': 'Edit Properties',
  'documents.inbox.email.menu.markNew': 'Mark as New',
  'documents.inbox.conv.updated.success': 'Updated sucessfully',
  'documents.inbox.conv.updated.failed': 'Could not update. Please try again',
  'documents.inbox.address.popup.description':
    'You can forward documents to the Sesame Document Inbox using the email address below, and they will automatically be linked to this object.',
  'generic.report': 'Report',
  'report.builder.chooseReference': 'Choose reference',
  'report.builder.chooseWeightsReference': 'Choose weights reference',
  'report.builder.chooseAggregationMethod': 'Choose aggregation method',
  'report.builder.style': 'Style',
  'report.builder.width': 'Width',
  'report.builder.dotted': 'Dotted',
  'report.builder.solid': 'Solid',
  'report.builder.dashed': 'Dashed',
  'report.builder.moveTo': 'Move to',
  'report.builder.goToVisual': 'Go to visual.',
  'report.builder.openWebAndPdf': 'This will open both Web and PDF format of the report',
  'report.builder.applyFormulaOnGroupResults': 'Apply formula on group results:',
  'report.builder.editExpression': 'Edit expression',
  'report.builder.multiple': 'Multiple',
  'report.builder.singular': 'Singular',
  'report.builder.checkAll': 'Check all',
  'report.builder.enterCode': 'Enter code',
  'generic.modified': 'Modified',
  'generic.created': 'Created',
  'report.builder.addColorCondition': 'Add Color Condition',
  'generic.mode': 'Mode',
  'generic.disclaimer': 'Disclaimer',
  'report.body.disclaimerModalContent':
    "Please note that sending this report as an attachment may expose it to external risk. As the attachment is being sent outside of Sesame's secure ecosystem and credentials are not required to open it, legitimate receivers of the report may share it with unauthorised recipients.",
  'report.management.notSelectingAnyCompany': 'Not selecting any company will make this report available to all.',
  'report.body.allCompanies': 'All Companies',
  'generic.searchForCompany': 'Search for company',
  'generic.companies': 'Companies',
  'generic.companyProperties': 'Company Properties',
  'generic.boolean': 'Boolean',
  'generic.contactProperties': 'Contact Properties',
  'report.body.selectCustomReportContentTags': 'Select custom report content tags',
  'generic.propertyName': 'Property name',
  'generic.propertyType': 'Property type',
  'generic.permission': 'Permission',
  'generic.lastEditedDate': 'Last edited date',
  'generic.lastEditedBy': 'Last edited by',
  'generic.spaceLevel': 'Space level',
  'generic.accountLevel': 'Account level',
  'generic.searchForProperty': 'Search property name',
  'generic.urlAddress': 'URL Address',
  'generic.toggle': 'Toggle',
  'generic.multiSelect': 'Multi Select',
  'generic.singleSelect': 'Single Select',
  'report.builder.createTag': 'Create Tag',
  'report.builder.createContentTag': 'Create Content Tag',
  'report.builder.searchForTag': 'Search for tag...',
  'report.builder.contentTagsLibrary': 'Content Tags Library',
  'generic.createTask': 'Create Task',
  'generic.userSelect': 'User Select',
  'generic.task': 'Task',
  'generic.hasBeenCreated': 'has been created',
  'generic.selectDate': 'Select date',
  'report.body.deleteThisAndAllAssociatedFavouritesAndSchedules': 'Delete this {reportType} and all associated favourites and schedules',
  'report.body.deleteThisAndAllAssociatedSchedules': 'Delete this report and all associated schedules',
  'generic.delete': 'Delete {name}',
  'generic.delete.word': 'Delete',
  'report.body.thisWillDeleteTheEntireHistoryOfPastExecutedJobs': 'This will delete the entire history of past executed jobs.',
  'report.body.doYouReallyWantToDeleteThisSchedule': 'Do you really want to delete this schedule?',
  'generic.value': 'value',
  'report.builder.newSubset': 'New subset',
  'report.builder.key': 'key',
  'generic.download': 'Download',
  'report.body': 'View in DMS',
  'report.body.downloadReport': 'Download Report',
  'report.body.downloadReport.noTemplates': 'Some templates were not received: {templates}. They will be changed to default ones in the exported file.',
  'report.builder.quantLibrary.cantDeleteThisMetric': "Can't delete this metric.",
  'generic.deleteComment': 'Delete comment',
  'generic.removeEntity': 'Remove Entity',
  'report.body.selectedEntities': 'Selected Entities',
  'generic.entities': 'Entities',
  'generic.consolidationType': 'Consolidation Type',
  'generic.endDate': 'End Date',
  'generic.selectEntity': 'Select Entity',
  'report.builder.addItem': 'Add item',
  'generic.remove': 'Remove',
  'error.takeMeHome': 'Take me Home',
  'refreshModal.refresh': 'Refresh',
  'generic.here': 'here',
  'generic.pleaseEnterAValidUrlAddress': 'Please enter a valid URL address',
  'report.error.issueWhenLoadingTheReportTryAgainOrContactSupport': 'Issue when loading the report. Try again or contact support with the error description',
  'generic.cancel': 'Cancel',
  'generic.restart': 'Restart',
  'generic.editOrMoveColumns': 'Edit or move columns',
  'generic.rightGroup': 'Right Group',
  'generic.mainGroup': 'Main Group',
  'generic.leftGroup': 'Left Group',
  'generic.left': 'Left',
  'generic.center': 'Center',
  'generic.right': 'Right',
  'generic.enterValue': 'Enter a value',
  'report.builder.referenceItem': 'Reference item',
  'generic.description': 'Description',
  'report.modal.enableNotification': 'Enable notification',
  'generic.notification': 'Notification',
  'generic.notifications': 'Notifications',
  'generic.when': 'When',
  'report.body.chooseReportType': 'Choose Report Type',
  'scheduler.scheduleReport': 'Schedule Report',
  'report.body.requiredField': 'Required field',
  'scheduler.requiredField': 'Required field',
  'generic.save': 'Save',
  'generic.run': 'Run',
  'report.builder.quantLibrary.groupable': 'Groupable',
  'generic.filters': 'Filters',
  'report.builder.quantLibrary.cardinality': 'Cardinality',
  'report.builder.quantLibrary.aggregation': 'Aggregation',
  'generic.currency': 'Currency',
  'report.builder.quantLibrary.enterHint': 'Enter hint',
  'report.builder.quantLibrary.hint': 'Hint',
  'report.builder.quantLibrary.describeTheMetric': 'Describe the metric',
  'report.builder.quantLibrary.enterPeriodEg3Months': 'Enter period (e.g 3 months...)',
  'generic.period': 'Period',
  'generic.displayName': 'Display Name',
  'generic.format': 'Format',
  'report.builder.quantLibrary': 'Elastic Path',
  'generic.enterGroup': 'Enter group',
  'report.builder.quantLibrary.selectSubCategory': 'Select sub category',
  'report.builder.subCategory': 'Sub Category',
  'generic.selectCategory': 'Select category',
  'generic.code': 'Code',
  'report.builder.quantLibrary.chooseDecimalsLimit': 'Choose decimals limit',
  'report.builder.quantLibrary.chooseFormattingType': 'Choose formatting type',
  'report.builder.quantLibrary.chooseIcon': 'Choose icon',
  'generic.enterDisplayName': 'Enter display name',
  'generic.enterParameterName': 'Enter parameter name',
  'report.builder.modal.globalFilters': 'Global Filters',
  'report.builder.configure': 'Configure',
  'onboarding.ownershimap.selectAnEntity': 'Select an entity',
  'generic.search': 'Search',
  'report.builder.configureParameters': 'Configure parameters',
  'report.builder.parameters': 'Parameters :',
  'report.builder.keyWithTheHoldingSetVerifiedDateValue': ' key with the holdingset.verifiedDate value.',
  'report.builder.keyWithAManuallyEnteredValueOf2ForDirectAnd1ForLoo':
    '" key, with a manually entered value of 2 for direct and 1 for lookthrough. Finally, you can override the default date by using the "',
  'report.builder.buildColumnAssociatedHyperlink': 'Build column-associated hyperlink.',
  'report.builder.howeverTheWebpagePathYouWillBeRedirecting':
    "However, the webpage path you will be redirecting to is not dynamic - It is important to keep this in mind if you intend to modify / delete the page you're redirecting to in certain aspects that could break the URL. You will then have to update the URL configuration here.",
  'report.builder.theBuiltLinkAssociatedToThisColumn':
    'The built link associated to this column will utilize selected parameters/value to be built dynamically depending on the row | specified parameters data.',
  'generic.modal.sesameHasBeenUpgradedToTheNewVersionV2':
    'Sesame has been upgraded to the new version V2. Previous version is no longer available to the users. If you have any questions or would want to schedule an additional Sesame V2 demo, please contact your client manager.',
  'generic.modal.sesameUpgrade': 'Sesame Upgrade',
  'generic.reset': 'Reset',
  'task.buttonInTheBottomRightCorner': ' button in the bottom right corner',
  'task.noTasksFoundCreateNewTaskByClickingTheBlue': 'No tasks found. {linebreak} Create new task by clicking the blue ',
  'generic.copied': 'Copied',
  'generic.shareYourUniqueLinkByClickingTheButtonBelow': 'Share your unique link by clicking the button below',
  'generic.itsSimpleIfYouReferSomeoneToSesame': "It's simple: if you refer someone to Sesame who signs up, we'll give you and the person you refer 10% off",
  'generic.referAFriendAndGet10OffYourNextAnnualBill': 'Refer a friend and get 10% off your next annual bill',
  'generic.return': 'Return',
  'generic.modal.referAFriend': 'Refer a friend',
  'generic.total': 'Total',
  'generic.comments': 'Comments',
  'notifications.userPreferences': 'User Preferences',
  'report.tab.excelTemplates': 'Excel Templates',
  'report.tab.scheduledReports': 'Scheduled Reports',
  'report.tab.myCustomReports': 'My Custom Reports',
  'report.tab.myFavorites': 'My Favorites',
  'report.tab.reportCatalogue': 'Report Catalogue',
  'report.modal.selectCompany': 'Select company',
  'report.modal.reportConfiguration': 'Report Configuration',
  'generic.knowledgeBase': 'Knowledge Base',
  'generic.knowledgeBaseUrl': 'Knowledge Base URL',
  'generic.ok': 'OK',
  'report.modal.favorite': 'Favorite',
  'report.modal.thisWillDeleteAllFutureScheduledReports': 'This will delete all future scheduled reports runs and the entire past history of scheduled runs.',
  'report.modal.doYouReallyWantToDeleteThisFavorite': 'Do you really want to delete this favorite?',
  'generic.export': 'Export',
  'report.modal.somethingWentWrongPleaseContactAnAdministrator':
    'Something went wrong, please contact an administrator. In most cases, this means that the configuration of the visual is incorrect.',
  'generic.availableEntities': 'Available Entities',
  'report.body.visual.thisSettingAllowsYouToModifyValuesAcrossThePage':
    'This setting allows you to modify values across the page prior to exporting in PDF/Excel. Data modified will not be persisted.',
  'report.body.visual.activateEditMode': 'Activate edit mode',
  'report.body.visual.deactivateEditMode': 'Deactivate edit mode',
  'generic.noData': 'No data',
  'report.builder.pdfPreview': 'PDF Preview',
  'generic.pdf': 'PDF',
  'report.builder.tab.pasteConfigurationHere': 'Paste configuration here...',
  'generic.or': 'OR',
  'report.builder.sheet.reportTitle': 'Report Title',
  'report.builder.sheet.selectMeasure': 'Select Measure',
  'report.builder.sheet.columns': 'Columns',
  'report.builder.sheet.historical': 'Historical',
  'report.builder.sheet.addEntitySelection': 'Add Entity Selection',
  'report.builder.sheet.openInSheetViewer': 'Open in sheet viewer',
  'generic.addEntity': 'Add Entity',
  'report.builder.quant.addAFilter': 'Add a Filter',
  'generic.logout': 'Logout',
  'generic.header.logoutFrom': 'Logout from {firstName}',
  'tag.generic.allAssociatedAssetsWillBeUnlinkedFromThisTag': '{linebreak} All associated assets will be unlinked from this tag. {linebreak} Please confirm operation.',
  'generic.holdingSet': 'Holding Set',
  'views.generic.allAssociatedAssetsWillBeUnlinkedCategoryallAssociatedAssetsWillBeUnlinkedCategory':
    '{linebreak} All associated assets will be unlinked from this tag category. {linebreak} Please confirm operation.',
  'generic.categoryName': 'Category name',
  'generic.views': 'Views',
  'report.body.configureEntities': 'Configure Entities',
  'generic.send': 'Send',
  'generic.message': 'Message',
  'generic.subject': 'Subject',
  'generic.to': 'To',
  'generic.compose': 'Compose',
  'generic.messages': 'Messages',
  'ownership.marketValue': '<s> Market Value </s> {marketValue}',
  'ownership.dateVerified': '<s> Date </s> {verifiedDate}',
  'generic.ownership': 'Ownership',
  'generic.upload': 'Upload',
  'report.table.template.no.usages': 'This template has no usages',
  'report.dashboardTable.ownershipTree': 'Ownership tree',
  'generic.month': 'Month',
  'generic.year': 'Year',
  'report.builder.typeSomeCssCode': 'Type some css code...',
  'refreshModal.backToSaveChanges': 'Back to save changes',
  'refreshModal.toAvoidLosingYourChangesPleaseSaveBeforeRefreshing': 'To avoid losing your changes, please save before refreshing.',
  'refreshModal.weHaveUpdatedSesameForAMoreSeamlessExperience': 'We have updated Sesame for a more seamless experience.',
  'refreshModal.newSesameVersion': 'New Sesame Version',
  'report.builder.saveAsTemplate': 'Save as template',
  'report.body.clickToNavigateToKnowledgeBaseForMoreInformation': 'Click to navigate to knowledge base for more information',
  'report.builder.layers': 'Layers',
  'generic.addNew': 'Add new',
  'report.scheduleModal.dayOfMonth': '{formatDay} day of month',
  'report.builder.addNewComponent': 'Add new component',
  'report.builder.searchComponents': 'Search components',
  'report.table.selectACategory': 'Select a category',
  'generic.filterBy': 'Filter by',
  'report.builder.visual.conditionalAggregation': 'Conditional Aggregation',
  'generic.youCurrentlyDoNotHaveAccessToAnyEntity': 'Unfortunately, you currently do not have access to any entity. Please contact an administrator for more information.',
  'generic.searchPages': 'Search pages',
  'report.builder.quantLibrary.noMetricSelectedYet': 'No metric selected yet.',
  'report.builder.quantLibrary.searchForField': 'Search for field...',
  'report.builder.quantLibrary.showOrphans': 'Show orphans',
  'report.builder.quantLibrary.orphansAreQuantItemsThatAreMappedMessage':
    'Orphans are quant items that are mapped to elastic fields that have been renamed/removed. They are defective and need to be updated.',
  'generic.clear': 'Clear',
  'generic.apply': 'Apply',
  'generic.quantLibrary': 'Quant Library',
  'report.builder.quantLibrary.typeToSearchAMeasure': 'Type to search a measure...',
  'generic.measures': 'Measures',
  'generic.searchFor': 'Search for {object}',
  'generic.measuresManagement': 'Measures Management',
  'generic.categoriesManagement': 'Categories Management',
  'report.builder.quantLibrary.typeOfConfigurations': 'Type of configurations: {class};',
  'report.builder.quantLibrary.thisMetricIsUsedAtThesePlaces': 'This metric is used at these places:',
  'report.builder.quantLibrary.referenceQuantItem': 'Reference quant item',
  'report.builder.quantLibrary.enterAValueManually': 'Enter a value manually',
  'report.modal.sendDocumentInAttachment': 'Send document in attachment',
  'generic.requiredFields': 'Required fields.',
  'report.modal.rerunJob': 'Rerun job',
  'report.modal.createdOnBehalfOf': 'Created on behalf of',
  'report.modal.customReportName': 'Custom Report Name',
  'generic.lastModifiedBy': 'Last Modified By',
  'generic.lastModified': 'Last Modified',
  'generic.dateCreated': 'Date Created',
  'generic.properties': 'Properties',
  'generic.createdBy': 'Created by',
  'report.noOfAttempts': 'No. of attempts: &nbsp;{attempts}',
  'generic.general': 'General',
  'generic.portfolios': 'Portfolios',
  'report.builder.selectAMeasure': 'Select a measure',
  y: 'y:',
  x: 'x:',
  'generic.sesameByLandytech': 'Sesame by Landytech',
  'generic.sesame': 'Sesame',
  sS: '<s> {expr} </s> {children}',
  'tasks.groupItemsWhileSorting': 'Group items while sorting',
  'generic.showHelp': 'Show help',
  'scheduler.runDate': 'Run Date',
  'generic.updateTag': 'Update Tag',
  'generic.pleaseConfirm': 'Please confirm',
  'generic.addNewTag': 'Add New Tag',
  'generic.filter': 'Filter',
  'dms.updateTagValue': 'Update Tag Value',
  'dms.generic.couldNotDownloadFiles': 'An error occured when attempting to download the files. Please try again later or contact an administrator.',
  'generic.addValue': 'Add value',
  'tms.assignee': 'Assignee',
  'dms.table.buttonOnTheRightSideOfTheFileName': ' button on the right side of the file name.',
  'dms.table.noTasksInOpenStatusForThisFileCreateANewTaskByClic': 'No tasks in Open status for this file. Create a new task by clicking the ',
  'dms.numberOfTasksInOpenStatusForThisFile': 'Number of tasks in Open status for this file.',
  'report.modal.scheduleName': 'Schedule name',
  'report.saveReport': 'Save Report',
  'tag.generic.deleteTag': 'Delete tag',
  'report.modal.withValidation': 'With Validation',
  'report.modal.withoutValidation': 'Without Validation',
  'report.builder.keyWithTheHoldingSetIdColumnValueYouCanOverride': ' key, with the holdingSetId column value. You can override the consolidation type by using the ',
  'views.generic.deleteTagCategory': 'Delete tag category',
  'report.builder.addColor': 'Add color',
  'report.builder.thisReportCannotBePreviewedDueToMisconfigurations': 'This report cannot be previewed due to misconfigurations. Please contact a reports manager.',
  'report.builder.parametersRepresentAKeyValuePairBarGivenAnUrl': 'Parameters represent a key/value pair in the URL bar. Given an URL:',
  'report.builder.toLinkToSingleReport': 'Single reports : To link to single reports, you will only need to use the "',
  'report.builder.holdingSetIdRepresentsTheKeyLeftInputHere':
    'holdingSetId represents the key (left input here), and 234 represents the value (right input here, the column selector). A couple of pages have specific behaviors, known ones at the moment are :',
  'report.builder.documentsToLinkToTheByEntityPageUseTheHsKey':
    'Documents : To link to the "By Entity" page, use the "hs" key, and holdingSetId value. To link to the "By Asset" page, use the "a" key, and assetId value.',
  'report.builder.canvas': 'Canvas',
  'generic.search.browseMeasures': 'Browse Measures',
  'generic.holdingSetIsRequired': 'Holding Set is required',
  'generic.referral.copyLink': 'Copy Link',
  'report.modal.somethingWentWrongWhenAttemptingToDeleteReport': 'Something went wrong when attempting to delete report "{name}".',
  'report.modal.successfullyDeletedReport': 'Successfully deleted report "{name}".',
  'report.modal.deleteThisAndCreateCustomReportFavoritesAndSchedules': 'Delete this {reportType} and create custom reports for the associated favorites and schedules',
  'report.modal.deleteThisAndCreateCustomReportSchedules': 'Delete this report and create custom reports for the associated schedules',
  'report.modal.reportNameCannotBeEmpty': 'Report name cannot be empty.',
  'report.modal.theConfigurationNeedsToBeInValidJsonFormat': 'The configuration needs to be in valid JSON format.',
  'report.aReportWithTheNameAlreadyExists': 'A report with the name "{name}" already exists.',
  'report.somethingWentWrongWhenAttemptingToUpdateConfig': 'Something went wrong when attempting to update the configuration for the report "{name}."',
  'report.successfullyUpdatedTheConfigurationOfTheReport': 'Successfully updated the configuration of the report {name}. ',
  'report.permittedCompanies': 'Permitted Companies',
  'report.theReportWasSuccessfullyRemovedFromFavorites': 'The report "{report.customReport.name}" was successfully removed from favorites.',
  'report.modal.somethingWentWrongWhenTryingToRemoveTheReport': 'Something went wrong when trying to remove the report "{name}" from favorites.',
  'report.modal.pleaseFillInEveryField': 'Please fill in every field.',
  'report.aFavoriteForTheReportAlreadyExists': 'A favorite for the report "{name}" already exists.',
  'report.somethingWentWrongWhenAttemptingToAddTheReport': 'Something went wrong when attempting to add the report "{name}" to favorites. Please contact an administrator.',
  'report.successfullyAddedTheReportToFav': 'Successfully added the report "{name}" to favorites.',
  'report.modal.setFavorite': 'Set favorite',
  'report.modal.successfullyDeletedSchedule': 'Successfully deleted schedule',
  'report.modal.somethingWentWrongWhenAttemptingToDeleteSchedule': 'Something went wrong when attempting to delete this schedule.',
  'report.modal.successfullyCreatedSchedule': 'Successfully created schedule {name}.',
  'report.modal.somethingWentWrongWhenScheduleReport': 'Something went wrong when attempting to schedule the report {name}. Please contact a content manager.',
  'report.modal.successfullyUpdatedSchedule': 'Successfully updated schedule {name}',
  'report.modal.somethingWentWrongWhenUpdatingTheSchedule': 'Something went wrong when updating the schedule {name}. Please contact a content manager.',
  'generic.tasks': 'Tasks',
  'report.modal.searchForUsers': 'Search for users',
  'report.modal.frequencyDay': 'Frequency day',
  'report.modal.sendLinkToDocument': 'Send link to document',
  'report.modal.recipients': 'Recipients',
  'report.builder.reportSettings': 'Report Settings',
  'report.modal.successfullyReranJob': 'Successfully reran job',
  'report.modal.somethingWentWrongWhenAttemptingToRerunTheJob': 'Something went wrong when attempting to rerun the job',
  'generic.selectFile': 'Click or drag file to this area to upload',
  'generic.currentPositionIsRequired': 'Current position is required',
  'generic.whatIfVersion': 'Report Version',
  'generic.whatIfVersionIsRequired': 'Report Version is required',
  'generic.whatIfVersionPlaceholder': 'Select Report Version',
  'generic.by': 'by',
  'generic.on': 'on',
  'generic.On': 'On',
  'generic.Off': 'Off',
  'generic.rule': 'Rule',
  'generic.currentValue': 'Current Value',
  'generic.row': 'Row',
  'report.builder.pleaseEnterANameForTheContentTag': 'Please enter a name for the content tag.',
  'report.builder.pleaseEnterACategoryForTheContentTag': 'Please enter a category for the content tag.',
  'report.builder.somethingWentWrongWhenAttemptingToCreateTheTag': 'Something went wrong when attempting to create the tag.',
  'report.builder.successfullyCreatedTheContentTag': "Successfully created the content tag '{name}'.",
  'report.builder.successfullyDeletedTheContentTag': 'Successfully deleted the content tag.',
  'report.builder.someItemsAreStillAssociatedToThisTagReportsMeasure':
    'Some items are still associated to this tag (reports, measures...). Please disassociate them prior to deleting the tag.',
  'report.builder.somethingWentWrongWhenAttemptingToUpdateTheTag': 'Something went wrong when attempting to update the tag.',
  'report.builder.successfullyUpdatedTheContentTagName': 'Successfully updated the content tag name.',
  'report.builder.saveTagName': 'Save tag name',
  'report.builder.successfullyUpdatedTheContentTagCategory': 'Successfully updated the content tag category.',
  'report.builder.successfullyUpdatedTheContentTagIcon': 'Successfully updated the content tag icon.',
  'generic.options': 'Options',
  'report.builder.noDescriptionAvailable': 'No description available.',
  'report.builder.addSeries': 'Add Series',
  'report.builder.wrongConfiguration': 'Wrong configuration',
  'generic.successfullyCreated': 'Successfully created',
  'report.builder.noVisualDetected': 'No visual selected',
  'report.builder.conditionalDisplay': 'Conditional Display',
  'report.builder.editPageTitle': 'Edit page title',
  'report.builder.deletePage': 'Delete page',
  'report.builder.addNewPage': 'Add new page',
  'report.builder.enterPageName': 'Enter page name',
  'report.builder.successfullyCreatedTheMetric': 'Successfully created the metric.',
  'report.builder.somethingWentWrongWhenTryingToCreateTheMetric': 'Something went wrong when trying to create the metric.',
  'report.builder.successfullyUpdatedTheMetric': 'Successfully updated the metric.',
  'report.builder.somethingWentWrongWhenTryingToUpdateTheMetric': 'Something went wrong when trying to update the metric.',
  'report.builder.thisQuantItemIsUsedAsAReferenceInThese': 'This quant item is used as a reference in these quant items: {join}.\\nPlease update them and try again.',
  'report.builder.quantLibrary.successfullyUnmappedTheMetric': 'Successfully unmapped the metric.',
  'report.builder.quantLibrary.somethingWentWrongWhenAttemptingToUnmapTheMetric': 'Something went wrong when attempting to unmap the metric',
  'generic.successfullySaved': 'Successfully saved',
  'report.builder.saveToComponentLibrary': 'Save to component library',
  'report.builder.moveToAnotherPage': 'Move to another page',
  'report.builder.haveYouPreviewedTheReport': 'Have you previewed the report and happy with sizing and content?',
  'report.builder.thisTemplateWillBeAdded': 'This template will be added to My Custom Reports.',
  'report.builder.thisWillOpenBothWebAndPdfFormatOfTheReport': 'This will open both web and PDF format of the report.',
  'report.builder.saveACustomReport': 'Save a custom report',
  'report.builder.theReportMustContainAtLeastOnePageAndOneVisual': 'The report must contain at least one page and one visual.',
  'report.builder.cantUpdateTheLocalVersionOfThisConfiguration': "Can't update the local version of this configuration.",
  'generic.default': 'Default',
  'report.builder.fromInception': 'From inception',
  'generic.xYears': '{number} years',
  'report.builder.lastNumberMonths': 'Last {number} months',
  'generic.lastMonth': 'Last month',
  'generic.currentMonth': 'Current month',
  'generic.tableType': 'Table type',
  'generic.dateRange': 'Date range',
  'report.builder.dataSelection': 'Data selection',
  'generic.groupBy': 'Group by',
  'report.builder.dataOptions': 'Data options',
  'generic.showChildren': 'Show children',
  'generic.subTotal': 'Sub total',
  'generic.sortBy': 'Sort by',
  'generic.sortingOrder': 'Sorting Order',
  'report.builder.limitRecords': 'Limit Records',
  'generic.top': 'Top',
  'generic.bottom': 'Bottom',
  'generic.monthly': 'Monthly',
  'generic.quarterly': 'Quarterly',
  'generic.pleaseSelectAnEntity': 'Please select an entity',
  'report.builder.entitiesCanBeAddedThroughEntitiesSelection': 'Entities can be added through entities selection and maximum of 5 entities can be added in the report',
  'report.builder.selectDefaultOrHistorical': 'Select default or historical according to the requirement of the report',
  'report.builder.enableHistoricalForPastDataRecords': 'Enable historical for past data records',
  'report.builder.selectFrequencyAccordingToDataRange': 'Select the frequency according to the data range required in the report',
  'report.builder.enableToSeeChildrenAccordingToDataRange': 'Enable to see children of each group from the table',
  'report.builder.selectTheNumberOfRowsToBeShownInTheTable': 'Select the number of rows to be shown in the table',
  'report.builder.reportTitleIsRequired': 'Report title is required.',
  'report.builder.anExcelTemplateAlreadyExistsWithSameTitle': 'An Excel template already exists with same title.',
  'generic.anErrorOccurredPleaseTryAgain': 'An error occurred, please try again.',
  'generic.pleaseSelectAStartDate': 'Please select a start date.',
  'generic.successfullyScheduled': 'Successfully scheduled.',
  'report.modal.somethingWentWrongWhenScheduling': 'Something went wrong when scheduling. Please contact a contact manager.',
  'generic.somethingWentWrongWhenAttemptingToDownload': 'Something went wrong when attempting to download the document {documentName}. Please contact an administrator.',
  'report.visual.youCannotDeselectTheLastEntity': 'You cannot deselect the last entity',
  'generic.none': 'None',
  'generic.family': 'Family',
  'generic.font.arial': 'Arial',
  'generic.font.serif': 'Serif',
  'generic.font.sansSerif': 'Sans Serif',
  'generic.font.normal': 'Normal',
  'generic.font.bold': 'Bold',
  'generic.font.italic': 'Italic',
  'report.builder.resetExpression': 'Reset expression',
  'report.builder.aCustomReportAlreadyExistsWithThisName': 'A custom report already exists with this name.',
  'report.builder.cannotReadCurrentReportConfiguration': 'Cannot read current report configuration',
  'generic.anErrorHasOccured': 'An error has occured',
  'report.builder.successfullyCreatedTheReportTemplate': 'Successfully created the report template {title}',
  'report.builder.somethingWentWrongWhenAttemptingToCreate': 'Something went wrong when attempting to create the custom report template.',
  'generic.anErrorHasOccurredPleaseTryAgain': 'An error has occurred, please try again.',
  'generic.errorUploadingImage': 'Error uploading image. The file size may be too large.',
  'dms.documentDoesNotExist': 'Document does not exist',
  'dms.generic.unexpectedStateTheFileCouldNotBeFound': "Unexpected state. The file '{name}' could not be found in the company storage. Please contact support.",
  'dms.generic.couldNotDownloadTheFileReason': 'Could not download the file, reason: {error}',
  'dms.fileUploadFailedDetails': '{name} file upload failed, details: {details}',
  'dms.fileUploadAborted': '{name} file upload aborted',
  'generic.documentHasBeenUploaded': 'Document {name} has been uploaded',
  'report.modal.youMustSelectAValidationTypeToProceedUponDate': 'You must select a validation type to proceed (Upon date validation/Close of business).',
  'report.modal.youMustSelectADayFrequencyToProceed': 'You must select a day frequency to proceed.',
  'report.modal.youMustSelectAFrequencyToProceed': 'You must select a frequency to proceed.',
  'report.modal.youMustSelectAFileTypeToProceed': 'You must select a file type to proceed.',
  'report.visual.missingDataForVisualWithId': 'Missing data for visual with id {id}',
  'generic.error.thePageYouVisitedDoesNotExist': 'Sorry, the page you visited does not exist.',
  'generic.successfullySent': 'Successfully sent',
  'generic.editCondition': 'Edit condition',
  'report.builder.addCondition': 'Add condition',
  'report.builder.addConditionGroup': 'Add condition group',
  'generic.condition': 'Condition',
  'generic.and': 'And',
  'generic.operator': 'Operator',
  'report.builder.form.addCustomColumn': 'Add a custom column',
  'generic.typeSuccessfullyAdded': '{type} successfully added',
  'generic.continue': 'Continue',
  'generic.addAnotherAsset': 'Add another asset',
  'generic.editProperties': 'Edit Properties',
  'generic.youHaveSuccessfullyAddedName':
    'You have successfully added {name}. Click <s> continue </s> to view your ownership map or {linebreak} click <s> add another </s> to continue adding assets/entities to your profile',
  'onboarding.startUsingSesameInThreeEasySteps': 'Start using Sesame in three easy steps',
  'onboarding.addYourAssets': 'Add your assets',
  'onboarding.selectYourConsolidation': 'Select your consolidation',
  'onboarding.viewYourDashboard': 'View your dashboard',
  'generic.getStarted': 'Get started',
  'onboarding.addAssets': 'Add Assets',
  'onboarding.realEstate': 'Real Estate',
  'onboarding.cash': 'Cash',
  'onboarding.collectible': 'Collectible',
  'onboarding.financialAssets': 'Financial Assets',
  'onboarding.businessInterest': 'Business Interest',
  'onboarding.propertyName': 'Property name',
  'generic.applyToAllSpaces': 'Apply to all spaces within the current account',
  'onboarding.purchasePrice': 'Purchase price',
  'onboarding.purchaseDate': 'Purchase date',
  'onboarding.initialMortgageAmount': 'Initial mortgage amount',
  'onboarding.mortgageOutstanding': 'Mortgage outstanding',
  'generic.photos': 'Photos',
  'onboarding.identifyPropertyByName': 'Enter a name to quickly identify this property',
  'onboarding.latestValuation': 'Latest valuation',
  'onboarding.valuationDate': 'Valuation date',
  'onboarding.selectYourWealthReportingCurrency': 'Select your wealth reporting currency',
  'onboarding.propertyType': 'Property type',
  'generic.address': 'Address',
  'onboarding.currencyThatAllYourWealthBeDisplayedIn': 'This will be currency that all your wealth be displayed in',
  'onboarding.selectYourReportingCurrency': 'Select your reporting currency',
  'onboarding.noteThatYouWillContactSupportInCaseOfAnyChanges': 'Note that you will contact support in case of any changes',
  'onboarding.tellUsAboutTheCashAccount': 'Tell us about the cash account',
  'onboarding.accountName': 'Account name',
  'onboarding.enterAccountName': 'Enter account name',
  'onboarding.financialInstitution': 'Financial Institution',
  'onboarding.selectOrEnterFinancialInstitution': 'Select or enter financial institution',
  'onboarding.whoIsTheLegalOwner': 'Who is the legal owner ?',
  'onboarding.youAreTheLegalOwnerOfTheAsset': 'You are the legal owner of the asset',
  'onboarding.you': 'You',
  'onboarding.existing': 'Existing',
  'onboarding.selectPrevCreatedPartnership': 'Select a previously created partnership, holding company or trust',
  'onboarding.createNewPartnership': 'Create a new partnership, holding company or trust',
  'onboarding.transactionDetails': 'Transaction details',
  'onboarding.enterManually': 'Enter manually',
  'onboarding.securelyLinkYourAccount': 'Securely link your account',
  'onboarding.secureConnectionBalances': 'Secure connection and automatic synchronization of your balances',
  'generic.country': 'Country',
  'onboarding.selectYourCountry': 'Select your country',
  'onboarding.assetName': 'Asset name',
  'onboarding.assetType': 'Asset type',
  'onboarding.selectAssetType': 'Select asset type',
  'onboarding.tradeCurrency': 'Trade currency',
  'generic.optional': 'optional',
  'onboarding.addTransaction': 'Add transaction',
  'generic.select': 'Select',
  'generic.firstName': 'First name',
  'generic.lastName': 'Last name',
  'onboarding.valuationDetails': 'Valuation details',
  'onboarding.bankName': 'Bank name',
  'onboarding.typeHere': 'Type here',
  'onboarding.accountValue': 'Account value',
  'onboarding.theFullPicture': 'The full picture',
  'onboarding.wealth': 'wealth',
  'onboarding.your': 'Your',
  'onboarding.sesameProSignupText':
    'Sesame pro is a platform providing data {linebreak} consolidation, robust performance and risk {linebreak} analytics and automated reporting to high net {linebreak} worth individual.',
  'onboarding.haveAnAccountSignIn': 'Have an account ? Sign in',
  'onboarding.signUp': 'Sign up',
  'generic.email': 'Email',
  'onboarding.signUpMessageAgreement': 'I agree to the {link} and provide my consent to receive communications from Landytech.*',
  'onboarding.privacyPolicy': 'Privacy policy',
  'onboarding.modal.addAnother': 'Add another',
  'onboarding.body.yourAccountHasBeenCreated': 'Your account has been created',
  'onboarding.signup.weHaveSentAnEmailTo': 'We have sent an email to {email}. {linebreak} The link in the email will allow you to set your password.',
  'onboarding.signup.haveYouEnteredTheWrongEmail': 'Have you entered the wrong email address ? {linebreak} You can fix it by clicking {here}',
  'onboarding.addALegalEntity': 'Add a Legal Entity',
  'generic.legalEntity': 'Legal entity',
  'onboarding.tellUsAboutTheProperty': 'Tell us about the property',
  'onboarding.selectPropertyType': 'Select property type',
  'onboarding.whereIsThePropertyLocated': 'Where is the property located ?',
  'onboarding.skipForNow': 'Skip for now',
  'onboarding.identifyTheLegalOwners': 'Identify the legal owner(s)',
  'onboarding.legalOwnershipIsHeldByYou': 'Legal ownership is held entirely or partly by you',
  'generic.holdingCompany': 'Holding company',
  'generic.trust': 'Trust',
  'onboarding.legalOwnershipOwnedByMultiple': 'Legal ownership of the asset is held by one or more individuals',
  'onboarding.legalOwnershipHeldByCompany': 'Legal ownership of the asset  is held by one or more companies',
  'onboarding.legalOwnershipHeldByTrust': 'Legal ownership of the asset is held by one or more trusts',
  'generic.previous': 'Previous',
  'generic.next': 'Next',
  'onboarding.anOwner': 'an owner',
  'onboarding.aTrust': 'a trust',
  'onboarding.youNeedToAddLegalOwnerBeforeAddingOwnershipPercentage': 'Add {legalOwner} or find an existing one {linebreak} to assign ownership percentage',
  'onboarding.stepXofXSteps': 'Step {currentStep} of {stepCount}',
  'generic.create': 'Create',
  'onboarding.ownershipPercentage': 'Ownership percentage',
  'generic.phoneNumber': 'Phone number',
  'generic.phone': 'Phone',
  'generic.contactSuccessfullyDeleted': 'Contact successfully deleted.',
  'generic.assignTask': 'Assign task',
  'generic.assignReport': 'Assign report',
  'generic.assignDocument': 'Assign document',
  'generic.linkToCompany': 'Link to company',
  'generic.linkToAnotherContact': 'Link to another contact',
  'generic.createContact': 'Create Contact',
  'onboarding.addFinancialAssets': 'Add Financial Assets',
  'onboarding.addCash': 'Add Cash',
  'onboarding.addCollectibles': 'Add Collectibles',
  'onboarding.addRealEstate': 'Add Real Estate',
  'onboarding.addAssetsDescriptionCardLegalEntity': 'An individual, company or organization that has legal rights and obligations',
  'onboarding.addAssetsDescriptionCardRealEstate': 'Residential, vacation, rental, commercial or mixed purpose property and any associated loans.',
  'onboarding.addAssetsDescriptionCardCash': 'Securely connect to or manually enter bank accounts including any savings and money market accounts.',
  'onboarding.addAssetsDescriptionCardCollectibles': 'Add any collector’s items like cars, fine wine, jewellery, horses, art and more.',
  'onboarding.addAssetsDescriptionCardFinancialAssets': 'Investment assets like stocks or bonds.',
  'onboarding.addAssetsDescriptionCardBusinessInterest':
    'Business Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos dolore ex illo ipsum molestiae necessitatibus nobis unde! At consectetur',
  'onboarding.steps.selectAValue': 'Select a value',
  'report.visual.historicalChart.error.tickrate': 'Historical chart: the legend period rate used is too small for the selected date range',
  'onboarding.createALegalEntity': 'Create a legal entity',
  'onboarding.identifyLegalEntity': 'Enter a name to quickly identify this legal entity',
  'onboarding.whatIsTheNameOfTheLegalEntity': 'What is the name of the legal entity ?',
  'onboarding.howWouldYouDescribeTheLegalEntity': 'How would you describe the legal entity ?',
  'generic.anAsset': 'an asset',
  'generic.account': 'Account',
  'onboarding.anEntity': 'an Entity',
  'onboarding.anHoldingCompany': 'an Holding Company',
  'generic.searchForContact': 'Search for contact',
  'erp.contacts.editDetails': 'Edit Details',
  'generic.changePhoto': 'Change Photo',
  'generic.person': 'Person',
  'generic.allActivity': 'All Activity',
  'generic.portfolio': 'Portfolio',
  'generic.allTime': 'All Time',
  'generic.lastXDays': 'Last {days} Days',
  'generic.activity': 'Activity',
  'generic.activities': 'Activities',
  'generic.dates': 'Dates',
  'generic.lastActivityDate': 'Last Activity Date',
  'generic.assets': 'Assets',
  'generic.viewAll': 'View All',
  'generic.addressLineX': 'Address line {number}',
  'generic.city': 'City',
  'onboarding.additionalInformation': 'Additional information',
  'onboarding.selectOneOrMoreOwners': 'Select one or more owners and set percentage ownership',
  'onboarding.steps.findAnExisting': 'Find an existing',
  'generic.selectEntities': 'Select Entities',
  'report.builder.pivotMode': 'Pivot Mode',
  'report.builder.pivotModeHelp': 'Pivoting allows you to take a columns values and turn them into columns',
  'generic.values': 'Values',
  'generic.columnsHeader': 'Columns Header',
  'generic.pleaseInputYourValues': 'Please input your values. You can always make changes later on.',
  'generic.colourCodeYourValues': 'Colour-code your values for charts in Publisher and Grid',
  'onboarding.tellUsAboutTheFinancialPortfolio': 'Tell us about the financial portfolio',
  'onboarding.enterTotalPortfolioValue': 'Enter total portfolio value',
  'onboarding.recordTotalPortfolioValue': 'Record the portfolio value for any date',
  'onboarding.enterIndividualStocksAndBonds': 'Enter individual stocks & bonds',
  'onboarding.inputPurchaseDetails': 'Input purchase details of each stock or bond held within your portfolio',
  'onboarding.financialDetails': 'Financial Details',
  'onboarding.enterQuantity': 'Enter quantity',
  'onboarding.quantityPurchased': 'Quantity purchased',
  'onboarding.purchaseAmount': 'Purchase amount',
  'generic.entityIsRequired': 'Entity is required',
  'onboarding.selectAssetName': 'Select asset name',
  'generic.font.baskerville': 'Baskerville',
  'generic.font.didactGothic': 'Didact Gothic',
  'onboarding.tellUsAboutTheCollectible': 'Tell us about the collectible',
  'onboarding.collectibleType': 'Collectible type',
  'onboarding.collectibleName': 'Collectible name',
  'onboarding.selectCollectibleType': 'Select collectible type',
  'onboarding.enterNameToIdentifyCollectible': 'Enter a name to quickly identify this collectible',
  'generic.pleaseEnterField': 'Please enter {field}.',
  'generic.errorWhenCreatingContact': 'There was an error when attempting to create the contact, please try again or contact an administrator',
  'generic.contactSuccessfullyCreated': 'The contact was successfully created.',
  'generic.companySuccessfullyCreated': 'The company was successfully created.',
  'generic.others': 'Others',
  'generic.mostUsed': 'Most used',
  'generic.contactSuccessfullyUpdated': 'The contact was successfully updated.',
  'generic.companySuccessfullyUpdated': 'The company was successfully updated.',
  'generic.errorWhenUpdatingContact': 'There was an error when updating the contact, please try again or contact an administrator.',
  'erp.contacts.fieldExceedsLimitedAmountOfCharacters': 'The {field} exceeds the limited amount of characters.',
  'generic.contacts': 'Contacts',
  'onboarding.addTrust': 'Add Trust',
  'erp.contacts.addDescription': 'Add a description',
  'erp.contacts.addAddressInformation': 'Add additional address information',
  'erp.contacts.selectACountry': 'Select a country',
  'erp.contacts.randomPostalCode': 'NW8 8SJ',
  'generic.street': 'Street',
  'generic.london': 'London',
  'generic.randomAddress': '1234 Main Street',
  'generic.randomCompany': "London's Central Bank",
  'report.builder.hideSortableIcons': 'Hide sortable icons',
  'report.builder.hideReportTitle': 'Hide report title',
  'generic.header': 'Header',
  'generic.footer': 'Footer',
  'generic.pagination': 'Pagination',
  'generic.hide': 'Hide',
  'generic.process': 'Process',
  'onboarding.yourAccountViaOpenBanking': 'your account {accountName} via Open Banking',
  'onboarding.myWealth': 'My wealth',
  'onboarding.accessCode': 'Access code',
  'generic.riskCountry': 'Risk Country',
  'generic.positions': 'Positions',
  'generic.transactions': 'Transactions',
  'generic.transactionsCount': `{count, plural,
                                one {{count, number} Transaction}
                                other {{count, number} Transactions}
                              }`,
  'generic.redemption': 'Redemption',
  'generic.subscription': 'Subscription',
  'generic.purchase': 'Purchase',
  'generic.dividend': 'Dividend',
  'generic.photo': 'Photo',
  'generic.somethingWentWrongWhenUploadingPhoto': "Something went wrong when uploading the contact's photo",
  'report.builder.customText': 'Custom text',
  'report.builder.showEntity': 'Show entity',
  'generic.showDate': 'Show date',
  'generic.dateFormat': 'Date format',
  'report.builder.successfullyCopiedComponent': 'Successfully copied component {componentTitle}',
  'report.builder.successfullyPastedComponent': 'Successfully pasted component {componentTitle}',
  'report.builder.successfullyCopiedPage': 'Successfully copied page {pageTitle}',
  'report.builder.successfullyPastedPage': 'Successfully pasted page {pageTitle}',
  'generic.show': 'Show',
  'generic.rename': 'Rename',
  'generic.inherit': 'Inherit',
  'generic.somethingWentWrongWhenDeletingPhoto': 'Something went wrong when attempting to delete the photo',
  'onboarding.additionalOwners': 'Additional Owner(s)',
  'onboarding.addOwner': 'Add Owner',
  'onboarding.searchTrust': 'Search Trust',
  'onboarding.searchOwner': 'Search Owner',
  'onboarding.searchHoldingCompany': 'Search Holding Company',
  'onboarding.addHoldingCompany': 'Add Holding Company',
  'generic.notes': 'Notes',
  'generic.createCompany': 'Create Company',
  'generic.ceo': 'CEO',
  'generic.founded': 'Founded',
  'generic.randomFounded': '1 July 2003, California, United States',
  'generic.createNote': 'Create Note',
  'generic.successfullyUpdatedMetadataKey': 'Successfully updated property.',
  'generic.somethingWentWrongWhenUpdatingKey': 'Something went wrong when attempting to update property.',
  'generic.saveNote': 'Save Note',
  'generic.enterNoteDescription': 'Enter note description',
  'generic.note': 'Note',
  'generic.successfullyAdded': 'Successfully added',
  'generic.noNotesAvailable': 'No notes available',
  'generic.links': 'Links',
  'generic.thisLinkIsNotEditable': 'This link has been marked as not editable',
  'generic.saveTask': 'Save Task',
  'generic.enterTaskDescription': 'Enter task description',
  'generic.assignedToUppercase': 'Assigned to',
  'generic.noTasksAvailable': 'No tasks available',
  'generic.menu.spaces': 'Spaces',
  'generic.youMustEnterAKeyName': 'You must enter a key name to proceed.',
  'generic.popup.youAreImpersonating': 'You are impersonating {firstName} {lastName}',
  'generic.successfullyCreatedMetadataKey': `Successfully created the key {keyName}.`,
  'generic.youMustEnterAdecimal': 'You must enter a positive decimal number between 0 and 10 to proceed.',
  'generic.editProperty': 'Edit Property',
  'generic.popup.youHaveNoRoles': 'No roles are assigned to your user in the current space. Please contact an administrator to be granted roles.',
  'generic.popup.youAreActingIn': 'You are acting in {actingCompany}',
  'generic.dueDate': 'Due Date',
  'generic.reminder': 'Reminder',
  'generic.priority': 'Priority',
  'report.builder.addMeasureCondition': 'Add Measure Condition',
  'generic.successfullySavedLinks': 'Successfully saved links',
  'generic.somethingWentWrongWhenSavingLinks': 'Something went wrong when attempting to save the links',
  'generic.contact': 'Contact',
  'generic.link': 'Link',
  'erp.reminders.atTaskDueTime': 'At task due time',
  'erp.reminders.noReminder': 'No reminders',
  'erp.reminders.thirtyMinsBefore': '30 minutes before',
  'erp.reminders.sixtyMinsBefore': '60 minutes before',
  'erp.reminders.ninetyMinsBefore': '90 minutes before',
  'erp.reminders.twoHundredFortyMinsBefore': '240 minutes before',
  'erp.reminders.oneDayBefore': '1 day before',
  'erp.reminders.threeDaysBefore': '3 days before',
  'erp.reminders.sevenDaysBefore': '7 days before',
  'report.tooltip.entityLimitReached': 'Entity limit reached. Please remove entities.',
  'generic.editTask': 'Edit Task',
  'generic.removeTask': 'Remove Task',
  'generic.notes.messageNoteRemoval': 'Are you sure you want to delete this note? All the data related to the note will be permanently deleted. Do you still wish to continue?',
  'generic.tasks.messageTaskRemoval': 'Are you sure you want to delete this task? All the data related to the task will be permanently deleted. Do you still wish to continue?',
  'generic.messageCommentRemoval': 'Are you sure you want to remove this comment? Do you still wish to continue?',
  'generic.successfullyUpdated': 'Successfully updated',
  'generic.document.upload.anErrorOccurredWhenAttemptingToUploadFile': 'An error occurred when attempting to upload the file.',
  'erp.activity.noRecentActivity': 'No recent activity',
  'generic.details': 'Details',
  'generic.common': 'Common',
  'generic.textField': 'Text field',
  'generic.textFieldDescription': 'Add any form of written content. Max 200 characters.',
  'generic.dateField': 'Date',
  'generic.dateFieldDescription': 'Select a specific date',
  'generic.numberField': 'Number',
  'generic.numberFieldDescription': 'Input numbers or numeric data',
  'generic.singleSelectField': 'Single-select',
  'generic.singleSelectFieldDescription': 'Choose one option from a dropdown list',
  'generic.multiSelectField': 'Multi-select',
  'generic.multiSelectFieldDescription': 'Select one or more options from a list',
  'generic.toggleField': 'Toggle',
  'generic.toggleFieldDescription': 'Check or uncheck for binary choices',
  'generic.currencyField': 'Currency',
  'generic.currencyFieldDescription': 'Choose a currency from the list of available options',
  'generic.userSelectField': 'User select',
  'generic.userSelectFieldDescription': 'Choose users from the list of available options',
  'generic.urlAddressField': 'URL Address',
  'generic.urlAddressFieldDescription': 'Insert a URL or web address for quick access',
  'generic.timeSeriesField': 'Time series',
  'generic.timeSeriesFieldDescription': 'Add custom table with dated values',
  'generic.countryField': 'Country',
  'generic.countryFieldDescription': 'Select a country from the global list',
  'generic.deleteNote': 'Delete Note',
  'generic.editNote': 'Edit Note',
  'generic.successfullyDeleted': 'Successfully deleted',
  'generic.user': 'User',
  'erp.comments.noCommentsAvailable': 'No comments available',
  'generic.property': 'Property',
  'generic.search.manageCategories': 'Manage Categories',
  'generic.search.noResultFound': 'No result found.',
  'generic.search.messageRecentSearchNotFound': 'Your recent searches will appear here {linebreak} for quick access.',
  'generic.search.recentSearches': 'Recent searches',
  'generic.search.singleAssetPage': 'Single Asset Page',
  'generic.search.singleAssetView': 'Single Asset View',
  'generic.enterEntityName': 'Enter entity name...',
  'erp.contacts.single.taskNo': 'Task No.',
  'generic.noDueDate': 'No due date',
  'generic.author': 'Author',
  'generic.deleted': 'Deleted',
  'erp.body.manageLinks': 'Manage links',
  'erp.noLinksAvailable': 'Links created for this {entityType} will appear here',
  'generic.clearAll': 'Clear all',
  'erp.contacts.postcode': 'Postcode',
  'generic.postalCode': 'Postal Code',
  'generic.xNumberLinks': '{number} link(s)',
  'erp.taskList': 'Task List',
  'generic.searchForTasks': 'Search for tasks',
  'generic.createdDate': 'Created Date',
  'erp.noteList': 'Note List',
  'generic.searchForNotes': 'Search for notes',
  'generic.previousTask': 'Previous task',
  'generic.nextTask': 'Next task',
  'generic.taskSuccessfullyUpdated': 'Task successfully updated.',
  'generic.createdOn': 'Created on',
  'generic.id': 'ID',
  'generic.terminateActing': 'Terminate acting',
  'generic.previousNote': 'Previous note',
  'generic.selected': 'Selected',
  'generic.nextNote': 'Next note',
  'erp.portfolio.currentMarketValueLc': 'Current Market Value (LC)',
  'erp.portfolio.inceptionToDatePnl': 'Inception to Date P&L',
  'generic.custodian': 'Custodian',
  'report.builder.quantLibrary.showExcelMeasuresOnly': 'Show Excel Measures Only',
  'report.builder.quantLibrary.excelMeasure': 'Excel Tool Measure',
  'generic.quantity': 'Quantity',
  'generic.marketValueRc': 'Market Value RC',
  'generic.verifiedDate': 'Verified Date',
  'generic.movement': 'Movement',
  'erp.hasCreatedLinkBetween': 'has created a link between',
  'erp.hasDeletedLinkBetween': 'has deleted a link between',
  'report.builder.sheet.firstValueSubTotal': 'First Value Sub Total',
  'generic.tag': 'Tag',
  'report.sider.reports': 'Reports',
  'report.sider.gallery': 'Gallery',
  'report.sider.allReports': 'All Reports',
  'report.sider.scheduler': 'Scheduler',
  'report.sider.bulkReports': 'Bulk reports',
  'generic.templates': 'Sesame Templates',
  'generic.myFavorites': 'My Favorites',
  'generic.allDocuments': 'All Documents',
  'generic.backToReportCenter': 'Back to Report Center',
  'search.for.documents': 'Search for documents',
  'create.report': 'Create Report',
  'report.publisher': 'Publisher',
  'report.grid': 'Grid',
  'report.gridBeta': 'Grid BETA',
  'report.internal': 'Internal',
  'report.external': 'External',
  'report.builder.entityDoesntExistOrNotAllowed': "The entity you selected doesn't exist or you don't have permissions to use it.",
  'generic.deleteTask': 'Delete Task',
  'generic.createOrAddYourselfASpace':
    'Your Account does not contain any Spaces or you have not been assigned to one. If your Account already has one or more Spaces, please assign your user to at least one. Follow this {link} to resolve.',
  'generic.askAdminToAddYouToASpace': 'Your User does not have access to any Spaces in this Account. Please contact your Administrator to grant you access to one or more Spaces.',
  'generic.adminRedirectLabel': 'link',
  'report.builder.successfullyCreatedTheReport': 'Successfully created the report {title}',
  'erp.asset.searchForAssets': 'Search for assets',
  'generic.creationDate': 'Creation Date',
  'ownership.notEnoughEntitiesForOrgChart': 'There are not enough entities available to build an organization chart.',
  'erp.portfolio.searchForPortfolios': 'Search for portfolios',
  'erp.portfolio.searchForLegalEntity': 'Search for legal entity',
  'report.builder.theme': 'Theme',
  'report.builder.textAlign': 'Text align',
  'generic.fontStyle': 'Font Style',
  'report.builder.table': 'Table',
  'report.builder.headerFontStyle': 'Header font style',
  'report.builder.headerFontColor': 'Header font color',
  'report.builder.headerBackgroundColor': 'Header background color',
  'report.builder.headerBorder': 'Header border',
  'report.builder.headerBorderColor': 'Header border color',
  'report.builder.hideHeaderBoxShadow': 'Hide header box shadow',
  'report.builder.bodyFontStyle': 'Body font style',
  'report.builder.bodyFontColor': 'Body font color',
  'report.builder.bodyBackgroundColor': 'Body background color',
  'report.builder.bodyAlternateBackgroundColor': 'Body alternate background color',
  'report.builder.groups': 'Groups',
  'report.builder.colorScheme': 'Color scheme',
  'report.builder.footerFontStyle': 'Footer font style',
  'report.builder.footerFontColor': 'Footer font color',
  'report.builder.footerBackgroundColor': 'Footer background color',
  'report.builder.firstColumnTextAlign': 'First column text align',
  'report.builder.lastColumnTextAlign': 'Last column text align',
  'report.builder.container': 'Container',
  'report.builder.borderRadius': 'Border radius',
  'report.builder.margin': 'Margin',
  'report.builder.padding': 'Padding',
  'erp.contact.messageContactRemoval':
    'Are you sure you want to remove this contact? All the data related to the contact will be permanently removed. Do you still wish to continue?',
  'erp.contact.messageCompanyRemoval':
    'Are you sure you want to remove this company? All the data related to the company will be permanently removed. Do you still wish to continue?',
  'generic.login.somethingWentWrongDuringLoginProcess': 'Something went wrong during the login process. Please try again soon or contact an administrator.',
  'generic.maintenance.maintenanceInProgress': 'Our website is currently undergoing scheduled maintenance.',
  'generic.maintenance.maintenanceInProgressBackSoon': 'We should be back shortly. Thank you for your patience.',
  'generic.maintenance.maintenanceInProgressTitle': 'Website under Maintenance',
  'generic.maintenance.maintenanceInProgressTwoHours': 'We should be back in approximately 2 hours.',
  'generic.popover.createSpace': 'Create Space',
  'generic.popover.spaceSettings': 'Space Settings',
  'generic.popover.searchForSpace': 'Search for a space',
  'erp.task.emptyTaskTitle': 'No Tasks',
  'erp.task.emptyTaskSubTitle': 'Tasks created for this {entityType} will appear here',
  'erp.documents.emptyDocumentTitle': 'No Documents',
  'erp.documents.emptyDocumentSubTitle': 'Documents linked to this {entityType} will appear here',
  'erp.note.emptyNoteTitle': 'No Notes',
  'erp.note.emptyNoteSubTitle': 'Notes created for this {entityType} will appear here',
  'generic.banner.weHaveDeliveredANewPermissionSystem':
    "We've introduced our new and improved sharing and permissions system, making it even easier and more secure to manage your user access rights. Find out more in our ",
  'generic.searchForAnEntity': 'Search for {entityType}',
  'generic.comment': 'Comment',
  'erp.contact.comments.visibleTo': 'Visible to <b>{entity}</b>',
  'generic.parent': 'Parent',
  'erp.table.linkedWith': 'Linked With',
  'generic.selectADate': 'Select a date',
  'report.consolidation.selectAConsolidationType': 'Select a consolidation type',
  'generic.noDatesAvailable': 'No dates available',
  'generic.error.internalServerErrorMessage': 'Something unexpected happened. Try refreshing the page',
  'generic.error.internalServerError': 'Internal Server Error',
  'generic.taskTitle': 'Task Title',
  'report.builder.gridLineColor': 'Grid line color',
  'generic.propertyNameIsRequired': 'Property name is required',
  'generic.newValue': 'New Value',
  'generic.selectValue': 'Select value',
  'tms.details.capturedValue': 'Captured Value',
  'generic.Value': 'Value',
  'generic.addMore': 'Add more',
  'generic.back': 'Back',
  'generic.confirm': 'Confirm',
  'generic.typeValue': 'Type value',
  'customPages.manageCustomPages': 'Manage My Custom Pages',
  'generic.enterPropertyName': 'Enter property name',
  'generic.reportCenter': 'Report Center',
  'customPages.sider.customPagesNoDataSider': 'No reports or folders added yet, head to the {link} to add reports to your Custom Pages.',
  'customPages.customPagesNoData': 'No reports or folders added yet, head to the {link} to add reports to your Custom Pages or get started by adding a folder.',
  'customPages.addFolder': 'Add folder',
  'customPages.folderName': 'Folder name',
  'customPages.folderNameError': 'Folder name cannot be empty. Please enter a valid name.',
  'customPages.confirmFolderDeletion': 'Confirm Folder Deletion',
  'customPages.folderDeletionDescription': 'This folder contains reports. Choose an option for handling these reports:',
  keepReports: 'Keep Reports',
  'customPages.keepReportsDescription': 'Retain reports in your Custom Pages, but remove them from this folder.',
  'customPages.removeReports': 'Remove Reports',
  'customPages.removeReportsDescription':
    'Delete reports from this folder and Custom Pages. This action will remove the entire folder of reports from Custom Pages but keep them accessible in the Report Center.',
  'customPages.confirmCustomPageDeletion': 'Remove this report from your list?',
  'customPages.customPageDeletionDescription': 'It will remain accessible in the Report Center.',
  'customPages.emptyCenter': 'No reports found in this folder. Please add reports via the {link}.',
  'customPages.organiseFolders': 'To organise folders, hover over a report to reveal the {icon} icon, click, drag using this icon, and drop into your desired folder.',
  'generic.font.gudea': 'Gudea',
  'generic.font.gelasio': 'Gelasio',
  'generic.showCurrency': 'Show currency',
  'generic.logoPosition': 'Logo position',
  'generic.logoWidthPercentage': 'Logo width (%)',
  'documents.error.documentUploadAbortedDueToLinkCreation': 'Document upload aborted due to error while creating links',
  'erp.activity.hasUpdatedReminderFor': 'updated the reminder for',
  'generic.asOfDate': 'As of date',
  'generic.holdingSets': 'Holdings Set',
  'report.visual.gauge.unableToFindScore': 'Unable to find score for the selected entity.',
  'generic.showMore': 'Show more',
  'documents.all': 'All documents',
  'documents.favorites': 'Favorites',
  'documents.recycleBin': 'Recycle Bin',
  'generic.categories': 'Categories',
  'report.visual.historical.grandTotal': 'Grand Total',
  'generic.subtype': 'Subtype',
  'ownership.export.successfullyDownloaded': 'Successfully downloaded',
  'ownership.export.button.exportViewAsPdf': 'Export view as PDF',

  'scheduler.createScheduleTitle': 'Create a schedule report',
  'scheduler.createScheduleDescription':
    'Set how often a report is shared and to automate sending it. To do this, enter the desired information in the required fields and click Schedule report.',
  'scheduler.updateScheduleTitle': 'Update schedule',
  'scheduler.updateScheduleDescription':
    'Edit how often a report is shared and to automate sending it. To do this, enter the desired information in the required fields and click Update.',
  'generic.active': 'Active',
  'scheduler.reportType': 'Report Type',
  'scheduler.sendNotification': 'Send notification',
  'scheduler.sendAsLink': 'Link',
  'scheduler.sendAsAttachment': 'Attachment',
  'scheduler.recipient': 'Recipient',
  'scheduler.furtherDetails': 'Further details',
  'scheduler.deleteSchedule': 'Delete Schedule',
  'scheduler.createSchedule': 'Create Schedule',
  'scheduler.scheduleJobsHistoryTitle': 'Historical Reports',
  'scheduler.scheduleJobsHistoryDescription': 'View the history for scheduled reports.',
  'generic.downloading': 'DOWNLOADING...',
  'erp.activity.noActivity': 'No Activity',
  'erp.activity.activityRelatedToContact': 'Activity related to a contact',
  'generic.settings': 'Settings',
  'globalSearch.assetGroups.cash': 'Cash',
  'globalSearch.assetGroups.businessInvestments': 'Business Investments',
  'globalSearch.assetGroups.equity': 'Equity',
  'globalSearch.assetGroups.fxDerivatives': 'FX Derivatives',
  'globalSearch.assetGroups.fixedIncome': 'Fixed Income',
  'globalSearch.assetGroups.alternatives': 'Alternatives',
  'globalSearch.assetGroups.commodities': 'Commodities',
  'globalSearch.assetGroups.benchmark-peers': 'Benchmark peers',
  'globalSearch.assetGroups.collectibles': 'Collectibles',
  'globalSearch.assetGroups.multi-asset': 'Multi Asset',
  'globalSearch.assetGroups.property': 'Property',
  'globalSearch.assetGroups.personalLiabilities': 'Personal Liabilities',
  'generic.uncaughtError': 'Uncaught Error',
  'scheduler.closeOfBusiness': 'Close of business',
  'scheduler.uponDateValidation': 'Upon date validation',
  'generic.vehicle': 'Vehicle',
  'generic.enterAValue': 'Enter a value',
  'generic.owner': 'Owner',
  'generic.toDo': 'To Do',
  'generic.clearHistory': 'Clear History',
  'generic.inProgress': 'In Progress',
  'generic.onHold': 'On Hold',
  'generic.completed': 'Completed',
  'generic.removed': 'Removed',
  'generic.between': 'Between',
  'generic.before': 'Before',
  'generic.after': 'After',
  'generic.low': 'Low',
  'generic.tags': 'Tags',
  'generic.moderate': 'Moderate',
  'generic.high': 'High',
  'generic.itd': 'ITD',
  'generic.ytd': 'MTD',
  'generic.mtd': 'YTD',
  'generic.shared': 'Shared',
  'generic.inceptionDate': 'Inception Date',
  'generic.owners': 'Owners',
  'generic.issuer': 'Issuer',
  'generic.conversation': 'Conversation',
  'generic.mention': 'Mention',
  'generic.shareWithLandytech': 'Share with Landytech',
  'generic.sharingDocumentWithLandytechFailed': 'Something went wrong when attempting to share this document. Please try again soon or contact an administrator',
  'generic.sharingDocumentWithLandytechSucceeded': 'Successfully shared {documentName} with Landytech',
  'generic.thisDocumentHasNotBeenSharedToLandytech': 'This document cannot be downloaded. It must be shared with Landytech.',
  'generic.stopSharingToLandytech': 'Stop sharing to Landytech',
  'generic.successfullyStoppedToShareToLandytech': 'Successfully stopped sharing {documentName} to Landytech.',
  'generic.stoppingToShareToLandytechFailed': 'Stopping to share this document with Landytech failed. Please try again soon.',
  'generic.happensWhenYouActInAnAccount': 'This generally happens when you act in an account.',
  'report.closeOfBusiness': 'Close of business',
  'report.uponDateValidation': 'Upon date validation',
  'erp.portfolio.noTransaction': 'No Transaction',
  'generic.generalInfo': 'General info',
  'erp.portfolio.TransactionWouldAppearHere': 'Transactions for this {pageType} would appear here',
  'generic.numberFormat': 'Number format',
  'generic.chooseFormatForNumericValues': 'Choose the format for your numeric values. You can customise this later in settings.',
  'generic.example': 'Example',
  'generic.experience': 'Experience',
  'generic.decimalPlaces': 'Decimal places',
  'generic.numericValueAsCurrency':
    'To use these numeric values as currencies, you will need to create a "Currency" property. This will allow you to apply the desired currency to the numeric values.',
  'generic.loadMore': 'Load more',
  'erp.portfolio.noPosition': 'No Positions',
  'erp.portfolio.positionWillAppearHere': 'Positions for this {pageType} will appear here',
  'generic.onlyTheFollowingFileExtensionsAreAllowed': 'Only the following file extensions are allowed',
  'generic.fileSizeExceedsTheLimit': 'File size exceeds the limit',
  'erp.messages.notificationDeleted': 'Notification deleted',
  'scheduler.are.you.sure': 'Are you sure?',
  'scheduler.close.before.submitting.description': 'If you close the panel before submitting, your changes will be lost.',
  'dms.newProperty': 'New Property',
  'dms.properties.manageProperties': 'Manage Properties',
  'dms.bulkAddTags': 'Bulk add properties',
  'dms.bulkAddTagsDescription': 'Add properties and attributes to selected documents',
  'generic.addProperty': 'Add property',
  'generic.manageProperties': 'Manage Properties',
  'generic.searchForEntity': 'Search for entity',
  'generic.searchForAsset': 'Search for asset',
  'generic.docInboxSettings': 'Doc Inbox Settings',
  'generic.markAllUpdated': 'Mark all as Updated',
  'dms.thisWillClearRecentDocumentHistory': 'Clear recent documents history',
  'dms.successfullyClearedRecentDocumentsHistory': 'Successfully cleared recent documents history',
  'dms.somethingWentWrongWhenAttemptingToClearDocumentHistory': 'Something went wrong when attempting to clear recent documents history. Please try again later',
  'generic.content': 'Content',
  'generic.defaultProperties': 'Default properties',
  'generic.defaultValue': 'Default value',
  'generic.defaultValueForTheToggle': 'Choose the default value for the toggle.',
  'generic.myProperties': 'My properties',
  'generic.searchForAValue': 'Search for a value...',
  'erp.mustBeProvided': 'must be provided!',
  'erp.mustNotContainOnlyWhiteSpaces': 'must not contain only spaces!',
  'erp.LoginEmailMustBeValid': 'Login email must be a valid email!',
  'dms.properties.manageCategories': 'Manage Categories',
  'dms.onlyUnsharedDocumentsHaveBeenSelected': 'Only unshared documents have been selected. Select at least one shared document to download.',
  'generic.somethingWentWrongWhenBulkUpdatingDocuments': 'Something went wrong when updating the documents, please try again in a moment',
  'generic.successfullyBulkUpdatedDocuments': 'The documents have been successfully updated',
  'generic.text': 'Text',
  'generic.number': 'Number',
  'dms.addProperty': 'Add property',
  'dms.deleteProperty': 'Delete property',
  'generic.lastModifiedDate': 'Last modified date',
  'generic.reminderDate': 'Reminder date',
  'generic.assignee': 'Assignee',
  'generic.seeMore': 'See more',
  'generic.noPrevious': 'No previous',
  'generic.select.account': 'Select account',
  'report.modal.template.usage': 'Template Usage',
  'generic.accounts': 'Accounts',
  'report.body.accessible.by.every.account': 'Accessible by every account',
  'report.schedules': 'Schedules',
  'report.modal.added.to.custom.reports': 'Report "{name}" successfully added to custom pages',
  'report.modal.removed.from.custom.reports': 'Report "{name}" successfully removed from custom pages',
  'report.custom.pages.add': 'Add to Custom Pages',
  'report.custom.pages.remove': 'Remove from Custom Pages',
  'report.edit.schedule': 'Edit Schedule',
  'generic.history': 'History',
  'generic.all.accounts': 'All Accounts',
  'generic.analytics': 'Analytics',
  'generic.additionalInformation': 'Additional Information',
  'dms.search': 'Search for documents',
  'erp.addACompany': 'Add a company',
  'generic.manage': 'Manage',
  'dms.successfullyUnsharedDocuments': 'Successfully unshared all documents',
  'dms.successfullySharedDocuments': 'Successfully shared all documents',
  'generics.confirmationDescription': 'This action cannot be undone!',
  'generics.confirmationTitle': 'Are you sure?',
  'generics.clearFilters': 'Clear filters',
  'custodialData.apiTokesPageTitle': 'API Tokens',
  'custodialData.apiTokesSubPageTitle': 'Application Access Tokens',
  'custodialData.apiTokesInputSearchPlaceholder': 'Search for tokens',
  'custodialData.activeTab': 'Active',
  'custodialData.expiredAndRevokedTab': 'Expired & Revoked',
  'custodialData.addTokenDrawerTitle': 'Add Token',
  'custodialData.updateTokenDrawerTitle': 'Update Token',
  'custodialData.addTokenNameFormItemLabel': 'Token Name',
  'custodialData.addTokenDescriptionFormItemLabel': 'Description',
  'custodialData.addTokenExpirationFormItemLabel': 'Expiration',
  'custodialData.addTokenDateFormItemLabel': 'Date',
  'custodialData.addTokenExpirationCustomOption': 'Custom',
  'custodialData.addTokenHowToAuthenticateLabel': 'How to Authenticate?',
  'custodialData.addTokenHowToUseIpWhitelist': 'How to use IP Whitelisting?',
  'custodialData.tokenNameTableLabel': 'Token Name',
  'custodialData.createdOnTableLabel': 'Created On',
  'custodialData.lastUsedTableLabel': 'Last Used',
  'custodialData.expirationTableLabel': 'Expiration',
  'custodialData.revokeTokenTableActionLabel': 'Revoke',
  'custodialData.generalInfoCollapseHeaderLabel': 'General',
  'custodialData.ipWhitelistCollapseHeaderLabel': 'IP Whitelist',
  'custodialData.requiredNameTokenFormMessage': 'Token name is required',
  'custodialData.requiredExpirationTokenFormMessage': 'Expiration is required',
  'custodialData.requiredDateTokenFormMessage': 'Date is required',
  'custodialData.currentIpLabel': 'Your current IP is {ip}',
  'custodialData.add.my.ip': 'Add my IP',
  'custodialData.ip.list': 'IP List',
  'custodialData.general.info.username': 'Username',
  'custodialData.general.info.password': 'Password',
  'custodialData.general.info.expiry.date': 'Expiry Date',
  'custodialData.general.info.copyMessage': '{text} copied to clipboard!',
  'custodialData.tokenCreationSuccess': 'Token created!',
  'custodialData.tokenUpdateSuccess': 'Token updated!',
  'custodialData.tokenRevokedSuccess': 'Token revoked!',
  'custodialData.generalErrorMessage': 'Something went wrong!',
  'custodialData.ipWhiteListing.addRow': 'Add More',
  'custodialData.logsPageTitle': 'Logs',
  'custodialData.logsTable.dateOfRequest': 'Date of Request',
  'custodialData.logsTable.resource': 'Resource',
  'custodialData.logsTable.endpoint': 'Endpoint',
  'custodialData.logsTable.status': 'Status',
  'custodialData.logsTable.requestDuration': 'Request Duration',
  'custodialData.logsTable.tokenName': 'Token Name',
  'custodialData.saveButtonLabel': 'Add',
  'custodialData.form.requiredMessage': '{formField} is required',
  'custodialdata.logsFilter.endpointPrefix': 'Endpoint',
  'custodialdata.logsFilter.statusPrefix': 'Status',
  'custodialdata.logsFilter.datePrefix': 'Date',
  'custodialdata.logsFilter.searchLogsPlaceholder': 'Search for log message',
  'custodialData.entityPage.title': 'Entity List',
  'custodialData.entityPage.exportCsv': 'Export CSV',
  'custodialData.entityListTable.entityIdColumnLabel': 'Entity ID',
  'custodialData.entityListTable.entityNameColumnLabel': 'Entity Name',
  'custodialData.entityListTable.entityTypeColumnLabel': 'Entity Type',
  'custodialData.entityListTable.entityCurrencyColumnLabel': 'Reporting Currency',
  'custodialData.entityListTable.entityCustodianColumnLabel': 'Custodian',
  'custodialData.entityListTable.entityLastVerifiedDateColumnLabel': 'Last Verified Date',
  'custodialData.entityListFilters.searchEntitiesPlaceholder': 'Search for entities',
  'custodialData.entityListFilters.currencyPrefix': 'Currency',
  'custodialData.entityListFilters.custodianPrefix': 'Custodian',
  'custodialData.entityListFilters.typePrefix': 'Type',
  'custodialData.entityList.entityUpdatedMessage': 'Entity updated successfully',
  'custodialData.dashboard.tabs.byEndpoints': 'By Endpoints',
  'custodialData.dashboard.tabs.byStatus': 'By Status',
  'custodialData.dashboard.chartCard.title': 'Requests',
  'custodialData.dashboard.apiEndpointsSection.title': 'API Endpoints',
  'custodialData.dashboard.activeTokenSection.title': 'Active Tokens',
  'custodialData.dashboard.activeTokenSection.description': 'Your token allows you to make API requests for your Sesame data',
  'custodialData.noTokensMessage': 'Create a token or add your current IP to an active token to access this feature.',
  'generic.search.for.entities': 'Search for entities',
  'generic.select.all': 'Select All',
  'custodialData.ipAlreadyInserted': 'IP already added. Each IP must be unique.',
  'custodialData.tokenForm.infoMessage': 'SuperAdmins or ClientAdmins will have today+2 days; Acting users will have the expiry date of the acting permission.',
  'report.visual.noYetSupported': 'Visual not yet supported',
  'generic.@ToMentionSpaceMember': 'type @ to mention space member',
  'erp.activity.activityRelatedTo': 'Activity related to this {entityType} will appear here',
  'erp.CommentsOnThisEntityWillAppearHere': 'Comments on this {entityType} will appear here',
  'generic.viewDeletedComment': 'View deleted comment',
  'report.visual.fetchError': 'Error while fetching data',
  'scheduler.eod': 'EOD',
  'scheduler.as.soon.as.date.validated': 'As soon as available',
  'scheduler.as.specific.date': 'Schedule for later',
  'scheduler.daily.extra': 'Daily reports timeout at 12am next day after trigger.',
  'scheduler.weekly.extra': 'Runs Mondays at 12am.',
  'scheduler.as.soon.as.date.validated.extra': 'This will send when we receive the data.',
  'scheduler.as.specific.date.extra': 'This will send regardless of if the data is received.',
  'erp.activity.activityRelatedToCompany': 'Activity related to a company will appear here',
  'generic.viewNewComment': 'View new comment',
  'generic.commentedOnThisTask': 'Commented on this task',
  'erp.reminders.atDueTime': 'At due time',
  'erp.reminders.min60': '60 minutes before',
  'erp.reminders.min90': '90 minutes before',
  'erp.reminders.min240': '240 minutes before',
  'erp.reminders.day1': '1 day before',
  'erp.reminders.day3': '3 days before',
  'erp.reminders.day7': '7 days before',
  'generic.limit_breach_classification': 'limit breach classification',
  'generic.holding_set': 'holding set',
  'erp.activity.reminderHasBeenSentFor': 'The reminder has been sent for the',
  'erp.errorWhenLinkingContactToCompany': 'There was an error linking this contact to a company',
  'erp.comments.noComments': 'No comments',
  'erp.comments.addComment': 'Add Comment',
  'generic.notified': 'Notified',
  'generic.column': 'Column',
  'generic.timeSeries': 'Time Series',
  'filter.groupedFilter': 'Grouped filter',
  'filter.singleFilter': 'Single filter',
  'filter.addNestedFilter': 'Add nested filter',
  'filter.selectAdditionalMeasure': 'Select Additional Measure',
  'filter.addFilter': 'Add Filter',
  'filter.where': 'Where',
  'generic.duplicate': 'Duplicate',
  'generic.legalEntities': 'Legal Entities',
  'erp.tasksList': 'Tasks List',
  'erp.notesList': 'Notes List',
  'erp.markAllAsRead': 'Mark all as read',
  'erp.NoNewNotificationsToView': 'There are no new notifications for you to view.',
  'erp.AllMessagesHaveBeenMarkedAsRead': 'All messages have been marked as read',
  'erp.MessageHasBeenDeletedSuccesfully': 'Message has been deleted succesfully',
  'generic.license': 'License',
  'generic.columns': 'Columns',
  'generic.permittedLicenses': 'Permitted Licenses',
  'report.productTemplate': 'Product Template',
  'report.accessibleByAllLicenses': 'Accessible by all licenses',
  'generic.groupings': 'Groupings',
  'generic.vehicles': 'Vehicles',
  'generic.Recommended': 'Recommended',
  'erp.activityWillAppearHere': 'Activity for this {pageType} will appear here',
  'erp.itemsLinkedToEntityTypeWillAppearHere': 'Links to other objects will appear here',
  'report.error.wrongVisualConfig': 'The configuration of this visual is incorrect. Try to reset your preferences or contact support.',
  'pms.assetBulkPricesAdd.withoutPermission.message':
    'Your user does not have the permission required to create asset prices.\n Please contact your administrator to create one or to grant you the permission to.',
  'pms.emptyScreenList.withPermission.message': 'You can now start adding {entity} to your account',
  'pms.emptyScreenList.withoutPermission.message':
    'There are no {entity} in this space and your user does not have the permission required to create one.\n Please contact your administrator to create one or to grant you the permission to.',
  'pms.legalEntity.emptyList.withPermission.message': 'You can now start adding Legal Entities to your account',
  'pms.legalEntity.emptyList.withoutPermission.message':
    'There are no legal entities in this space and your user does not have the permission required to create one.\n Please contact your administrator to create one or to grant you the permission to.',
  'pms.legalEntity.createLegalEntity': 'Create Legal Entity',
  'pms.legalEntity.legalEntityCreated': 'Legal Entity created!',
  'pms.portfolio.emptyList.withPermission.message': 'You can now start adding portfolios to your account',
  'pms.modal.exitTheForm': 'Exit the form?',
  'pms.modal.allChangesWillBeLost': 'All changes will be lost',
  'pms.userPortfolio.createPortfolio': 'Create Portfolio',
  'pms.userPortfolio.byPosition': 'By position',
  'pms.userPortfolio.byTransaction': 'By transaction',
  'pms.userPortfolio.byTransactionCashIsAdjusted': 'By transaction, cash is adjusted',
  'pms.userPortfolio.byPositionDescription':
    'Effortlessly input positions and your total performance or P&L, with Sesame handling the balancing changes in market value. {br}{br} This is suitable when you have multiple positions and the transactions are not available or there are too many to keep Sesame synchronised. This option allows you to track total performance and P&L. Asset level, however, is not supported.',
  'pms.userPortfolio.byTransactionDescription':
    'Seamlessly enter  transactions and allow Sesame to derive positions. {br}{br} This is suitable where you have positions in private assets (for example, property, collectibles and Private Equity) which are funded from a separate portfolio. Performance will be driven by valuation changes. Cash is not supported in this portfolio - all transactions that would result in cash movements will be automatically handled so that they do not affect performance.',
  'pms.userPortfolio.byTransactionCashIsAdjustedDescription':
    'Simply enter transactions and allow Sesame to derive positions. Cash balances will be calculated based on all transactions. {br}{br} This is suitable when you would like to fully reconstruct a custodied set of positions using all transactions.',
  'pms.message.successfullySavedThisChangeMayTakeFewMinutes': 'Successfully saved. This change may take a few minutes to be updated globally in your Sesame Application.',
  'pms.message.successfullyTransactionDraftSubmitted':
    'Draft(s) successfully added to the Review Board. Please remember to review and submit them in order for them to be reflected in your Sesame Application.',
  'pms.error.sorryYouAreNotAuthorized': 'Sorry, you are not authorized to access this feature.',
  'pms.error.oneOrMoreTransactionsCouldNotBeSubmitted': 'One or more transactions could not be submitted, please refer to the transaction-level error message',
  'pms.error.errorDuringBulkOperation': 'Error during bulk operation',
  'pms.portfolioReprocessHasBeenTriggered':
    'Portfolio reprocess has been triggered! Please monitor the status on the User Portfolios screen to confirm when it is published to your Sesame Application.',
  'generic.generalInformation': 'General Information',
  'erp.editPropertyValues': 'Edit property values',
  'general.saveChanges': 'Save Changes',
  'metadata.propertyUpdatedBy': 'Property updated by {user}',
  'metadata.propertyCreatedBy': 'Property created by {user}',
  'metadata.propertyDeletedBy': 'Property deleted by {user}',
  'pms.portfolio.emptyList.withoutPermission.message':
    'There are no portfolios in this space and your user does not have the permission required to create one. {linebreak} Please contact your administrator to create one or to grant you the permission to.',
  'generic.sesameAcademy': 'Sesame Academy',
  'generic.youMustEnterAPositiveDecimalNumber': 'You must enter a positive decimal number',
  'erp.deleteContact': 'Delete Contact',
  'erp.deleteCompany': 'Delete Company',
  'report.builder.sesameLibrary': 'Sesame Library',
  'report.builder.customLibrary': 'Custom',
  'generic.overview': 'Overview',
  'generic.pleaseEnterAValidNumber': 'Please enter a valid number',
  'generic.flow': 'Transaction',
  'generic.successfullyDeletedMetadataKey': 'Successfully deleted property.',
  'generic.somethingWentWrongWhenDeletingKey': 'Something went wrong when attempting to delete property.',
  'generic.messageMetadataKeyRemoval':
    'Are you sure you want to remove this property? All the data related to the property will be permanently removed. Do you still wish to continue?',
  'generic.userPortfolios': 'User Portfolios',
  'erp.userPortfolios': 'User Portfolios',
  'erp.custodiedPortfolios': 'Custodied Portfolios',
  'erp.reviewBoard': 'Review Board',
  'generic.successfullyUpdatedMetadataKeys': 'Successfully updated properties.',
  'generic.somethingWentWrongWhenUpdatingKeys': 'Something went wrong when attempting to update properties.',
  'generic.flows': 'Flows',
  'generic.maximalKeyNameLengthExceeded': 'The maximum amount of characters for the property name has been exceeded. Please shorten the name and try again.',
  'erp.transactionProperties': 'Transaction properties',
  'erp.positionProperties': 'Position Properties',
  'erp.editPositionProperties': 'Edit Position Properties',
  'erp.legalEntitiesProperties': 'Legal Entities Properties',
  'erp.custodiedPortfoliosProperties': 'Custodied Portfolios Properties',
  'erp.userPortfoliosProperties': 'User Portfolios Properties',
  'erp.assetsProperties': 'Asset Properties',
  'erp.updatePositionValuation': 'Update Position Valuation',
  'report.builder.filter.measureIsRequired': "Filters can't use empty measure",
  'report.builder.filter.operatorIsRequired': "Filters can't use empty operator",
  'report.builder.filter.valueIsRequired': "Filters can't use empty value",
  'globalSearch.assetGroups.debtFinancing': 'Debt Financing'
};
