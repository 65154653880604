import { selector, selectorFamily } from 'recoil';
import { getPmsAssetTypes } from '../api/getPmsAssetTypes';
import { AssetEnumDto, AssetListFilter } from '../../AssetV2/types';
import { assetsLastUpdated } from '../../AssetV2/recoil/asset';
import { getAssets } from '../../AssetV2/api/getAssets';

export const assetsV2Query = selectorFamily({
  key: 'assets-v2-selector',
  get:
    (props: Partial<AssetListFilter>) =>
    ({ get }) => {
      get(assetsLastUpdated);
      return getAssets(props);
    }
});

export const pmsAssetTypes = selector({
  key: 'pms-asset-types',
  get: () => getPmsAssetTypes()
});

export const pmsAssetType = selectorFamily({
  key: 'pms-asset-types',
  get:
    (type: AssetEnumDto['value']) =>
    ({ get }) =>
      get(pmsAssetTypes).find(({ value }) => value === type)
});
