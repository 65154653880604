import React, { SetStateAction, useCallback, useState } from 'react';
import { Flex, Layout, Row, Typography } from 'antd';
import { PersistentTask, Task } from '../../../../common/types/entity/Task';
import 'react-quill/dist/quill.bubble.css';
import 'quill-mention/dist/quill.mention.min.css';
import './styles.css';
import { Field, FormContext, useFormContext } from '@/common/form';
import { quillFormats, quillModulesBasic, quillModulesFull, useQuillModules } from '../../../../common/ReactQuillAdapted';
import { ContextMode, TaskContextGrid } from './TaskContextGrid';
import Conversation from '../../../Conversation';
import { ConversationType } from '../../../../common/types/entity/Conversation';
import { companyUsers } from '../../../../recoil/users';
import { withSmallSuspense } from '../../../../common/suspense';
import useUserAndTaskMention from '../../../../common/hooks/useUserAndTaskMention';
import { secureRandom } from '@/utils/secureRandom';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInput } from '@/common/components/Input/TextInput';
import ReactQuill from 'react-quill';
import { TaskSider } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Form/Sider';
import { descriptionMaxLength, TaskFormType } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/CreateTask';
import { Dayjs } from 'dayjs';
import { LinkableObject } from '@/common/types/entity/Link';
import { useRecoilValue } from 'recoil';
import { StatusSelect } from '../StatusSelect';
import { HoldingSetSelect } from '@/common/entity';
import { Text } from '@/common/components/Typography/Text';

const { Header } = Layout;

export type RteToolbar = 'basic' | 'full';

export interface TaskFormProps<T extends Task> {
  task: T;
  createTaskData: TaskFormType;
  saving?: boolean;
  pending?: boolean;
  showStatus?: boolean;
  showEntity?: boolean;
  showContext?: ContextMode;
  rteToolbar?: RteToolbar;
  setSelectedLinks: React.Dispatch<SetStateAction<Array<LinkableObject>>>;
  selectedLinks: Array<LinkableObject>;
  onChange: (
    values: TaskFormType,
    key: string,
    value:
      | string
      | Dayjs
      | {
          id: number;
        }
      | null,
    property?: string
  ) => void;
  setDisabled: React.Dispatch<SetStateAction<boolean>>;
}

export default withSmallSuspense(function TaskForm<T extends Task>({
  task,
  createTaskData,
  saving,
  pending,
  showStatus = false,
  showEntity = true,
  showContext,
  rteToolbar = 'basic',
  setSelectedLinks,
  selectedLinks,
  onChange,
  setDisabled
}: TaskFormProps<T>) {
  const [guid] = useState('quill_' + secureRandom().toString(36).slice(2, 6));
  const [content, setContent] = useState('');
  const mentionModule = useUserAndTaskMention(useRecoilValue(companyUsers));
  const quillModules = useQuillModules(rteToolbar === 'full' ? quillModulesFull : quillModulesBasic, mentionModule);
  const translator = useIntl();

  const _onChange = useCallback(
    (createTaskData: TaskFormType, property: string, value: any) => {
      onChange && onChange(createTaskData, property, value);
      setDisabled(!(createTaskData.title && createTaskData.description));
    },
    [onChange, setDisabled]
  );

  const context = useFormContext(createTaskData, _onChange);

  const handleChangeQuill = useCallback(
    (value: string) => {
      if (value.replace(/<(.|\n)*?>/g, '').length <= descriptionMaxLength) {
        setContent(value);
        onChange(createTaskData, 'description', value);
      }
    },
    [createTaskData, onChange]
  );

  return (
    <FormContext.Provider value={context}>
      <Layout className="task-form">
        <Header className="task-form-header">
          <Row className="form-row" align="middle">
            <Typography.Title level={4}>
              <FormattedMessage id={'generic.createTask'} />
            </Typography.Title>
          </Row>
          {<TaskContextGrid task={task} saving={saving} pending={pending} mode={showContext} />}
        </Header>
        <Row className="form-row" align="middle">
          <Field className={'field-row-task task-modal-title-label'} withoutColons property="title" label={translator.formatMessage({ id: 'generic.taskTitle' })} required>
            <TextInput maxLength={100} style={{ borderRadius: '5px', width: '100%' }} placeholder={translator.formatMessage({ id: 'generic.title' })} />
          </Field>
        </Row>
        <br />
        <Row className={'form-row'}>
          <Field
            className={'field-row-task'}
            property="description"
            valueProperty="defaultValue"
            withoutColons
            controlled={false}
            onChange={(value: string) => handleChangeQuill(value)}
            label={translator.formatMessage({ id: 'generic.description' })}
            required
          >
            <ReactQuill
              value={content}
              placeholder={translator.formatMessage({
                id: 'generic.enterTaskDescription'
              })}
              preserveWhitespace
              formats={quillFormats}
              modules={quillModules}
            />
          </Field>
          {task instanceof PersistentTask && <Conversation refType={ConversationType.TASK} refId={task.id} bounds={`#${guid}`} />}
        </Row>
        <br />
        <Row justify="end">
          <Text type={content.replace(/<[^>]*>?/gm, '').length >= descriptionMaxLength ? 'danger' : undefined}>
            {content.replace(/<[^>]*>?/gm, '').length ?? 0} / {descriptionMaxLength}
          </Text>
        </Row>
        <Flex className="form-row" gap={'middle'}>
          {showStatus && (
            <Field className="status" label="Status" property="status" withoutColons>
              <StatusSelect className={'select-info'} />
            </Field>
          )}
          {showEntity && (
            <Field withoutColons className="entity modal-contact-create-sider-text" label="Entity" property="holdingSetId">
              <HoldingSetSelect className={'select-info'} emptyOption="Uncategorized" defaultValue={{ id: createTaskData?.holdingSetId }} />
            </Field>
          )}
          <Flex gap={'middle'}>
            <TaskSider setSelectedLinks={setSelectedLinks} selectedLinks={selectedLinks} onChange={onChange} values={createTaskData} />
          </Flex>
        </Flex>
      </Layout>
    </FormContext.Provider>
  );
});
