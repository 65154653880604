import { DocumentStatus } from '../../../common/types/entity/DocumentStatus';
import { DocumentLookup } from '../../../common/types/entity/DocumentLookup';
import { State } from '../../../common/hooks/State';
import { Aborter } from '@azure/storage-blob';
import { BasicTagType, Tags, TagValueType } from '../../../common/types/entity/DocumentTags';
import TransientDocumentTagValue from './TransientDocumentTagValue';
import EntityLookup from '../../../common/types/entity/EntityLookup';
import EntityRef from '../../../common/types/entity/EntityRef';

export class Document<T = BasicTagType | EntityRef> implements EntityLookup<string> {
  private readonly _progress: State<number>;
  private readonly _disabled: State<boolean>;
  private readonly _status: State<DocumentStatus>;
  private _aborter?: Aborter;

  constructor(
    public id: string,
    public name: string,
    public size: number,
    public tags: Tags<TransientDocumentTagValue<T>>,
    public shared: boolean,
    public contentType?: string,
    public lastModifiedDate: Date = new Date(),
    public openTasksCount: number = 0,
    status: DocumentStatus = DocumentStatus.UPLOADING,
    progress = 0,
    disabled = true,
    public isNew = false,
    public docInboxEmailFrom: string = '',
    public isDocInbox = false
  ) {
    this._status = State.init(status);
    this._progress = State.init(progress);
    this._disabled = State.init(disabled);
  }

  static fromLookup(lookup: DocumentLookup, tags: Tags<TransientDocumentTagValue<TagValueType>>): Document<TagValueType> {
    return new Document(
      lookup.id,
      lookup.name,
      lookup.size,
      tags,
      lookup.shared,
      lookup.contentType,
      lookup.lastModifiedDate,
      lookup.openTasksCount,
      lookup.status,
      100,
      false,
      lookup.isNew,
      lookup.docInboxEmailFrom,
      lookup.isDocInbox
    );
  }

  useStatus = () => this._status.use();
  get status(): DocumentStatus {
    return this._status.val;
  }
  set status(value: DocumentStatus) {
    this._status.val = value;
  }

  useProgress = () => this._progress.useSafe();
  get progress(): number {
    return this._progress.val;
  }
  set progress(value: number) {
    this._progress.val = value;
    this._disabled.val = this.inProgress();
  }

  useDisabled = () => this._disabled.use();
  get disabled(): boolean {
    return this._disabled.val;
  }

  abort() {
    if (!this._aborter?.aborted) {
      this._aborter?.abort();
    }
  }
  get aborter() {
    if (!this._aborter || this._aborter?.aborted) {
      this._aborter = Aborter.none;
    }
    return this._aborter;
  }

  inProgress(): boolean {
    return this.progress < 100;
  }
}
