import { selectorFamily } from 'recoil';
import { EntityLinkType, EntityLinkTypeMenu, LinkableObjectList, LinkObject } from '@/common/types/entity/Link';
import { getOwner } from '@/api/getOwner';
import { userEntitiesWithCustodian } from '@/recoil/holdingSets';
import { documentSelector, documentsSelector, validatedDocumentsSelector } from '@/modules/ERP/Documents/Recoil/documents.selector';
import {
  contactDetailsSelector,
  contactLookupsSelector,
  contactNoteByIdSelector,
  contactsTasksSelector,
  contactTaskByIdSelector
} from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { assetsDetailsListSelector } from '@/modules/ERP/Asset/AssetDetails/Recoil/assetDetails.selector';
import { entityLinksSelector } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { EntitySearch, EntityType } from '@/modules/Onboarding/Shared/types';
import { notesSelector } from '../../Notes/recoil/notes.selector';
import { HoldingSetType } from '@/common/types/entity/HoldingSetType';
import { legalEntityByHoldingSetId } from '../../PortfolioV2/recoil/legalEntities';
import { assetById } from '../../AssetV2/recoil/asset';

type LinkObjectSelectorParameter = {
  type: EntityLinkType;
  id: string;
};

type MultipleLinkObjectSelectorParameter = { type: EntityLinkType; id: string }[];

export const multiplelinkObjectSelector = selectorFamily<LinkObject[], MultipleLinkObjectSelectorParameter>({
  key: 'contact-links-object-selector',
  get:
    objects =>
    async ({ get }) => {
      return objects.map(({ id, type }) => {
        return get(linkObjectSelector({ type, id }));
      });
    }
});

const linkObjectSelector = selectorFamily<LinkObject, LinkObjectSelectorParameter>({
  key: 'contact-links-object-selector',
  get:
    ({ type, id }) =>
    async ({ get }) => {
      if (type === EntityLinkType.DOCUMENT) {
        return get(documentSelector(id));
      }

      if (type === EntityLinkType.CONTACT_PERSON) {
        return get(contactDetailsSelector(id));
      }

      if (type === EntityLinkType.CONTACT_COMPANY) {
        return get(contactDetailsSelector(id));
      }

      if (type === EntityLinkType.HOLDING_SET) {
        const result = get(legalEntityByHoldingSetId({ holdingSetId: Number(id) }));
        return { ...result, id: result.holdingSetId };
      }

      if (type === EntityLinkType.ASSET) {
        return get(assetById({ id: Number(id) }));
      }

      if (type === EntityLinkType.OWNER) {
        return getOwner(id);
      }

      if (type === EntityLinkType.NOTE) {
        return get(contactNoteByIdSelector(id))[0];
      }

      if (type === EntityLinkType.TASK) {
        return get(contactTaskByIdSelector(id));
      }
    }
});

export const linkableObjectsSelector = selectorFamily<
  LinkableObjectList,
  {
    type: EntityLinkTypeMenu;
    params?: EntitySearch;
  }
>({
  key: 'linkable-objects-selector',
  get:
    ({ type, params = {} }) =>
    async ({ get }) => {
      if (type === EntityLinkTypeMenu.DOCUMENT) {
        if (params.ids) {
          return get(validatedDocumentsSelector(params.ids)).map(obj => ({
            ...obj,
            _entityType: EntityLinkType.DOCUMENT
          }));
        }

        return get(documentsSelector).map(obj => ({
          ...obj,
          _entityType: EntityLinkType.DOCUMENT
        }));
      }

      if (type === EntityLinkTypeMenu.CONTACT_PERSON || type === EntityLinkTypeMenu.CONTACT_COMPANY) {
        return get(contactLookupsSelector(params))
          .map(obj => ({
            ...obj,
            _entityType: type
          }))
          .filter(obj => 'CONTACT_' + obj.type === type);
      }

      if (type === EntityLinkTypeMenu.PORTFOLIO || type === EntityLinkTypeMenu.GROUPING || type === EntityLinkTypeMenu.VEHICLE) {
        let hs = get(userEntitiesWithCustodian(type as unknown as HoldingSetType));

        if (params.ids) {
          const ids = params.ids.split(',');

          hs = hs.filter(h => ids.includes(String(h.id)));
        }

        return hs.map(obj => ({
          ...obj,
          _entityType: EntityLinkType.HOLDING_SET
        }));
      }

      if (type === EntityLinkTypeMenu.ASSET) {
        return get(assetsDetailsListSelector)
          .filter(obj => ('ids' in params ? (params.ids as string).split(',').includes(String(obj.id)) : true))
          .map(obj => ({
            ...obj,
            _entityType: EntityLinkType.ASSET
          }));
      }

      if (type === EntityLinkTypeMenu.OWNER) {
        return [];
      }

      if (type === EntityLinkTypeMenu.NOTE) {
        return get(notesSelector(params)).map(obj => ({
          ...obj,
          _entityType: EntityLinkType.NOTE
        }));
      }

      if (type === EntityLinkTypeMenu.TASK) {
        const ids = params.ids ? params.ids.split(',') : undefined;
        return get(
          contactsTasksSelector([
            {
              search: '',
              includeRemoved: true,
              ...params,
              ids
            },
            EntityLinkType.TASK
          ])
        ).map(obj => ({
          ...obj,
          _entityType: EntityLinkType.TASK
        }));
      }

      return [];
    }
});

export const existingListObjectsSelector = selectorFamily<LinkableObjectList, [EntityLinkType, Primitive, EntityType?]>({
  key: 'existing-list-objects-selector',
  get:
    ([entityType, id, selectedType]) =>
    ({ get }) => {
      const links = get(entityLinksSelector([entityType, id]));
      const items: LinkableObjectList = [];

      links
        .filter(link => {
          if (selectedType) {
            return link.type === selectedType;
          }
          return link;
        })
        .forEach(link => {
          let typeOfLink = link.type;
          if (
            (link.type as unknown as EntityType) === EntityType.PORTFOLIO ||
            (link.type as unknown as EntityType) === EntityType.VEHICLE ||
            (link.type as unknown as EntityType) === EntityType.GROUPING
          ) {
            typeOfLink = EntityLinkType.HOLDING_SET;
          }
          switch (typeOfLink) {
            case EntityLinkType.HOLDING_SET: {
              const holdingSetLegalEntity = get(legalEntityByHoldingSetId({ holdingSetId: Number(link.entityId) }));

              if (holdingSetLegalEntity) {
                items.push({
                  ...holdingSetLegalEntity,
                  _entityType: EntityLinkType.HOLDING_SET
                });
              }
              break;
            }
            case EntityLinkType.CONTACT_PERSON:
              const contactPerson = get(contactDetailsSelector(link.entityId));

              items.push({
                ...contactPerson,
                _entityType: EntityLinkType.CONTACT_PERSON
              });
              break;
            case EntityLinkType.CONTACT_COMPANY:
              const contactCompany = get(contactDetailsSelector(link.entityId));

              items.push({
                ...contactCompany,
                _entityType: EntityLinkType.CONTACT_COMPANY
              });
              break;
            case EntityLinkType.NOTE:
              const note = get(notesSelector({})).find(note => note.id === link.entityId);

              if (note) {
                items.push({ ...note, _entityType: EntityLinkType.NOTE });
              }
              break;
            case EntityLinkType.TASK:
              const task = get(
                contactsTasksSelector([
                  {
                    search: '',
                    includeRemoved: true
                  },
                  entityType,
                  link.entityId
                ])
              ).find(task => {
                return String(task.id) === link.entityId;
              });

              if (task) {
                items.push({ ...task, _entityType: EntityLinkType.TASK });
              }
              break;
            case EntityLinkType.ASSET: {
              const asset = get(assetById({ id: Number(link.entityId) }));

              items.push({ ...asset, _entityType: EntityLinkType.ASSET });
              break;
            }
            case EntityLinkType.DOCUMENT:
              const document = get(documentSelector(link.entityId));

              items.push({ ...document, _entityType: EntityLinkType.DOCUMENT });
              break;

            default:
              return;
          }
        });

      return items;
    }
});

export { linkObjectSelector };
